
import { uuid4 } from "@sentry/utils";
import { ReactElement } from "react";

type textOrLink = { text?: string, link?: string };

/*
// split can't deal with multiple \n, only seens as one splitter
export function componentify(inputText: string | undefined) : ReactElement | undefined {
  
  if(!inputText) return undefined;

  return <>
    { 
      inputText.split("\n").map( (line: string) => (<span key={uuid4()}>{linkify(line)}</span>))
    }
  </>
}
*/

export function linkify(inputText: string | undefined) : ReactElement | undefined {
  
  if(!inputText) return undefined;

  const patterns: RegExp[] = [
    //URLs starting with http://, https://
    /(\b(?:https?|http):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim,
    //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    /(www\.[\S]+(?:\b|$))/gim,
  ];
  let foundLinks: string[] | null;

  // init the text with a single part
  var texts: textOrLink[] = [{ text: inputText }];
  
  // for each pattern of link, look for it in the texts
  for(let pattern of patterns ) {
    texts = texts.reduce<textOrLink[]>( 
      function(otherTexts: textOrLink[], textOrLink: textOrLink) : textOrLink[] {
        // look for link, if it's a text
        if(textOrLink.text) {
          foundLinks = textOrLink.text.match(pattern);
          if(foundLinks) {
            // if links were found, split the text
            let textParts: string[] = textOrLink.text.split(pattern);
            // then proceed the parts, depending on
            for(let textPart of textParts) {
              otherTexts.push(
                foundLinks.includes(textPart) ? 
                  { link: textPart } 
                : { text: textPart }
              )
            }
          }
          else {
            // if no link was found, keep going with the text as it is
            otherTexts.push(textOrLink);
          }
        }
        else {
          // if it's already a link, keep going with the link as it is
          otherTexts.push(textOrLink)
        }
        
        return otherTexts
    }, 
    [])
  }

  return <div>{ 
    texts.map( (textOrLink: textOrLink) => 
      textOrLink.link ? 
          <a key={uuid4()} href={textOrLink.link.startsWith("www") ? "http://"+textOrLink.link : textOrLink.link} target="_blank">{textOrLink.link!}</a>
        : <span key={uuid4()}>{textOrLink.text!}</span>
    )
  }</div>
}