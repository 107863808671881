import { UserOutput } from "@addventa/sesha-forms-api";
import { Checkbox, Input, theme } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { ChangeEvent, useEffect, useState } from "react";

const { Search } = Input;

const UserSelection = (props: {
  users: UserOutput[];
  selectedUsers: string[];
  setSelectedUsers: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const {
    token: { colorPrimary },
  } = theme.useToken();

  const [filteredUsers, setFilteredUsers] = useState<UserOutput[]>(props.users);

  useEffect(() => {
    setFilteredUsers(props.users);
  }, [props.users]);

  const search = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value) {
      setFilteredUsers(
        props.users.filter(
          (user) =>
            user.firstName.toLowerCase().includes(e.target.value.toLowerCase()) ||
            user.lastName.toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
    } else {
      setFilteredUsers(props.users);
    }
  };

  const onChange = (e: CheckboxChangeEvent, id: string): void => {
    if (e.target.checked) props.setSelectedUsers([...props.selectedUsers, id]);
    else props.setSelectedUsers(props.selectedUsers.filter((userId) => userId !== id));
  };

  return (
    <div
      style={{
        borderRadius: "34px",
        boxShadow: "2px 6px 10.1px 1px rgba(0, 0, 0, 0.15)",
        padding: "20px",
      }}
    >
      <Search
        placeholder="Rechercher"
        onChange={search}
        enterButton
        allowClear
        style={{ paddingBottom: "15px" }}
      />
      <div style={{ maxHeight: "500px", overflowY: "auto" }}>
        {filteredUsers.map((el) => (
          <div key={el._id}>
            <Checkbox
              checked={props.selectedUsers.some((userId) => userId === el._id)}
              onChange={(e) => onChange(e, el._id)}
              style={{ paddingBottom: "2px" }}
            >
              {el.firstName + " " + el.lastName}
            </Checkbox>
            <br />
          </div>
        ))}
      </div>
      <div style={{ paddingTop: "15px", textAlign: "center", color: colorPrimary }}>
        <a
          style={{ color: colorPrimary }}
          onClick={() => props.setSelectedUsers(filteredUsers.map(({ _id }) => _id))}
          onMouseEnter={(e) => (e.currentTarget.style.textDecoration = "underline")}
          onMouseLeave={(e) => (e.currentTarget.style.textDecoration = "none")}
        >
          Tout sélectionner
        </a>{" "}
        /{" "}
        <a
          style={{ color: colorPrimary }}
          onClick={() =>
            props.setSelectedUsers([
              ...props.selectedUsers.filter(
                (userId) => !filteredUsers.some((user) => user._id === userId)
              ),
            ])
          }
          onMouseEnter={(e) => (e.currentTarget.style.textDecoration = "underline")}
          onMouseLeave={(e) => (e.currentTarget.style.textDecoration = "none")}
        >
          Tout désélectionner
        </a>
      </div>
    </div>
  );
};

export default UserSelection;
