import { FormOutput } from "@addventa/sesha-forms-api";
import { ConfigProvider, Flex, Input, Modal, Spin, theme } from "antd";
import { useEffect, useState } from "react";
import {
  coupleAllQuestionsAnswers,
  filterActiveSections,
} from "../../../../assets/formAnswers/rulesActivation";
import DisplayAllSections from "../../../../assets/formAnswers/section/DisplayAllSections";
import { ExtendedSeshaFormsAnswers } from "../../ReviewFormModel.d";

export function YourFormAnswersPreviewModal(props: {
  modalIsOpen: boolean;
  setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  form: FormOutput;
  formAnswersList: ExtendedSeshaFormsAnswers[];
}) {
  const [currentSelectedFormAnswers, setCurrentSelectedFormAnswers] =
    useState<ExtendedSeshaFormsAnswers>();
  const {
    token: { colorBorder, colorText },
  } = theme.useToken();

  const onClose = () => {
    props.setModalIsOpen(false);
  };

  useEffect(() => {
    const selectedFormAnswers = props.formAnswersList.find((formAnswer) => formAnswer.isLoaded);
    setCurrentSelectedFormAnswers(selectedFormAnswers);
  }, [props.formAnswersList]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Input: {
            colorTextDisabled: colorText,
            colorBorder: colorBorder,
          },
        },
      }}
    >
      <Modal
        title="Aperçu des réponses"
        className="modal-reviewForm-yourFormAnswersPreview "
        open={props.modalIsOpen}
        onCancel={onClose}
      >
        {currentSelectedFormAnswers === undefined ? (
          <Spin spinning={currentSelectedFormAnswers === undefined}></Spin>
        ) : (
          <Flex vertical gap={30}>
            <span>
              <h3>Nom du répondant</h3>
              <Input
                value={`${currentSelectedFormAnswers.contact.firstName} ${currentSelectedFormAnswers.contact.lastName}`}
                disabled
                style={{ width: 600 }}
              />
            </span>
            <DisplayAllSections
              form={props.form}
              formAnswers={currentSelectedFormAnswers}
              sections={filterActiveSections(
                props.form.sections!,
                props.form.rules || [],
                coupleAllQuestionsAnswers(props.form.sections!, currentSelectedFormAnswers.answers!)
              )}
              unansweredQuestionsAreDisplayed={false}
            />
          </Flex>
        )}
      </Modal>
    </ConfigProvider>
  );
}
