import { Button, Flex } from "antd";

import {
  AnswerOutput,
  FormAnswersOutput,
  FormOutput,
  QuestionOutput,
  RuleOutput,
  SectionOutput,
} from "@addventa/sesha-forms-api";
import { apiFormAnswers } from "../../api-configuration/Configuration";
import { completionTracking, navigationTracking } from "../assets/formAnswers/utils";
import FormAnswersProgressBar from "./FormAnswersProgressBar";

type QuestionAndAnswer = {
  question: QuestionOutput;
  answer: AnswerOutput;
};

function FormAnswersNavPanel(props: {
  formAnswers: FormAnswersOutput;
  form: FormOutput;
  inValidationMode: boolean;
  setInValidationMode: React.Dispatch<React.SetStateAction<boolean>>;
  setMandatoryWarning: React.Dispatch<React.SetStateAction<boolean>>;
  prevSections: SectionOutput[];
  setPrevSections: React.Dispatch<React.SetStateAction<SectionOutput[]>>;
  currentSection: SectionOutput;
  setCurrentSection: React.Dispatch<React.SetStateAction<SectionOutput>>;
  nextSections: SectionOutput[];
  setNextSections: React.Dispatch<React.SetStateAction<SectionOutput[]>>;
  setCurrentSectionRules: React.Dispatch<React.SetStateAction<RuleOutput[]>>;
  currentAnswers: QuestionAndAnswer[];
}) {
  async function goToPreviousSection(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();

    props.setMandatoryWarning(false);

    // check if you're not returning from preview mode
    if (props.inValidationMode) {
      // it was already left on the last section, only disable preview mode
      props.setInValidationMode(false);
    }
    // else skip to previous section if it exists
    else if (props.prevSections.length > 0) {
      let previousSection: SectionOutput = props.prevSections[props.prevSections.length - 1];
      // look for previous last question, to track progression
      let lastQuestion: QuestionOutput =
        previousSection.questions![previousSection.questions!.length - 1];

      // track progression
      navigationTracking(props.formAnswers, props.form, previousSection, lastQuestion);
      // save progression and completion
      let updatedFormAnswers = { ...props.formAnswers, lastModifiedDate: new Date() };
      await apiFormAnswers.formAnswersUpdateOne(props.formAnswers._id, updatedFormAnswers);

      props.setCurrentSection(previousSection);
      props.setCurrentSectionRules(
        props.form.rules!.filter((r) =>
          previousSection.questions!.find((q) =>
            r.conditions.find((condition) => q._id === condition.questionId)
          )
        )
      );
      props.setPrevSections(props.prevSections.slice(0, -1));
      props.setNextSections([props.currentSection, ...props.nextSections]);
    }
  }

  async function goToNextSection(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();

    // checking if all mandatory questions have an answer, switch section if all amndatory are good
    let mandatoryNotOk = props.currentAnswers.some(
      ({ question, answer }) => question.mandatory && answer.value.length === 0
    );
    props.setMandatoryWarning(mandatoryNotOk);

    // if mandatory questions are ok, try to go next section or end the form
    if (!mandatoryNotOk) {
      // save progression and completion
      // look for current last question, to track progression and completion
      let lastQuestion: QuestionOutput =
        props.currentSection.questions![props.currentSection.questions!.length - 1]!;

      // track the last question of the last/current section as last completed
      completionTracking(props.formAnswers, props.form, props.currentSection, lastQuestion);

      // look for a next section to display
      if (props.nextSections.length > 0) {
        let nextSection = props.nextSections[0];

        // and track the first question of the next section as last seen
        let firstQuestion = nextSection.questions![0]!;
        navigationTracking(props.formAnswers, props.form, nextSection, firstQuestion);
        let updatedFormAnswers = { ...props.formAnswers, lastModifiedDate: new Date() };
        await apiFormAnswers.formAnswersUpdateOne(props.formAnswers._id, updatedFormAnswers);

        props.setPrevSections([...props.prevSections, props.currentSection]);
        props.setCurrentSection(nextSection);
        props.setCurrentSectionRules(
          props.form.rules!.filter((r) =>
            nextSection.questions!.find((q) =>
              r.conditions.find((condition) => q._id === condition.questionId)
            )
          )
        );
        props.setNextSections(props.nextSections.slice(1));
      }
      // or if the forms is over, just go to answers review and validation
      else {
        // and track the last question of the last/current section as last seen
        navigationTracking(props.formAnswers, props.form, props.currentSection, lastQuestion);
        let updatedFormAnswers = { ...props.formAnswers, lastModifiedDate: new Date() };
        await apiFormAnswers.formAnswersUpdateOne(props.formAnswers._id, updatedFormAnswers);

        props.setInValidationMode(true);
      }
    }
  }

  return (
    <Flex vertical>
      <Flex
        justify="end"
        style={{
          marginBottom: "20px",
        }}
        gap="small"
      >
        {props.prevSections.length > 0 && (
          <Button type="default" onClick={goToPreviousSection}>
            Précedent
          </Button>
        )}
        <Button type="primary" onClick={goToNextSection}>
          Suivant
        </Button>
      </Flex>
      <FormAnswersProgressBar
        prevSections={props.prevSections}
        currentSection={props.currentSection}
        nextSections={props.nextSections}
      />
    </Flex>
  );
}

export default FormAnswersNavPanel;
