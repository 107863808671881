import { AnswerOutput, QuestionOutput, RuleOutput, SectionOutput } from "@addventa/sesha-forms-api";

export type QuestionAndAnswer = {
    question: QuestionOutput;
    answer: AnswerOutput;
};

export function coupleQuestionAnswer(answers: AnswerOutput[]): (q: QuestionOutput) => QuestionAndAnswer {
    return (q: QuestionOutput) => ({
        question: q,
        answer: answers.find((a) => q._id === a.questionId)!,
    });
}

export function coupleAllQuestionsAnswers(sections: SectionOutput[], answers: AnswerOutput[]): QuestionAndAnswer[] {
    return sections!.reduce<QuestionAndAnswer[]>(
        (qas, s) => qas.concat(s.questions!.map(coupleQuestionAnswer(answers))),
        []
      );
}


// trigger if every rules about a section is validated
function checkTriggeringRules(
    sections: SectionOutput[],
    rules: RuleOutput[],
    questionAnswers: QuestionAndAnswer[]
  ): boolean {
    return rules.length == 0 
        || rules.some((r) => {
            // get the targeted section, to check for rule validity
            let targetSection: SectionOutput = sections.find( s => s._id === r.sectionId )!;
            return r.conditions.find( c => {
                // check if the condition is valid and is targeting a section later
                let conditionSection: SectionOutput = sections.find( s => s.questions!.find( q => q._id == c.questionId ))!;
                if( conditionSection.order >= targetSection.order)
                    return false;

                // if the condition is valid, check the option/answer satisfying the condition
                return questionAnswers.find((qa) =>
                    qa.question._id === c.questionId 
                    && qa.answer.value.find( v => v ===  c.value ))
            })
        });
}

/**
 * 
 * @param sections 
 * @param rules 
 * @param questionAnswers 
 * @returns sections filtered out of non triggered section, in regards of the current answers
 */
export function filterActiveSections(sections: SectionOutput[], rules: RuleOutput[], questionAnswers: QuestionAndAnswer[]) : SectionOutput[] {
    // would be more efficient if form (sections, rules, questions) was more organized before once for all
    return sections.filter(
        (s) =>
          checkTriggeringRules(
            sections,
            rules.filter((r) => r.sectionId === s._id),
            questionAnswers
          )
      )
}