import { ContactOutput, TagOutput } from "@addventa/sesha-forms-api";
import { Button, Form, Input, Tag, theme } from "antd";
import { useEffect, useState } from "react";
import { apiTag } from "../../api-configuration/Configuration";

const colors = [
  "#FFDCFB", // pink
  "#DCFFE4", // green
  "#DDDCFF", // violet
  "#FFEDDC", // brown
  "#DCF7FF", // blue
  "#FFDCDC", // red
];

function TagItem(props: {
  mode: string;
  tag: TagOutput;
  setCurrentTag: React.Dispatch<React.SetStateAction<string>>;
  isNew: boolean;
  setTags?: React.Dispatch<React.SetStateAction<TagOutput[]>>;
  setReloadList?: React.Dispatch<React.SetStateAction<boolean>>;
  contacts?: ContactOutput[];
  reloadList: boolean;
}) {
  const [originalColors, setOriginalColors] = useState(props.tag.color || "#FFDCFB");
  const [contactCount, setContactcount] = useState<number>(0);

  const {
    token: { colorPrimary },
  } = theme.useToken();

  useEffect(() => {
    setContactcount(props.contacts?.filter((elt) => elt.tags.includes(props.tag._id)).length!);
  }, [props.reloadList]);

  const onFinish = (values: any) => {
    (async () => {
      if (props.isNew) {
        await apiTag.tagCreateOne({ label: values.label, color: originalColors });
      } else {
        await apiTag.tagUpdateOne(props.tag._id, {
          label: values.label,
          color: originalColors,
        });
      }
      props.setCurrentTag("");
      props.setTags!((prevTags) => {
        return prevTags.map((tag) => {
          if (tag._id === props.tag._id) {
            return { ...tag, label: values.label, color: originalColors };
          }
          return tag;
        });
      });
      props.setReloadList!(true);
    })();
  };

  return (
    <div>
      {props.mode === "edit" ? (
        <>
          <Form layout="inline" onFinish={onFinish} initialValues={{ label: props.tag.label }}>
            <Form.Item
              name="label"
              rules={[{ required: true, message: "Merci de renseigner le nom du tag" }]}
            >
              <Input style={{ width: "200px" }} placeholder="Ajouter un tag" />
            </Form.Item>
            <span
              style={{
                marginRight: "5px",
                marginTop: "5px",
                verticalAlign: "middle",
              }}
            >
              Couleur :{" "}
            </span>
            {colors.map((color) => {
              return (
                <Button
                  shape="circle"
                  key={color}
                  style={{
                    border: `3px solid ${originalColors === color ? colorPrimary : "transparent"}`,
                    backgroundColor: color,
                    marginRight: "5px",
                    marginTop: "5px",
                  }}
                  size="small"
                  onClick={(e) => {
                    e.preventDefault();
                    setOriginalColors(color);
                  }}
                ></Button>
              );
            })}
            <Form.Item style={{ textAlign: "right", marginLeft: "10px" }}>
              <Button type="primary" htmlType="submit">
                {props.isNew ? "Ajouter" : "Valider"}
              </Button>
              <Button
                style={{ marginLeft: "10px" }}
                onClick={() => {
                  props.setCurrentTag("");
                  if (props.tag.label === "") {
                    props.setTags!((prevTags) => {
                      return prevTags.filter((tag) => tag._id !== props.tag._id);
                    });
                  }
                }}
              >
                Annuler
              </Button>
            </Form.Item>
          </Form>
        </>
      ) : (
        <>
          <Tag color={props.tag.color} style={{ color: "#2F2F2F" }}>
            {props.tag.label + " (" + contactCount + ")"}
          </Tag>
        </>
      )}
    </div>
  );
}

export default TagItem;
