import {
  AccountingFirmOutput,
  ContactOutput,
  FormAnswersOutput,
  FormOutput,
  TagOutput,
} from "@addventa/sesha-forms-api";
import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  FileAddOutlined,
  SettingOutlined,
  TagOutlined,
} from "@ant-design/icons";
import {
  Button,
  ConfigProvider,
  Flex,
  Modal,
  Popconfirm,
  Popover,
  Select,
  Table,
  Tag,
  theme,
  Tooltip,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { CustomTagProps } from "rc-select/lib/BaseSelect";
import { useEffect, useState } from "react";
import {
  apiAccountingFirm,
  apiContact,
  apiFormAnswers,
  apiTag,
} from "../../../api-configuration/Configuration";
import { testIDepotConnection } from "../../../api/API";
import { useNotification } from "../../../hooks/useNotification";
import Tags from "../../tags/Tags";
import ContactListModal from "./ContactListModal";
import IDepotColumn from "./IDepotColumn";

function ContactList(props: {
  forms: FormOutput[];
  filteredContactsIds: string[];
  reloadSearch: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const {
    token: { colorPrimaryBgHover, colorPrimary },
  } = theme.useToken();
  const { showNotification } = useNotification();

  const [deletionWarningMessage, setDeletionWarningMessage] = useState<string>("");
  const [allContacts, setAllContacts] = useState<ContactOutput[]>([]);
  const [filteredContacts, setFilteredContacts] = useState<ContactOutput[]>([]);
  const [contactToEdit, setContactToEdit] = useState<ContactOutput>();
  const [reloadList, setReloadList] = useState<boolean>(false);
  const [displayHeader, setDisplayHeader] = useState<boolean>(false);
  const [selectedRows, setSelectedRows] = useState<ContactOutput[]>([]);
  const [openTagsModal, setOpenTagsModal] = useState<boolean>(false);
  const [tags, setTags] = useState<TagOutput[]>([]);
  const [openAddTagsModal, setOpenAddTagsModal] = useState<boolean>(false);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [formAnswers, setFormAnswers] = useState<FormAnswersOutput[]>([]);
  const [accountingFirm, setAccountingFirm] = useState<AccountingFirmOutput>();
  const [iDepotState, setIDepotState] = useState<string>("");
  const [iDepotUUID, setIDepotUUID] = useState<string>("");

  useEffect(() => {
    (async () => {
      const resAcc = await apiAccountingFirm.accountingfirmFindOne();
      setAccountingFirm(resAcc);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (accountingFirm && accountingFirm.dataProvider === "ACD" && accountingFirm.fec === false) {
        const res = await testIDepotConnection();
        const resIDepot = await res.json();
        if (res.status !== 200) {
          setIDepotState("KO");
        } else {
          setIDepotState("OK");
          setIDepotUUID(resIDepot.data.UUID);
        }
      }
    })();
  }, [accountingFirm]);

  useEffect(() => {
    (async () => {
      const allContacts = await apiContact.contactFindAll();
      setAllContacts(allContacts.map((contact) => ({ ...contact, key: contact._id })));
    })();
  }, [reloadList]);

  useEffect(() => {
    setFilteredContacts(
      allContacts.filter((contact) => props.filteredContactsIds.includes(contact._id))
    );
  }, [allContacts, props.filteredContactsIds]);

  useEffect(() => {
    (async () => {
      const resTags = await apiTag.tagFindAll();
      setTags(resTags);
      const resFormAnswers: FormAnswersOutput[] = await apiFormAnswers.formAnswersFindAll();
      setFormAnswers(resFormAnswers);
    })();
  }, [reloadList]);

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: ContactOutput[]) => {
      setSelectedRows(selectedRows);
      setDisplayHeader(!!selectedRows.length);
    },
  };

  const handleDelete = async () => {
    try {
      const deletePromises = selectedRows.map(async (elt) => {
        try {
          await apiContact.contactDeleteOne(elt._id);
        } catch (error) {
          throw new Error("Erreur lors de la suppression: " + elt.firstName + " " + elt.lastName);
        }
        return elt._id;
      });
      await Promise.all(deletePromises);

      setReloadList(!reloadList);
      setDisplayHeader(false);
      showNotification(
        "success",
        deletePromises.length > 1 ? "Contacts supprimés" : "Contact supprimé"
      );
    } catch (error) {
      showNotification("error", "Erreur lors de la suppression");
    }
  };

  const columns: ColumnsType<ContactOutput> = [
    {
      title: "Nom du contact",
      key: "lastName",
      render: (value, record) => record.firstName + " " + record.lastName,
      sorter: (a: ContactOutput, b: ContactOutput) =>
        (a.firstName + " " + a.lastName).localeCompare(b.firstName + " " + b.lastName),
      showSorterTooltip: false,
    },
    {
      title: (
        <>
          <Button
            type="text"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              setOpenTagsModal(true);
            }}
            icon={
              <Tooltip placement="top" title="Gestion des tags">
                <SettingOutlined />{" "}
              </Tooltip>
            }
            style={{ height: "22px" }}
          />
          <span>Tag(s)</span>
        </>
      ),
      dataIndex: "tags",
      key: "tags",
      render: (value, record) => {
        const sortTags = record.tags?.sort((a, b) => a.localeCompare(b, "fr", { numeric: true }));
        return (
          <>
            {sortTags?.slice(0, 2).map((tag) => {
              const contactTag = tags.find((elt) => elt._id === tag);
              if (contactTag)
                return (
                  <Tag
                    color={contactTag!.color}
                    key={tag}
                    style={{ color: "#2F2F2F", margin: "2.5px 8px 2.5px 0px" }}
                  >
                    {`${
                      contactTag!.label.length > 14
                        ? contactTag!.label.slice(0, 14) + "..."
                        : contactTag!.label ?? ""
                    }`}
                  </Tag>
                );
              return undefined;
            })}
            {sortTags.length > 2 && (
              <Popover
                trigger="hover"
                content={
                  <Flex>
                    {sortTags.slice(2).map((tag) => {
                      const contactTag = tags.find((elt) => elt._id === tag);
                      if (contactTag)
                        return (
                          <Tag
                            color={contactTag!.color}
                            key={tag}
                            style={{ color: "#2F2F2F", margin: "0px 4px 0px 4px" }}
                          >
                            {contactTag!.label ?? ""}
                          </Tag>
                        );
                      return undefined;
                    })}
                  </Flex>
                }
              >
                <Tag
                  icon={<EllipsisOutlined />}
                  color="blue"
                  bordered={false}
                  style={{ marginRight: "0px" }}
                />
              </Popover>
            )}
          </>
        );
      },
      width: "200px",
      sorter: (a: ContactOutput, b: ContactOutput) => {
        if (a.tags.length === 0 || b.tags.length === 0) return 1;
        return a.tags![0].localeCompare(b.tags![0]);
      },
      showSorterTooltip: false,
    },
    {
      title: "Adresse email",
      dataIndex: "email",
      key: "email",
      sorter: (a: ContactOutput, b: ContactOutput) => a.email.localeCompare(b.email!),
      showSorterTooltip: false,
    },
    {
      title: "Dernier formulaire envoyé",
      key: "lastForm",
      render: (value, record) => {
        const contactFormAnswers = formAnswers.filter((elt) => elt.contactId === record._id);
        const sortContactFormAnswers = contactFormAnswers.sort(
          (a, b) => dayjs(b.sendDate).valueOf() - dayjs(a.sendDate).valueOf()
        );
        if (sortContactFormAnswers[0]) {
          return (
            <span>
              {props.forms.find((form) => form._id === sortContactFormAnswers[0].formId)?.title}
            </span>
          );
        }
      },
    },
    {
      title: "i-Dépôt",
      key: "iDepot",
      render: (text, record) => {
        return (
          <IDepotColumn text={text} record={record} iDepotState={iDepotState} uuid={iDepotUUID} />
        );
      },
      hidden:
        !accountingFirm ||
        (accountingFirm &&
          (accountingFirm.dataProvider !== "ACD" ||
            accountingFirm.fec ||
            accountingFirm?.iDepotOnForms !== true)),
    },
    {
      title: "",
      key: "action",
      render: (value, record) => (
        <Button
          shape="circle"
          icon={<EditOutlined />}
          size="small"
          onClick={() => {
            setContactToEdit(record);
          }}
        />
      ),
    },
  ];

  const locale = {
    emptyText: (
      <div style={{ marginTop: "10px" }}>
        <FileAddOutlined style={{ fontSize: "60px", color: "#A6A6A6" }} />
        <p style={{ fontSize: "19px", fontWeight: 700, color: "#A6A6A6" }}>
          Vos contacts apparaîtront ici.
          <br />
          Pour commencer, cliquez sur "Ajouter des contacts".
        </p>
      </div>
    ),
  };

  const tagRender = (props: CustomTagProps) => {
    const { label, closable, onClose } = props;
    const color = tags?.find((tag) => tag.label === label)?.color;

    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={color}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
          marginLeft: 3,
          marginBottom: 1,
          borderRadius: 8,
          color: "#2F2F2F",
        }}
      >
        {label}
      </Tag>
    );
  };

  const selectTags = (value: string[]) => {
    setSelectedTags(value);
  };

  const addTagsToMultipleContacts = () => {
    (async () => {
      for (const contact of selectedRows) {
        var hasTags = contact.tags;
        for (const selectedTag of selectedTags) {
          if (!hasTags?.includes(selectedTag)) {
            hasTags?.push(selectedTag);
          }
        }
        await apiContact.contactUpdateOne(contact._id, { tags: hasTags });
      }
      setReloadList(!reloadList);
      setOpenAddTagsModal(false);
    })();
  };

  const getDeletionMessage = async () => {
    if (selectedRows.length === 1) {
      const res = await apiFormAnswers.formAnswersFindAll(selectedRows[0]._id);
      for (const formAnswer of res) {
        if (formAnswer.answers.length > 0) {
          return "Vous vous apprêtez à supprimer un contact ayant rempli des formulaires. Ses réponses seront en conséquence supprimées. Souhaitez-vous continuer ?";
        }
      }
      return "Vous vous apprêtez à supprimer un contact. Souhaitez-vous continuer ?";
    }

    for (const contact of selectedRows) {
      const res = await apiFormAnswers.formAnswersFindAll(contact._id);
      for (const formAnswer of res) {
        if (formAnswer.answers.length > 0) {
          return "Vous vous apprêtez à supprimer plusieurs contacts ayant rempli des formulaires. Les réponses de ces contacts seront en conséquence supprimées. Souhaitez-vous continuer ?";
        }
      }
    }
    return "Vous vous apprêtez à supprimer plusieurs contacts. Souhaitez-vous continuer ?";
  };

  return (
    <>
      <div
        style={{
          marginTop: displayHeader ? "-5px" : "0px",
          marginBottom: "7px",
          height: displayHeader ? "50px" : "10px",
        }}
      >
        {displayHeader && (
          <div
            style={{
              backgroundColor: colorPrimaryBgHover,
              borderRadius: "20px",
              height: "50px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Button
              type="primary"
              icon={<TagOutlined />}
              style={{ marginRight: "10px" }}
              onClick={() => setOpenAddTagsModal(true)}
            >
              Ajouter des tags
            </Button>
            <Popconfirm
              title="Suppression"
              description={<p style={{ maxWidth: 400 }}>{deletionWarningMessage}</p>}
              onVisibleChange={async (visible) => {
                if (visible) {
                  const message = await getDeletionMessage();
                  setDeletionWarningMessage(message);
                }
              }}
              className="popconfirm-delete"
              onConfirm={handleDelete}
              okText="Oui"
              cancelText="Non"
            >
              <Button
                type="primary"
                icon={<DeleteOutlined />}
                danger={true}
                style={{ marginRight: "10px" }}
              >
                Supprimer
              </Button>
            </Popconfirm>
          </div>
        )}
      </div>

      <div>
        <ConfigProvider
          theme={{
            components: {
              Table: {
                cellPaddingBlock: 13,
              },
            },
          }}
        >
          <Table
            rowSelection={{
              type: "checkbox",
              ...rowSelection,
            }}
            columns={columns}
            dataSource={[...filteredContacts]}
            locale={locale}
            pagination={{ position: ["bottomCenter"], size: "small" }}
          />
        </ConfigProvider>
        {accountingFirm && (
          <ContactListModal
            contact={contactToEdit}
            setContactToEdit={setContactToEdit}
            setReloadList={(data) => (data ? setReloadList(!reloadList) : null)}
            reloadList={reloadList}
            dataProvider={accountingFirm.dataProvider!}
            fec={accountingFirm.fec!}
          />
        )}
      </div>
      <Tags
        openTagsModal={openTagsModal}
        setOpenTagsModal={setOpenTagsModal}
        setReloadList={(data) => {
          if (data) {
            setReloadList(!reloadList);
            props.reloadSearch!(!reloadList);
          }
        }}
        contacts={filteredContacts}
        reloadList={reloadList}
      />
      <ConfigProvider
        theme={{
          components: {
            Modal: {
              headerBg: colorPrimaryBgHover,
            },
          },
        }}
      >
        <Modal
          title="Ajouter des tags"
          open={openAddTagsModal}
          okText="Ajouter"
          cancelText="Retour"
          onCancel={() => setOpenAddTagsModal(false)}
          onOk={() => addTagsToMultipleContacts()}
        >
          <span>Tag(s)</span>{" "}
          <Select
            mode="multiple"
            tagRender={tagRender}
            style={{
              width: 370,
              float: "right",
            }}
            allowClear
            options={tags.map((tag) => ({ value: tag._id, label: tag.label }))}
            onChange={selectTags}
          />
          <div style={{ marginTop: "30px" }}>
            <span>Nombre de contacts sélectionnés :</span>{" "}
            <span style={{ color: colorPrimary, fontWeight: "bold" }}>{selectedRows.length}</span>
          </div>
        </Modal>
      </ConfigProvider>
    </>
  );
}

export default ContactList;
