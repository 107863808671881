import { FormOutput } from "@addventa/sesha-forms-api";
import { SearchOutlined } from "@ant-design/icons";
import { AutoComplete, Input } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiForm } from "../../api-configuration/Configuration";

function FormSearch() {
  const [options, setOptions] = useState<{ value: string; label: string }[]>([]);
  const [allForms, setAllForms] = useState<FormOutput[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const res: FormOutput[] = await apiForm.formFindAll();
      setAllForms(res);
    })();
  }, []);

  const onSelect = (data: string) => {
    if (allForms.find((form) => form._id === data)?.draft) navigate("/form/create/" + data);
    else navigate("/form/" + data);
  };

  const handleSearch = (searchedValue: string) => {
    let res: { value: string; label: string }[] = [];

    if (!searchedValue) {
      res = [];
    } else {
      const filteredList = allForms.filter((elt) =>
        elt.title.toLocaleLowerCase().includes(searchedValue.toLowerCase())
      );
      res = filteredList.map((form) => ({
        value: form._id,
        label: form.draft ? form.title + " - Brouillon" : form.title,
      }));
    }
    setOptions(res);
  };

  return (
    <div className="form-search" style={{ textAlign: "center" }}>
      <AutoComplete
        options={options}
        onSelect={onSelect}
        onSearch={handleSearch}
        style={{
          width: "80%",
          zIndex: 0,
        }}
      >
        <Input
          size="large"
          placeholder="Rechercher un formulaire..."
          style={{
            width: "80% !important",
            zIndex: 0,
            marginTop: "75px",
            fontSize: "22px !important",
            height: "56px !important",
            textAlign: "left",
            border: "1px solid #d9d9d9",
            paddingLeft: "31px",
          }}
          suffix={
            <SearchOutlined
              style={{
                fontSize: 23,
                marginTop: 8,
                marginBottom: 8,
              }}
            />
          }
        />
      </AutoComplete>
    </div>
  );
}

export default FormSearch;
