import {
  AccountingFirmOutput,
  FormInput,
  FormOutput,
  OptionOutput,
  QuestionOutput,
  RuleOutput,
  SectionOutput,
  UserOutput,
} from "@addventa/sesha-forms-api";
import { DownloadOutlined } from "@ant-design/icons";
import * as Sentry from "@sentry/react";
import { Button, Form, Input } from "antd";
import dayjs from "dayjs";
import "dayjs/locale/fr";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { apiForm, apiUser, apiUserFormAsso } from "../../../api-configuration/Configuration";
import { EFeatures } from "../../../enums/EFeatures";
import { useNotification } from "../../../hooks/useNotification";
import { usePermission } from "../../../hooks/usePermission";
import { EditionRule } from "../../../types/SeshaForm/SeshaRule";
import { Contents, Edition, Identified } from "../../../types/misc/Generic";
import { objectid } from "../../../types/misc/MongoDB";
import AllSections from "../../assets/form/section/AllSections";
import templatesForm from "./templatesForm";
import {
  addTempIds,
  checkRulesValidityOnIds,
  checkRulesValidityOnOrders,
  setTempIdsinRules,
} from "./utils";

function CreateForm(props: {
  setActiveTab: React.Dispatch<React.SetStateAction<string>>;
  setForm: React.Dispatch<React.SetStateAction<FormOutput | undefined>>;
  accountingFirm: AccountingFirmOutput | undefined;
  setIsModified: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const { showNotification } = useNotification();
  const hasSAAccess = usePermission(EFeatures.SUPERADMIN_TECH);

  const [editedForm, setEditedForm] = useState<Edition<Identified<Contents<FormOutput>>>>();
  const [stay, setStay] = useState<boolean>(false);
  const [savedNewForm, setSavedNewForm] = useState<string>("");
  const [isEdited, setIsEdited] = useState<number>(0);

  function removeIds(obj: any): any {
    if (typeof obj !== "object" || obj === null) {
      return obj;
    }

    if (Array.isArray(obj)) {
      return obj.map(removeIds);
    }

    const newObj: any = {};
    Object.keys(obj).forEach((key) => {
      if (key !== "_id") {
        newObj[key] = removeIds(obj[key]);
      }
    });
    return newObj;
  }

  function createNewForm(
    accountingFirmId: objectid,
    title: string,
    description: string,
    sections: Contents<SectionOutput>[],
    rules: Contents<RuleOutput>[],
    creatorId: objectid
  ): Edition<Identified<Contents<FormOutput>>> {
    let newForm: Contents<FormOutput> = {
      accountingFirmId: accountingFirmId,
      title: title,
      description: description,
      draft: true,
      sections: sections?.map((section, index) => ({
        ...section,
        order: index + 1,
      })),
      rules: rules,
      createdAt: new Date(),
      creatorId: creatorId,
    };

    // generate new ids
    let newFormWithTempIds: Edition<Identified<Contents<FormOutput>>> = addTempIds(newForm);
    setTempIdsinRules(newFormWithTempIds);

    // remove old ids
    return removeIds(newFormWithTempIds);
  }

  useEffect(() => {
    (async () => {
      var firstForm: Edition<Identified<Contents<FormOutput>>>;

      // creation by copy
      if (location.state?.duplicate) {
        const resFormCopy: FormOutput = await apiForm.formFindOne(location.state.duplicate);

        firstForm = createNewForm(
          props.accountingFirm!._id,
          resFormCopy.title,
          resFormCopy.description,
          resFormCopy.sections!,
          resFormCopy.rules!,
          "creator-id" // TODO : real creator Id
        );

        setEditedForm(firstForm);
      } else if (location.state?.template) {
        const key: "2042" | "new_employee" | "financial_year_end" | "tax_return" =
          location.state.template;
        const template: Edition<Identified<Contents<FormOutput>>> = templatesForm[key];

        firstForm = createNewForm(
          props.accountingFirm!._id,
          template.title,
          template.description,
          template.sections!,
          template.rules!,
          "creator-id" // TODO : real creator Id
        );
        setEditedForm(firstForm);
        Sentry.setContext("editedForm", firstForm);
      } else if (id) {
        // edition of existant
        const resForm: FormOutput = await apiForm.formFindOne(id);

        resForm.sections = resForm.sections?.map((section, index) => ({
          ...section,
          order: index + 1,
        }));

        let resFormWithTempIds: Identified<FormOutput> = addTempIds(resForm);
        setTempIdsinRules(resFormWithTempIds);

        setEditedForm(resFormWithTempIds);
        props.setForm(resForm);
        Sentry.setContext("form", resForm);
      } else {
        // creation from scratch
        firstForm = createNewForm(
          props.accountingFirm!._id,
          "",
          "",
          [
            {
              title: "Nom de la section...",
              order: 0,
              questions: [],
            },
          ],
          [],
          "creator-id"
        );
        setEditedForm(firstForm);
        Sentry.setContext("editedForm", firstForm);
      }
    })();
  }, []);

  const replaceTempIdInRules = (
    rules: Identified<Contents<EditionRule>>[],
    formOut: FormOutput,
    formIn: Edition<Identified<Contents<FormOutput>>>
  ): void => {
    rules.forEach((rule) => {
      const sectionId = formOut.sections!.find(
        (section) =>
          section.order ===
          formIn.sections!.find(
            (elt: Identified<Contents<SectionOutput>>) => elt.tempId === rule.sectionTempId
          )?.order
      )?._id;
      //id of the Section where the questionId is
      rule.conditions.forEach((condition) => {
        // if condition is ill formed, nothing to look for
        if (condition.questionTempId && condition.valueTempId) {
          //id of the Section where the questionId is
          const sectionContextIndex: number = formIn.sections!.findIndex((section: any) =>
            section.questions!.find((question: any) => question.tempId === condition.questionTempId)
          );

          const questionIndex: number = formOut.sections![sectionContextIndex].questions!.findIndex(
            (question: QuestionOutput) =>
              question.order ===
              formIn.sections![sectionContextIndex].questions!.find(
                (elt: Identified<Contents<QuestionOutput>>) =>
                  elt.tempId === condition.questionTempId
              )!.order
          );

          const questionId: objectid =
            formOut.sections![sectionContextIndex].questions![questionIndex]._id;

          const value: objectid = formOut.sections![sectionContextIndex].questions![
            questionIndex!
          ].options!.find(
            (option) =>
              option.value ===
              formIn.sections![sectionContextIndex].questions![questionIndex].options!.find(
                (elt: Identified<Contents<OptionOutput>>) => elt.tempId === condition.valueTempId
              )!.value
          )!._id;
          condition.questionId = questionId;
          condition.value = value;
        }
      });

      rule.sectionId = sectionId;
    });
  };

  function displayRulesErrors(invalidRules: any[]) {
    return (
      <div>
        Des règles incluant:<br></br>
        {invalidRules.map((r) => (
          <div>
            {r.context.join("/")}
            <br />
          </div>
        ))}
        font réference à quelque chose absent du formulaire, contactez le support
      </div>
    );
  }

  const onFinish = (values: FormOutput) => {
    var res: FormOutput;
    var formWithRules: FormInput;
    (async () => {
      if (editedForm) {
        let theForm: Edition<Identified<Contents<FormOutput>>> = {
          ...editedForm!,
          title: values.title,
          description: values.description,
        };

        const formWithoutRules: Contents<FormOutput> = {
          ...theForm,
          rules: [],
        };

        if (!id && !theForm._id && savedNewForm === "") {
          // if form creation
          const resFirst = await apiForm.formCreateOne(formWithoutRules);

          setSavedNewForm(resFirst._id);

          // when it's a creation, set all firm users as contact of the form
          const allUsers: UserOutput[] = await apiUser.userFindAll();
          await apiUserFormAsso.userFormAssoCreateMany(
            allUsers.map((user) => ({ userId: user._id, formId: resFirst._id }))
          );

          // To do: check if findForm not the same as resFirst being the result of a creation
          const findForm = await apiForm.formFindOne(resFirst._id);

          //replace tempId with the correct _id
          replaceTempIdInRules(theForm.rules!, findForm, theForm!);
          formWithRules = {
            ...resFirst,
            rules: theForm.rules!,
          };
          res = await apiForm.formUpdateOne(resFirst._id, formWithRules);
        } else {
          // if form update and id in the url or if draft created but stay on page
          const formId = id ? id : savedNewForm;
          const resNoRules = await apiForm.formUpdateOne(formId, formWithoutRules);

          replaceTempIdInRules(theForm.rules!, resNoRules, theForm!);
          formWithRules = {
            ...resNoRules,
            rules: theForm.rules!,
          };

          res = await apiForm.formUpdateOne(formId, formWithRules);
        }
        //res.rules![0].sectionTempId = "test";
        //res.rules![0].conditions[0].questionTempId = "test";
        //res.rules![0].conditions[0].valueTempId = "test";
        if (checkRulesValidityOnIds(res, displayRulesErrors)) {
          props.setIsModified(false);
          // To do: check if everything is not already up to date in editedForm
          setEditedForm(theForm);
          props.setForm(res);

          if (!stay) {
            props.setActiveTab("2");
          } else {
            showNotification(
              "success",
              "Votre formulaire a bien été enregistré en tant que brouillon."
            );
          }
        } else {
          throw new Error("Invalid rules");
        }
      }
    })();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const downloadForm = () => {
    let templateForm: any = {};

    // Copie des propriétés de editedForm vers templateForm
    templateForm.tempId = editedForm?.tempId;
    templateForm.title = editedForm?.title;
    templateForm.description = editedForm?.description;
    templateForm.creatorId = "";
    templateForm.accountingFirmId = "";
    templateForm.sections = editedForm?.sections;
    templateForm.draft = true;
    templateForm.createdAt = new Date();

    templateForm.rules = editedForm?.rules;

    const jsonStr = JSON.stringify(templateForm, null, 2);
    const blob = new Blob([jsonStr], { type: "application/json" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "templateForm.json";
    document.body.appendChild(a);
    a.click();
    URL.revokeObjectURL(url);
  };

  return (
    <>
      {editedForm ? (
        <>
          {" "}
          {id && hasSAAccess ? (
            <Button
              style={{ position: "absolute", right: "20px", top: "8px" }}
              type="text"
              icon={<DownloadOutlined />}
              onClick={(e) => {
                e.preventDefault();
                downloadForm();
              }}
            >
              Form JSON
            </Button>
          ) : null}{" "}
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            style={{ maxWidth: 600 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onValuesChange={() => props.setIsModified(true)}
            autoComplete="off"
            layout="vertical"
            initialValues={{
              title: location.state?.duplicate
                ? editedForm?.title + " - Copie du " + dayjs().format("DD/MM/YYYY")
                : editedForm?.title,
              description: editedForm?.description,
            }}
          >
            <Form.Item
              label="Nom du formulaire"
              name="title"
              rules={[{ required: true, message: "Merci de renseigner le nom du formulaire" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Description du formulaire"
              name="description"
              rules={[{ required: true, message: "Merci de renseigner la description" }]}
            >
              <Input style={{ minHeight: "60px" }} />
            </Form.Item>
            <AllSections
              form={editedForm!}
              setForm={setEditedForm}
              setIsModified={props.setIsModified}
              setIsEdited={setIsEdited}
            />
            <Form.Item style={{ marginTop: "20px", textAlign: "right" }}>
              <Button
                htmlType="submit"
                style={{ marginRight: "10px" }}
                onClick={() => {
                  setStay(true);
                }}
                disabled={
                  editedForm?.sections!.filter((elt) => elt.questions!.length === 0).length > 0 ||
                  isEdited > 0
                }
              >
                Enregistrer en tant que brouillon
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => {
                  setStay(false);
                }}
                disabled={
                  editedForm?.sections!.filter((elt) => elt.questions!.length === 0).length > 0 ||
                  isEdited > 0 ||
                  !checkRulesValidityOnOrders(editedForm)
                }
              >
                Envoyer le formulaire
              </Button>
            </Form.Item>
          </Form>
        </>
      ) : null}
    </>
  );
}

export default CreateForm;
