import {
  FormOutput,
  OptionOutput,
  QuestionOutput,
  RuleOutput,
  SectionOutput,
} from "@addventa/sesha-forms-api";
import { message } from "antd";
import { v4 as uuidv4 } from "uuid";
import { Contents, Edition, Identified } from "../../../types/misc/Generic";

export function addTempIds<T extends Contents<FormOutput>>(form: T): Identified<T> {
  let newObj: Identified<T> = form as Identified<T>;

  newObj.tempId = uuidv4();
  if (newObj.sections) {
    newObj.sections.forEach((section: any) => {
      section.tempId = uuidv4();
      if (section.questions) {
        section.questions.forEach((question: any) => {
          question.tempId = uuidv4();
          if (question.options) {
            question.options.forEach((option: any) => {
              option.tempId = uuidv4();
            });
          }
        });
      }
    });
  }

  setTempIdsinRules(newObj);

  return newObj;
}

export function addTempId<T extends {}>(o: T): T & { tempId: string } {
  return Object.assign<T, { tempId: string }>(o, { tempId: uuidv4() });
}

export function setTempIdsinRules(form: Edition<Identified<Contents<FormOutput>>>): void {
  let allQuestions: Identified<Contents<QuestionOutput>>[] = form.sections!.reduce(
    (
      questions: Identified<Contents<QuestionOutput>>[],
      section: Identified<Contents<SectionOutput>>
    ) => questions.concat(section.questions!),
    []
  );
  // reset rule question id and section id with tempId of each corresponding part
  for (let rule of form.rules!) {
    let section: Identified<Contents<SectionOutput>> = form.sections!.find(
      (s: Identified<Contents<SectionOutput>>) => s._id === rule.sectionId
    )!;

    rule.sectionTempId = section.tempId;

    rule.tempId = rule.tempId || uuidv4();

    rule.conditions.forEach((condition) => {
      let question: Identified<Contents<QuestionOutput>> = allQuestions.find(
        (q) => q._id === condition.questionId
      )!;
      let option: Identified<Contents<OptionOutput>> = question.options!.find(
        (o) => o._id === condition.value
      )!;

      condition.questionTempId = question.tempId;
      condition.valueTempId = option.tempId;
    });
  }
}

/**
 *
 * @param sections
 * @param questionId
 * @returns Indexes of section and question couple corresponding to the questionId. Do it in one pass on sections/questions
 */
export function findSectionAndQuestionIndexesFromQuestionId(
  sections: Identified<Contents<SectionOutput>>[],
  questionTempId: string
): [number, number] {
  return sections.reduce<[number, number]>(
    (
      lastFound: [number, number],
      currentSection: Identified<Contents<SectionOutput>>,
      sectionIndex: number
    ) => {
      // if it was already found, return it
      if (!lastFound.includes(-1)) return lastFound;

      // else look for a question in the current section
      let questionIndex = currentSection.questions!.findIndex(
        (question: any) => question.tempId === questionTempId
      );
      if (questionIndex !== -1) return [sectionIndex, questionIndex];

      // else let the other section look for the question
      return lastFound;
    },
    [-1, -1]
  );
}

export function checkRulesValidityOnIds(form: FormOutput, displayError: any): boolean {
  let invalidRules: { rule: RuleOutput; context: string[] }[] = [];

  form.rules!.forEach((r) => {
    let invalidRuleInfo: { rule: RuleOutput; context: string[] } = {
      rule: r,
      context: [],
    };

    let section = form.sections!.find((s) => s._id === r.sectionId);
    // if it was found, store context for other part of the rule
    if (section) {
      invalidRuleInfo.context.push(" section " + ' "' + section.title + '"');
    }
    r.conditions.forEach((c) => {
      // try to locate the referenced question
      let question = form.sections!.reduce<QuestionOutput | undefined>(
        (foundQ, s) => foundQ || s.questions!.find((q) => q._id === c.questionId),
        undefined
      );
      // if it was found, store correct context for potential failure with other part of the rule
      if (question) {
        invalidRuleInfo.context.push(" question " + ' "' + question.title + '"');
      }

      let value = form.sections!.reduce<OptionOutput | undefined>(
        (foundOInSection, s) =>
          foundOInSection ||
          s.questions!.reduce<OptionOutput | undefined>(
            (foundO, q) => foundO || q.options!.find((o) => o._id === c.value),
            undefined
          ),
        undefined
      );
      // if it was found, store context for other part of the rule
      if (value) {
        invalidRuleInfo.context.push(" option " + ' "' + value.label + '"');
      }

      // if anything was missing, store the rule + context as an invalid rule
      if (!section || !question || !value) {
        invalidRules.push(invalidRuleInfo);
      }
    });
  });

  if (invalidRules.length > 0) {
    message.error(displayError(invalidRules));
    console.log(invalidRules);
    console.log(form);
  }
  return invalidRules.length === 0;
}

export function checkRulesValidityOnOrders(
  form: Edition<Identified<Contents<FormOutput>>>
): boolean {
  return form.rules!.every((r) => {
    let targetSection = form.sections!.find((s) => s.tempId === r.sectionTempId)!;
    return (
      targetSection &&
      r.conditions.every((c) => {
        let sectionOfQuestion = form.sections!.find((s) =>
          s.questions!.find((q) => q.tempId === c.questionTempId)
        )!;
        return sectionOfQuestion && sectionOfQuestion?.order < targetSection?.order;
      })
    );
  });
}
