import { ResponseContext } from "@addventa/sesha-forms-api";
import { notification } from "antd";
import { jwtDecode } from "jwt-decode";
import { Token } from "../types/Token/Token";

export const postMiddleware = async (context: ResponseContext) => {
  // Redirection to auth if the client token has expired
  const token =
    sessionStorage.getItem("token") !== null
      ? sessionStorage.getItem("token")
      : localStorage.getItem("token");
  if (token) {
    const decodedToken: Token = jwtDecode<Token>(token);
    if (decodedToken.role === "CLIENT" && Date.now() > decodedToken.exp * 1000) {
      localStorage.removeItem("token");
      window.location.reload();
    }
  }

  // Display errors in notifications in local env
  if (process.env.REACT_APP_ENV !== "local") return;
  if (context.response.status < 200 || context.response.status >= 300) {
    let clonedRes = context.response.clone();
    try {
      let parsed = await clonedRes.json();
      notification.error({
        message: "Error " + context.response.status,
        description: context.response.url + ": " + JSON.stringify(parsed),
      });
    } catch (error) {
      notification.error({
        message: "Error " + context.response.status,
        description: context.response.url,
      });
    }
  }
  return;
};
