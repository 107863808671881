import { ContactOutput, FormAnswersOutput } from "@addventa/sesha-forms-api";

interface ExtendedSeshaFormsAnswers extends FormAnswersOutput {
  isSelected: boolean;
  isLoaded: boolean;
  isLoading: boolean;
  key: string;
  contact: ContactOutput;
}

enum ELockType {
  LOCK,
  LOCKALL,
  UNLOCK,
}

enum EModalMailReminderState {
  NOW,
  SCHEDULING,
}

export { ELockType, EModalMailReminderState };
export type { ExtendedSeshaFormsAnswers };
