import { UserOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Avatar, Col, Dropdown, Layout, Row, Space, Switch, theme } from "antd";
import { useContext } from "react";
import { Link } from "react-router-dom";
import circleLinkTo from "../../assets/images/circleLinkTo.svg";
import { ReactComponent as LogoSeshaFormsBeta } from "../../assets/images/logo-forms-beta.svg";
import seshaLanding from "../../assets/images/logo-sesha.svg";
import seshaVPF from "../../assets/images/logo-vpf.svg";
import downArrow from "../../assets/images/menu-down-arrow.png";
import Context from "../../context/Context";
import { EFeatures } from "../../enums/EFeatures";
import { usePermission } from "../../hooks/usePermission";

const { Header } = Layout;

const Nav = () => {
  const { user, theme: themeType, setThemeType } = useContext(Context);
  const canManageFormsRights = usePermission(EFeatures.MANAGE_FORMS_RIGHTS);
  const hasAccessToSeshaClassic = usePermission(EFeatures.ACCESS_TO_SESHA);
  const hasAccessToAdmin = usePermission(EFeatures.ADMINISTRATION);
  const canManageUsers = usePermission(EFeatures.MANAGE_USERS_AND_RIGHTS);

  const {
    token: { colorBgContainer, colorText, boxShadow },
  } = theme.useToken();

  type CustomMenuProps = {
    show?: boolean;
    key: string;
    label: JSX.Element;
  };
  const items: CustomMenuProps[] = [
    {
      key: "1",
      show: hasAccessToAdmin || canManageUsers,
      label: (
        <Link
          to="#"
          onClick={() => {
            window.location.replace(process.env.REACT_APP_URL_SESHA_CLASSIC_FRONT + "/admin");
          }}
        >
          Administration
        </Link>
      ),
    },
    {
      key: "2",
      show: canManageFormsRights,
      label: <Link to="/rights">Gestion des droits</Link>,
    },
    {
      key: "3",
      show: true,
      label: (
        <Link
          to="#"
          onClick={() => {
            window.location.replace(process.env.REACT_APP_URL_SESHA_CLASSIC_FRONT + "/settings");
          }}
        >
          Mon compte
        </Link>
      ),
    },
    {
      key: "4",
      show: true,
      label: (
        <a href="https://knowledge.sesha.expert/knowledge" rel="noreferrer" target="_blank">
          Aide & FAQ
        </a>
      ),
    },
    {
      key: "5",
      show: true,
      label: (
        <Link
          to="#"
          onClick={() => {
            localStorage.removeItem("token");
            // The check isTokenValid on the HomepageLayout.tsx (parent) will handle the redirection since there is no longer a token
            // Maybe a bad practice, what do I know
            //window.location.replace(process.env.REACT_APP_URL_SESHA_CLASSIC_FRONT + "/logout");
          }}
        >
          Déconnexion
        </Link>
      ),
    },
  ];

  const items2: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <a
          href={process.env.REACT_APP_URL_SESHA_CLASSIC_FRONT}
          style={{
            width: "127px",
            height: "40px",
            display: "block",
          }}
        >
          <div
            style={{
              backgroundImage: `url(${seshaLanding})`,
              width: "110px",
              height: "25px",
              position: "absolute",
              backgroundRepeat: "no-repeat",
              top: "25%",
            }}
          ></div>
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          href={process.env.REACT_APP_URL_SESHA_CLASSIC_FRONT + "/portfolio/"}
          style={{
            width: "127px",
            height: "40px",
            display: "block",
          }}
        >
          <div
            style={{
              backgroundImage: `url(${seshaVPF})`,
              width: "127px",
              height: "40px",
              position: "absolute",
              backgroundRepeat: "no-repeat",
              top: "20%",
            }}
          ></div>
        </a>
      ),
    },
  ];

  return (
    <Header
      style={{
        background: colorBgContainer,
        marginBottom: "30px",
        borderBottomLeftRadius: "20px",
        borderBottomRightRadius: "20px",
        position: "sticky",
        top: 0,
        zIndex: 1000,
        boxShadow: "0px 5px 30px " + boxShadow,
      }}
    >
      <Row>
        <Col span={8}>
          <div style={{ marginTop: "12px" }}>
            <Link to="/">
              <LogoSeshaFormsBeta />
            </Link>
          </div>
          <div style={{ marginTop: "-25px" }}>
            {hasAccessToSeshaClassic && (
              <Dropdown menu={{ items: items2 }} placement="bottom" arrow={{ pointAtCenter: true }}>
                <div
                  style={{
                    backgroundImage: `url(${circleLinkTo})`,
                    width: "32px",
                    height: "32px",
                    position: "absolute",
                    left: "50%",
                    top: "35%",
                  }}
                ></div>
              </Dropdown>
            )}
          </div>
        </Col>
        <Col span={8} offset={8} style={{ textAlign: "right" }}>
          {process.env.REACT_APP_ENV === "local" && (
            <Switch
              defaultChecked={themeType === "dark"}
              onChange={(checked) => setThemeType(checked ? "dark" : "light")}
              style={{ marginRight: "10px" }}
            />
          )}
          <Dropdown
            menu={{
              items: items.filter(
                (item) => item.show && (item?.key !== "1" || canManageFormsRights)
              ),
            }}
            overlayStyle={{ paddingTop: "10px" }}
          >
            <a onClick={(e) => e.preventDefault()} style={{ color: colorText }}>
              <Space>
                <Avatar
                  icon={<UserOutlined />}
                  style={{
                    marginRight: "5px",
                  }}
                />
                {user?.firstName}
                <div
                  style={{
                    backgroundImage: `url(${downArrow})`,
                    height: "32px",
                    width: "32px",
                    display: "inline-block",
                    marginLeft: "5px",
                    verticalAlign: "middle",
                  }}
                ></div>
              </Space>
            </a>
          </Dropdown>
        </Col>
      </Row>
    </Header>
  );
};

export default Nav;
