import { FormOutput } from "@addventa/sesha-forms-api";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, ConfigProvider, Flex, Input, Modal } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router";
import { apiForm } from "../../../../api-configuration/Configuration";

export function FormDeletionValidationModal(props: {
  form: FormOutput;
  modalIsVisible: boolean;
  setModalIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [inputValue, setInputValue] = useState("");

  const navigation = useNavigate();

  const onFinish = async () => {
    await apiForm.formDeleteOne(props.form._id);
    navigation("/", { replace: true });
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary: "#FF9700",
            colorPrimaryHover: "#FF9700",
          },
          Modal: {
            headerBg: "#FFF",
          },
        },
      }}
    >
      <Modal
        open={props.modalIsVisible}
        title={
          <>
            <ExclamationCircleOutlined
              style={{ fontSize: "24px", marginRight: "10px", color: "#FF9700" }}
            />
            Êtes-vous sûr(e) du vouloir supprimer le formulaire ?
          </>
        }
        className="modal-confirmation-lock-form"
        footer={null}
        maskClosable={false}
        closable={false}
      >
        <Flex vertical gap="middle">
          <p style={{ margin: 0 }}>
            La suppression du formulaire est <b>irréversible</b>. Elle entraînera la perte du
            formulaire et de l’intégralité des réponses/documents transmis pas vos clients.
          </p>
          <p style={{ margin: 0 }}>
            Saisissez <b>“Supprimer le formulaire”</b> pour valider la supression.
          </p>
          <Input
            onPaste={(e) => e.preventDefault()}
            onCopy={(e) => e.preventDefault()}
            status="warning"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            style={{
              backgroundColor: "hsla(50, 100%, 95%, 1)",
              fontSize: "1.5em",
              color: "hsla(36, 100%, 50%, 1)",
              fontWeight: "bold",
              letterSpacing: "0.1em",
            }}
          />
          <Flex justify="end" gap="middle" style={{ marginBottom: 20 }}>
            <Button type="default" onClick={() => props.setModalIsVisible(false)}>
              Annuler
            </Button>
            <Button
              type="primary"
              onClick={async () => onFinish()}
              disabled={inputValue.toUpperCase() !== "Supprimer le formulaire".toUpperCase()}
            >
              Valider
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </ConfigProvider>
  );
}
