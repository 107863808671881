import { ContactInput } from "@addventa/sesha-forms-api";

enum EContactCreationStatus {
  ERROR,
  SUCCESS,
  LOADING,
  IDLE,
}

interface ExtendedSeshaContact extends ContactInput {
  key: Number;
  isNewlyAdded?: boolean;
  isFirst?: boolean;
  isInErrorState?: boolean;
  emailIsDuplicate?: boolean;
}

export { EContactCreationStatus };
export type { ExtendedSeshaContact };
