import { FormOutput, QuestionOutput, SectionOutput } from "@addventa/sesha-forms-api";
import {
  DndContext,
  DragEndEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  MeasuringStrategy,
  MouseSensor,
  closestCorners,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { useEffect, useState } from "react";
import { Contents, Edition, Identified } from "../../../../types/misc/Generic";
import { updateOrderAllQuestions } from "../../../../utils/orderQuestions";
import QuestionItem from "../question/QuestionItem";
import Section from "./Section";

function AllSections(props: {
  form: Edition<Identified<Contents<FormOutput>>>;
  setForm: React.Dispatch<
    React.SetStateAction<Edition<Identified<Contents<FormOutput>>> | undefined>
  > | null;
  isReviewMode?: boolean;
  setIsModified?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEdited?: React.Dispatch<React.SetStateAction<number>>;
}) {

  // deconstruct props to use part as depenency in useEffect
  const { setIsEdited } = props;

  const [activeId, setActiveId] = useState<any>();
  const [editedSection, setEditedSection] = useState<string>("");
  const [editedSections, setEditedSections] = useState<string[]>([]);

  useEffect(() => {
    if (setIsEdited) {
      setIsEdited(editedSections.length);
    }
  }, [editedSections, setIsEdited]);

  function calculateCumulativeOrder(
    section: Identified<Contents<SectionOutput>>,
    indexInSection: number,
    allSections: Identified<Contents<SectionOutput>>[],
    betweenSections: boolean
  ): number {
    // Calculate the cumulative order
    let cumulativeOrder = 0;

    for (let i = 0; i < allSections.length; i++) {
      const s = allSections[i];
      if (s.tempId === section.tempId) {
        cumulativeOrder += indexInSection;
        break;
      } else {
        if (s.questions) {
          cumulativeOrder += s.questions.length;
        }
      }
    }

    return cumulativeOrder;
  }

  // DRAG AND DROP START
  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    })
  );

  // Function to update the order of questions within a section
  function updateQuestionOrderInSection(
    section: Identified<Contents<SectionOutput>>,
    activeIndex: number,
    overIndex: number
  ): Identified<Contents<SectionOutput>> {
    var updatedQuestions = [...section.questions!];
    const [removedQuestion] = updatedQuestions.splice(activeIndex, 1);
    updatedQuestions.splice(overIndex, 0, removedQuestion);
    updatedQuestions = updatedQuestions.map((question, index) => ({
      ...question,
      order: calculateCumulativeOrder(section, index, props.form.sections!, false),
    }));

    return { ...section, questions: updatedQuestions };
  }

  // Function to update the form with a modified section
  function updateFormWithUpdatedSection(updatedSection: Identified<Contents<SectionOutput>>): void {
    const updatedForm = {
      ...props.form,
      sections: props.form.sections!.map((section: any) =>
        section.tempId === updatedSection.tempId ? updatedSection : section
      ),
    };
    props.setIsModified!(true);
    props.setForm!(updatedForm);

    //console.log("Form updated:", updatedForm);
  }

  // Function to move a question between sections
  function moveQuestionBetweenSections(
    sourceSection: Identified<Contents<SectionOutput>>,
    targetSection: Identified<Contents<SectionOutput>>,
    sourceIndex: number,
    destinationIndex: number
  ): Edition<Identified<Contents<FormOutput>>> {
    var updatedSourceQuestions = [...sourceSection.questions!];

    const [questionToMove] = updatedSourceQuestions.splice(sourceIndex, 1);

    var updatedTargetQuestions = [...targetSection.questions!];
    updatedTargetQuestions.splice(destinationIndex, 0, questionToMove);

    const updatedSourceSection = { ...sourceSection, questions: updatedSourceQuestions };

    const updatedTargetSection = { ...targetSection, questions: updatedTargetQuestions };

    const updatedForm: Edition<Identified<Contents<FormOutput>>> = {
      ...props.form,
      sections: props.form.sections!.map((section: any) =>
        section.tempId === sourceSection.tempId
          ? updatedSourceSection
          : section.tempId === targetSection.tempId
          ? updatedTargetSection
          : section
      ),
    };

    return updatedForm;
  }

  const handleDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id);
  };

  const handleDragOver = (event: DragOverEvent) => {
    console.log(event);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!active || !over) return;

    const activeContainerId = active.data.current?.sortable.containerId;
    const overContainerId = over.data.current?.sortable.containerId || over.id;

    const activeContainer = props.form.sections!.find(
      (section: Identified<Contents<SectionOutput>>) => section.tempId === activeContainerId
    )!;
    const overContainer = props.form.sections!.find(
      (section: Identified<Contents<SectionOutput>>) => section.tempId === overContainerId
    )!;

    const activeIndex = activeContainer.questions!.findIndex(
      (question: Identified<Contents<QuestionOutput>>) => question.tempId === active.id
    );
    const overIndex = overContainer.questions!.findIndex(
      (question: Identified<Contents<QuestionOutput>>) => question.tempId === over.id
    );

    // If source and target sections are the same, update question order
    if (activeContainerId === overContainerId) {
      const updatedSection = updateQuestionOrderInSection(activeContainer, activeIndex, overIndex);

      updateFormWithUpdatedSection(updatedSection);
    } else {
      // Move the question between sections
      var updatedForm = moveQuestionBetweenSections(
        activeContainer,
        overContainer,
        activeIndex,
        overIndex
      );
      updatedForm = updateOrderAllQuestions(updatedForm);

      props.setForm!(updatedForm);
      props.setIsModified!(true);
    }

    setActiveId(null);
  };

  return (
    <>
      <DndContext
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragOver={handleDragOver}
        collisionDetection={closestCorners}
        measuring={{
          droppable: {
            strategy: MeasuringStrategy.Always,
          },
        }}
      >
        {props.form?.sections?.map(
          (section: Identified<Contents<SectionOutput>>, index: number) => {
            return <Section
              section={section}
              index={index}
              editedSectionId={editedSection}
              setEditedSectionId={setEditedSection}
              editedSections={editedSections}
              setEditedSections={setEditedSections}
              form={props.form}
              setForm={props.setForm}
              isReviewMode={props.isReviewMode}
              setIsModified={props.setIsModified}
              setIsEdited={props.setIsEdited}
            />
          }
        )}

        <DragOverlay>
          {activeId ? (
            <QuestionItem form={props.form} setForm={props.setForm!} id={activeId} />
          ) : null}
        </DragOverlay>
      </DndContext>
    </>
  );
}

export default AllSections;
