import { jwtDecode } from "jwt-decode";

export const useIsTokenValid = (): boolean => {
  sessionStorage.clear();
  const storedToken = localStorage.getItem("token");
  if (!storedToken) return false;

  const { exp } = jwtDecode<{ exp: number }>(storedToken);
  return Date.now() < exp * 1000;
};

export const useIsTokenSessionValid = (): boolean => {
  const storedToken = sessionStorage.getItem("token");
  if (!storedToken) return false;

  const { exp } = jwtDecode<{ exp: number }>(storedToken);
  return Date.now() < exp * 1000;
};
