import { AccountingFirmOutput, FormOutput } from "@addventa/sesha-forms-api";
import { ConfigProvider, Flex, theme } from "antd";
import { useState } from "react";
import { ELockType, ExtendedSeshaFormsAnswers } from "../../ReviewFormModel.d";
import { YourFormAnswersPreviewModal } from "./YourFormAnswersPreviewModal";
import { YourFormAnswersReminderModal } from "./YourFormAnswersReminderModal";
import { YourFormAnswersStats } from "./YourFormAnswersStats";
import { YourFormAnswersTable } from "./YourFormsAnswersTable";

interface Props {
  formAnswersList: ExtendedSeshaFormsAnswers[];
  setFormAnswersList: React.Dispatch<React.SetStateAction<ExtendedSeshaFormsAnswers[]>>;
  form: FormOutput;
  setForm: React.Dispatch<React.SetStateAction<FormOutput | undefined>>;
  accountingFirm: AccountingFirmOutput | undefined;
  setLockType: React.Dispatch<React.SetStateAction<ELockType>>;
  setModalLocakValiationIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function YourFormAnswers(props: Props) {
  const [modalMailReminderIsOpen, setModalMailReminderIsOpen] = useState<boolean>(false);
  const [modalPreviewIsOpen, setModalPreviewIsOpen] = useState<boolean>(false);

  const {
    token: { colorPrimary },
  } = theme.useToken();

  return (
    <ConfigProvider
      theme={{
        components: {
          Card: {
            colorBorder: colorPrimary,
          },
        },
      }}
    >
      <Flex vertical gap={50} className="yourFormAnswers">
        <YourFormAnswersStats
          form={props.form}
          setForm={props.setForm}
          formAnswersList={props.formAnswersList}
          setFormAnswersList={props.setFormAnswersList}
          setModalMailReminderIsOpen={setModalMailReminderIsOpen}
        />
        <YourFormAnswersTable
          form={props.form}
          formAnswersList={props.formAnswersList}
          setFormAnswersList={props.setFormAnswersList}
          setModalIsOpen={setModalPreviewIsOpen}
          accountingFirm={props.accountingFirm}
          setModalLocakValiationIsOpen={props.setModalLocakValiationIsOpen}
          setLockType={props.setLockType}
        />
        <YourFormAnswersReminderModal
          form={props.form}
          formAnswersList={props.formAnswersList}
          setFormAnswersList={props.setFormAnswersList}
          modalIsOpen={modalMailReminderIsOpen}
          setModalIsOpen={setModalMailReminderIsOpen}
          accountingFirm={props.accountingFirm}
        />
        <YourFormAnswersPreviewModal
          formAnswersList={props.formAnswersList}
          form={props.form}
          modalIsOpen={modalPreviewIsOpen}
          setModalIsOpen={setModalPreviewIsOpen}
        />
      </Flex>
    </ConfigProvider>
  );
}

export default YourFormAnswers;
