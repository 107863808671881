import {
  AccountingfirmApi,
  AnswerApi,
  Configuration,
  ContactApi,
  FormAnswersApi,
  FormApi,
  RequestContext,
  ResponseContext,
  TagApi,
  UserApi,
  UserContactAssoApi,
  UserFormAssoApi,
} from "@addventa/sesha-forms-api";
import { postMiddleware } from "./postMiddleware";
import { preMiddleware } from "./preMiddleware";

// API configuration
const configuration = new Configuration({
  basePath: process.env.REACT_APP_FORMS_BACKEND,
  middleware: [
    {
      pre: async (context: RequestContext) => {
        await preMiddleware(context);
      },
      post: async (context: ResponseContext) => {
        await postMiddleware(context);
      },
    },
  ],
});

// Services
export const apiUser = new UserApi(configuration);
export const apiUserFormAsso = new UserFormAssoApi(configuration);
export const apiUserContactAsso = new UserContactAssoApi(configuration);
export const apiContact = new ContactApi(configuration);
export const apiForm = new FormApi(configuration);
export const apiAnswer = new AnswerApi(configuration);
export const apiTag = new TagApi(configuration);
export const apiFormAnswers = new FormAnswersApi(configuration);
export const apiAccountingFirm = new AccountingfirmApi(configuration);
