import { FormOutput } from "@addventa/sesha-forms-api";
import { Contents, Edition, Identified } from "../../../types/misc/Generic";

const templates: {
  "2042": Edition<Identified<Contents<FormOutput>>>;
  new_employee: Edition<Identified<Contents<FormOutput>>>;
  financial_year_end: Edition<Identified<Contents<FormOutput>>>;
  tax_return: Edition<Identified<Contents<FormOutput>>>;
} = {
  "2042": {
    tempId: "be05ff27-be56-4137-91e1-459f6374fdfd",
    title: "Déclaration d'impôt 2024 (revenus personnels 2023)  - Mise à jour de vos informations",
    description:
      "Ce formulaire vous permettra de nous communiquer les informations nous permettant de réaliser, le cas échéant, votre déclaration d'impôt sur le revenu.",
    creatorId: "",
    accountingFirmId: "",
    sections: [
      {
        _id: "6633ad7c22b19ffc8a9225e3",
        title: "Délégation de la réalisation de votre déclaration d'impôt",
        order: 0,
        questions: [
          {
            _id: "6633ad7c22b19ffc8a9225e4",
            type: "TEXTAREA",
            title:
              "Souhaitez-vous confier la mission de réalisation de votre déclaration d'impôt 2024 sur les revenus 2023 à votre cabinet comptable ?",
            mandatory: true,
            multiple: false,
            other: false,
            order: 0,
            newQuestion: false,
            options: [],
            note: "Commentaire cabinet\n",
            tempId: "197b424b-50c1-4db7-b0b1-84807835371d",
          },
        ],
        tempId: "4def38a0-5aab-4cbc-b9e9-d987d7223ef2",
      },
      {
        _id: "6633ad7c22b19ffc8a9225e5",
        title: "Confirmation de non délégation",
        order: 3,
        questions: [
          {
            _id: "6633ad7c22b19ffc8a9225e6",
            type: "TEXTAREA",
            title:
              "Nous vous remercions pour votre retour concernant la mission de préparation de votre déclaration des revenus 2023. ",
            mandatory: false,
            multiple: false,
            other: false,
            order: 1,
            newQuestion: false,
            options: [],
            note: "Nous souhaitons néanmoins vous rappeler les échéances importantes concernant la déclaration de revenus en ligne pour l'année en cours, afin de vous assurer une tranquillité d'esprit dans la gestion de vos obligations fiscales :\n\n\n                              Pour les départements numérotés de 01 à 19, la date limite de déclaration est fixée au jeudi 23 mai 2024 à 23h59.\nPour ceux numérotés de 20 à 54 (y compris le 2A et le 2B), la date limite est le jeudi 30 mai 2024 à 23h59.\nEnfin, pour les départements numérotés de 55 à 974/976, la date limite est le jeudi 6 juin 2024 à 23h59.\nNous restons à votre entière disposition pour toute assistance ou conseil dont vous pourriez avoir besoin dans le futur, que ce soit pour des questions fiscales ou d'autres préoccupations comptables et financières.",
            tempId: "26c9f3e9-d107-4920-bf1b-aa547a040c05",
          },
        ],
        tempId: "3014e04b-65db-4773-a965-1167303481fa",
      },
      {
        _id: "6633ad7c22b19ffc8a9225e7",
        title: "Documents à nous communiquer",
        order: 1,
        questions: [
          {
            _id: "6633ad7c22b19ffc8a9225e8",
            type: "UPLOAD",
            title: "Vos justificatifs d'identité",
            mandatory: false,
            multiple: false,
            other: false,
            order: 2,
            newQuestion: false,
            options: [],
            note: "Carte d'identité en cours de validité pour chaque déclarant",
            tempId: "e92da78f-ce11-49c8-9ea1-e6f256bc720e",
          },
          {
            _id: "6633ad7c22b19ffc8a9225e9",
            type: "UPLOAD",
            title: "Vos justificatifs des revenus 2023",
            mandatory: false,
            multiple: false,
            other: false,
            order: 3,
            newQuestion: false,
            options: [],
            note: "Bulletin de paie du mois de décembre pour les salariés \nDéclarations de revenus d’activité indépendante pour les entrepreneurs et professionnels libéraux\nAttestations de chômage\nAvis de pension, retraite, et rentes\nRelevés de revenus de capitaux mobiliers (intérêts, dividendes...)\nRelevés de plus-values mobilières et immobilières",
            tempId: "c79b7ddf-2f9a-443c-aec5-04aa3eabf558",
          },
          {
            _id: "6633ad7c22b19ffc8a9225ea",
            type: "UPLOAD",
            title: "Vos justificatifs des déductions, réductions et crédits d’impôt ",
            mandatory: false,
            multiple: false,
            other: false,
            order: 4,
            newQuestion: false,
            options: [],
            note: "Justificatifs de dons à des organisations caritatives\nFactures de travaux d’amélioration énergétique dans le logement principal.\nReçus pour frais de garde d’enfants\nDéclarations de frais professionnels (pour ceux optant pour les frais réels)\nJustificatifs de dépenses en faveur de l’aide à domicile\nJustificatifs de versement sur un Plan Epargne Retraite",
            tempId: "b9ec57cc-46e7-4aa4-9e6a-7cf117c6c983",
          },
          {
            _id: "6633ad7c22b19ffc8a9225eb",
            type: "UPLOAD",
            title: "Vos justificatifs concernant les biens immobiliers",
            mandatory: false,
            multiple: false,
            other: false,
            order: 5,
            newQuestion: false,
            options: [],
            note: "- Relevés de revenus fonciers :\n\nQuittances de loyer : Elles servent à prouver les montants perçus dans le cadre de locations nues ou meublées.\nContrats de bail : Ils fournissent des informations détaillées sur la location, notamment la durée du bail, le montant du loyer, et les conditions spécifiques.\n\n- Déclarations de charges et de travaux :\n\nFactures de réparation, d'entretien et d'amélioration : Ces documents justifient les dépenses engagées pour maintenir ou augmenter la valeur du bien et peuvent, dans certains cas, être déduites des revenus fonciers.\nDécomptes de charges de copropriété : Ils permettent de déduire les charges déductibles payées au titre de la copropriété.\n\n- Justificatifs de prêts immobiliers :\n\nÉchéanciers de prêt : Ils indiquent le montant des intérêts d’emprunt payés au cours de l’année, déductibles sous certaines conditions.\nOffre de prêt et tableau d'amortissement : Ces documents peuvent être requis pour vérifier la date d'acquisition ou de réalisation des travaux et le détail des remboursements (capital et intérêts).\n\n- Attestations pour les dispositifs de défiscalisation ",
            tempId: "aa1fd78f-b94a-4d5b-aa81-089654e5cae9",
          },
        ],
        tempId: "85d6af30-c024-48cd-8ca8-e3b693c4797b",
      },
      {
        _id: "6633ad7c22b19ffc8a9225ec",
        title: "Changements à nous signaler",
        order: 2,
        questions: [
          {
            _id: "6633ad7c22b19ffc8a9225ed",
            type: "OPTION",
            title: "Avez-vous déménagé en 2023 ou 2024 ?",
            mandatory: true,
            multiple: false,
            other: false,
            order: 6,
            newQuestion: false,
            options: [
              {
                _id: "6633ad7c22b19ffc8a9225ee",
                label: "Oui, je déclare un déménagement au cours de cette période",
                value: "1",
                tempId: "47eb2652-f281-425c-8cac-09bb290d4c5f",
              },
              {
                _id: "6633ad7c22b19ffc8a9225ef",
                label: "Non, je n'ai pas déménagé au cours de cette période",
                value: "2",
                tempId: "f75dc4e5-de5a-4787-8ec1-f9f65f47b038",
              },
            ],
            tempId: "e4a342cc-7ec3-40e6-88bb-e1a4dcb3214e",
          },
          {
            _id: "6633ad7c22b19ffc8a9225f0",
            type: "TEXTAREA",
            title: "Si vous avez déménagé, pouvez-vous nous indiquer la date exacte ?",
            mandatory: false,
            multiple: false,
            other: false,
            order: 7,
            newQuestion: false,
            options: [],
            tempId: "698b834b-94d4-4558-8ac5-8ce267f81e3a",
          },
          {
            _id: "6633ad7c22b19ffc8a9225f1",
            type: "OPTION",
            title: "Avez-vous cédé un bien immobilier en 2023 ?",
            mandatory: true,
            multiple: false,
            other: false,
            order: 9,
            newQuestion: false,
            options: [
              {
                _id: "6633ad7c22b19ffc8a9225f2",
                label: "Je déclare avoir cédé un bien immobilier en 2023",
                value: "1",
                tempId: "8669be05-0471-42c8-9206-462f938f2832",
              },
              {
                _id: "6633ad7c22b19ffc8a9225f3",
                label: "Je n'ai pas cédé de bien immobilier en 2023",
                value: "2",
                tempId: "9fa3fc8f-de7d-4b90-85fc-281adefec499",
              },
            ],
            note: "Si vous avez cédé un bien immobilier, le montant de la plus-value imposable doit être reporté sur votre déclaration pour le calcul du revenu fiscal de référence.",
            tempId: "905e1ef7-fa05-4e13-b3d1-48cf19cc9ff9",
          },
          {
            _id: "6633ad7c22b19ffc8a9225f4",
            type: "TEXTAREA",
            title: "Combien d'enfants scolarisés avez-vous à charge ?",
            mandatory: false,
            multiple: false,
            other: false,
            order: 9,
            newQuestion: false,
            options: [],
            note: "Conditions pour bénéficier de la réduction d'impôt :\n\nSi vous avez un enfant à charge, qu'il soit mineur ou majeur mais rattaché à votre foyer fiscal, il doit remplir les critères suivants pour que vous puissiez bénéficier de la réduction d'impôt :\n\n- ne pas être engagé par un contrat de travail et être libre de tout engagement pendant et après ses études\n- ne pas percevoir de rémunération\n\nLa réduction d'impôt ne s'applique pas si votre enfant se trouve dans l'une des situations suivantes :\n\n- en apprentissage\n- en congé formation\n- engagé dans un contrat d'études avec son employeur\n",
            tempId: "89ac0761-e9db-495c-8653-4bf6f1706415",
          },
          {
            _id: "6633ad7c22b19ffc8a9225f5",
            type: "OPTION",
            title:
              "Au cours de l'année 2023, avez-vous été concerné par des évènements familiaux ? (naissance(s), mariage, divorce, pacs, décès...)",
            mandatory: true,
            multiple: false,
            other: false,
            order: 10,
            newQuestion: false,
            options: [
              {
                _id: "6633ad7c22b19ffc8a9225f6",
                label: "Oui",
                value: "1",
                tempId: "4402028f-d18c-4979-b436-6a6c34b444b3",
              },
              {
                _id: "6633ad7c22b19ffc8a9225f7",
                label: "Non",
                value: "2",
                tempId: "928fdaca-70e4-47b3-9150-f12f0999dac9",
              },
            ],
            tempId: "9bffec17-1342-4651-a84c-e2835ca227f3",
          },
          {
            _id: "6633ad7c22b19ffc8a9225f8",
            type: "TEXTAREA",
            title: "Si vous avez répondu oui à la question précédente, pouvez-vous préciser ?",
            mandatory: false,
            multiple: false,
            other: false,
            order: 11,
            newQuestion: false,
            options: [],
            tempId: "6f673b15-13f8-4138-b896-d4433dff5133",
          },
          {
            _id: "6633ad7c22b19ffc8a9225f9",
            type: "OPTION",
            title:
              "Dans le cadre de nos obligations réglementaires et afin d'assurer la conformité avec les lois fiscales applicables, nous vous demandons de bien vouloir indiquer si vous détenez ou avez autorité sur des comptes bancaires situés hors du territoire national.",
            mandatory: true,
            multiple: false,
            other: false,
            order: 12,
            newQuestion: false,
            options: [
              {
                _id: "6633ad7c22b19ffc8a9225fa",
                label: "Oui",
                value: "1",
                tempId: "f07f6115-2609-4738-8f5e-8dcb997bcd9b",
              },
              {
                _id: "6633ad7c22b19ffc8a9225fb",
                label: "Non",
                value: "2",
                tempId: "181985d8-c49e-4f92-b781-25f44bd1be14",
              },
            ],
            note: "Si vous détenez des comptes bancaires à l’étranger (amende minimum de 1500 € en cas de non-déclaration : https://www.service-public.fr/particuliers/vosdroits/F34342), un formulaire spécifique est à joindre impérativement à votre déclaration de revenus.",
            tempId: "66f2fd2a-b75b-4c1b-a9a0-ecb449ce9f7c",
          },
        ],
        tempId: "78831008-18e8-4968-86c1-8c23638c464f",
      },
      {
        _id: "6633ad7c22b19ffc8a9225fc",
        title: "Comptes à l'étranger",
        order: 4,
        questions: [
          {
            _id: "6633ad7c22b19ffc8a9225fd",
            type: "UPLOAD",
            title:
              "Si vous disposez d'un ou de plusieurs compte(s) bancaire(s) à l'étranger, il est obligatoire de nous transmettre un récapitulatif du/des compte(s) détenu(s) à l'étranger :",
            mandatory: false,
            multiple: false,
            other: false,
            order: 13,
            newQuestion: false,
            options: [],
            note: "Ce récapitulatif devra contenir :\n- Le nom du détenteur du/des compte(s)\n- La nature du/des compte(s)\n- Le numéro du/des compte(s)\n- La date d'ouverture (et éventuellement de clôture) du/des compte(s)\n- L'organisme gestionnaire du/des compte(s)\n- L'usage du/des compte(s)",
            tempId: "dbee3b2d-c609-405c-ae93-053bdac36b25",
          },
        ],
        tempId: "e23cca12-39ee-4a7b-9341-6e19173b17c3",
      },
      {
        _id: "6633ad7c22b19ffc8a9225fe",
        title: "Autres éléments/documents à nous communiquer",
        order: 5,
        questions: [
          {
            _id: "6633ad7c22b19ffc8a9225ff",
            type: "TEXTAREA",
            title: "Autres éléments à nous communiquer",
            mandatory: false,
            multiple: false,
            other: false,
            order: 14,
            newQuestion: false,
            options: [],
            tempId: "1eeaae36-0c76-43d2-b888-54296c0dc753",
          },
          {
            _id: "6633ad7c22b19ffc8a922600",
            type: "UPLOAD",
            title: "Autres documents à nous communiquer",
            mandatory: false,
            multiple: false,
            other: false,
            order: 15,
            newQuestion: false,
            options: [],
            tempId: "5b9cec33-e954-488e-8de6-27d3be0ec973",
          },
        ],
        tempId: "cdf55d33-ca21-44ae-9412-3e311b9c3c78",
      },
    ],
    rules: [
      {
        _id: "6633ad7d22b19ffc8a92262a",
        sectionId: "6633ad7c22b19ffc8a9225fe",
        conditions: [
          {
            questionId: "6633ad7c22b19ffc8a9225f9",
            value: "6633ad7c22b19ffc8a9225fb",
            questionTempId: "66f2fd2a-b75b-4c1b-a9a0-ecb449ce9f7c",
            tempId: "181985d8-c49e-4f92-b781-25f44bd1be14",
          },
        ],
        tempId: "cdf55d33-ca21-44ae-9412-3e311b9c3c78",
      },
      {
        _id: "6633ad7d22b19ffc8a92262c",
        sectionId: "6633ad7c22b19ffc8a9225fc",
        conditions: [
          {
            questionId: "6633ad7c22b19ffc8a9225f9",
            value: "6633ad7c22b19ffc8a9225fa",
            questionTempId: "66f2fd2a-b75b-4c1b-a9a0-ecb449ce9f7c",
            tempId: "f07f6115-2609-4738-8f5e-8dcb997bcd9b",
          },
        ],
        tempId: "e23cca12-39ee-4a7b-9341-6e19173b17c3",
      },
    ],
    draft: true,
    createdAt: new Date(),
  },
  new_employee: {
    tempId: "66033d2b78928fa8cc4f92d7",
    accountingFirmId: "",
    title: "Collecte des Documents et Informations de votre nouveau salarié",
    description:
      "Recueillez les informations essentielles et documents requis des nouveaux employés pour compléter leur dossier administratif et respecter les obligations légales.",
    creatorId: "",
    sections: [
      {
        title: "Informations Générales",
        order: 0,
        questions: [
          {
            type: "TEXTAREA",
            title: "Nom complet :",
            mandatory: true,
            multiple: false,
            other: false,
            newQuestion: false,
            order: 0,
            options: [],
            _id: "66033d2b78928fa8cc4f92d9",
            tempId: "66033d2b78928fa8cc4f92d9",
          },
          {
            type: "OPTION",
            title: "Mode de règlement du salaire :",
            mandatory: true,
            multiple: false,
            other: false,
            newQuestion: false,
            order: 1,
            options: [
              {
                label: "Chèque",
                value: "1",
                _id: "66033d2b78928fa8cc4f92db",
                tempId: "66033d2b78928fa8cc4f92db",
              },
              {
                label: "Virement",
                value: "2",
                _id: "66033d2b78928fa8cc4f92dc",
                tempId: "66033d2b78928fa8cc4f92dc",
              },
            ],
            _id: "66033d2b78928fa8cc4f92da",
            tempId: "66033d2b78928fa8cc4f92da",
          },
        ],
        _id: "66033d2b78928fa8cc4f92d8",
        tempId: "66033d2b78928fa8cc4f92d8",
      },
      {
        title: "Situation Maritale et Familiale",
        order: 1,
        questions: [
          {
            type: "OPTION",
            title: "Statut marital (Célibataire, Marié(e), Pacsé(e), Divorcé(e), Veuf(ve)) :",
            mandatory: true,
            multiple: false,
            other: false,
            newQuestion: false,
            order: 1,
            options: [
              {
                label: "Célibataire",
                value: "1",
                _id: "66033d2b78928fa8cc4f92df",
                tempId: "66033d2b78928fa8cc4f92df",
              },
              {
                label: "Marié(e)",
                value: "2",
                _id: "66033d2b78928fa8cc4f92e0",
                tempId: "66033d2b78928fa8cc4f92e0",
              },
              {
                label: "Pacsé(e)",
                value: "3",
                _id: "66033d2b78928fa8cc4f92e1",
                tempId: "66033d2b78928fa8cc4f92e1",
              },
              {
                label: " Divorcé(e)",
                value: "4",
                _id: "66033d2b78928fa8cc4f92e2",
                tempId: "66033d2b78928fa8cc4f92e2",
              },
              {
                label: "Veuf(ve)",
                value: "5",
                _id: "66033d2b78928fa8cc4f92e3",
                tempId: "66033d2b78928fa8cc4f92e3",
              },
            ],
            _id: "66033d2b78928fa8cc4f92de",
            tempId: "66033d2b78928fa8cc4f92de",
          },
          {
            type: "TEXTAREA",
            title: "Nombre d'enfants à charge :",
            mandatory: true,
            multiple: false,
            other: false,
            newQuestion: false,
            order: 2,
            options: [],
            _id: "66033d2b78928fa8cc4f92e4",
            tempId: "66033d2b78928fa8cc4f92e4",
          },
          {
            type: "TEXTAREA",
            title:
              "Autres informations  importante ne figurant pas sur le contrat à nous communiquer :",
            mandatory: false,
            multiple: false,
            other: false,
            newQuestion: false,
            order: 3,
            options: [],
            _id: "66033d2b78928fa8cc4f92e5",
            tempId: "66033d2b78928fa8cc4f92e5",
          },
        ],
        _id: "66033d2b78928fa8cc4f92dd",
        tempId: "66033d2b78928fa8cc4f92dd",
      },
      {
        title: "Documents à Fournir ",
        order: 2,
        questions: [
          {
            type: "UPLOAD",
            title:
              "Veuillez joindre les documents suivants à ce formulaire : Contrat de Travail Signé",
            mandatory: true,
            multiple: false,
            other: false,
            newQuestion: false,
            order: 0,
            options: [],
            note: "Assurez-vous que le contrat de travail est signé par les deux parties (employeur et employé).",
            _id: "66033d2b78928fa8cc4f92e7",
            tempId: "66033d2b78928fa8cc4f92e7",
          },
          {
            type: "UPLOAD",
            title:
              "Veuillez joindre les documents suivants à ce formulaire : Pièce d'Identité (si non transmis)",
            mandatory: false,
            multiple: false,
            other: false,
            newQuestion: false,
            order: 1,
            options: [],
            note: "Une copie de votre carte d'identité nationale, passeport ou titre de séjour en cours de validité.",
            _id: "66033d2b78928fa8cc4f92e8",
            tempId: "66033d2b78928fa8cc4f92e8",
          },
          {
            type: "UPLOAD",
            title:
              " Veuillez joindre les documents suivants à ce formulaire : Carte Vitale (si non transmis)",
            mandatory: false,
            multiple: false,
            other: false,
            newQuestion: false,
            order: 2,
            options: [],
            note: "Une copie de votre carte Vitale ou de l'attestation de droits à l'assurance maladie.",
            _id: "66033d2b78928fa8cc4f92e9",
            tempId: "66033d2b78928fa8cc4f92e9",
          },
          {
            type: "UPLOAD",
            title:
              "Veuillez joindre les documents suivants à ce formulaire :  Relevé d'Identité Bancaire (RIB)",
            mandatory: false,
            multiple: false,
            other: false,
            newQuestion: false,
            order: 3,
            options: [],
            note: "Un RIB pour le versement des salaires afin de réaliser un fichier des paiements",
            _id: "66033d2b78928fa8cc4f92ea",
            tempId: "66033d2b78928fa8cc4f92ea",
          },
        ],
        _id: "66033d2b78928fa8cc4f92e6",
        tempId: "66033d2b78928fa8cc4f92e6",
      },
    ],
    rules: [],
    draft: true,
    createdAt: new Date(),
  },
  financial_year_end: {
    tempId: "6608148078928fa8cc4fc149",
    accountingFirmId: "",
    title: "Finalisation de vos comptes annuels, éléments à nous transmettre rapidement",
    description:
      "Dans le cadre de la finalisation de vos comptes annuels, nous vous prions de bien vouloir nous transmettre les documents suivants avant le [date limite à insérer]. Cette démarche est essentielle pour nous permettre de réaliser une révision complète et précise de vos comptes.",
    creatorId: "",
    sections: [
      {
        title: "Stocks et travaux en cours",
        order: 0,
        questions: [
          {
            type: "UPLOAD",
            title: "Stock de Marchandises/Matières Premières",
            mandatory: false,
            multiple: false,
            other: false,
            newQuestion: false,
            order: 0,
            options: [],
            note: "Merci de nous fournir une estimation détaillée de votre stock au [date de clôture des comptes]. Ceci inclut les marchandises non vendues et les matières premières non utilisées.",
            _id: "6608148078928fa8cc4fc14b",
            tempId: "6608148078928fa8cc4fc14b",
          },
          {
            type: "UPLOAD",
            title: "Travaux en Cours et Factures à Établir",
            mandatory: false,
            multiple: false,
            other: false,
            newQuestion: false,
            order: 1,
            options: [],
            note: "Fournissez une liste détaillée des travaux en cours ainsi que des factures à établir à la date de clôture des comptes. Cela inclut tous les services ou produits qui n'ont pas encore été facturés à vos clients.",
            _id: "6608148078928fa8cc4fc14c",
            tempId: "6608148078928fa8cc4fc14c",
          },
        ],
        _id: "6608148078928fa8cc4fc14a",
        tempId: "6608148078928fa8cc4fc14a",
      },
      {
        title: "Vos charges sociales personnelles (si vous êtes concerné)",
        order: 1,
        questions: [
          {
            type: "UPLOAD",
            title: "Attestations de Cotisations Facultatives pour les TNS",
            mandatory: false,
            multiple: false,
            other: false,
            newQuestion: false,
            order: 2,
            options: [],
            note: "Pour les Travailleurs Non-Salariés (TNS), veuillez nous transmettre les attestations de vos cotisations à des régimes facultatifs tels que Madelin, PER, etc.",
            _id: "6608148078928fa8cc4fc14e",
            tempId: "6608148078928fa8cc4fc14e",
          },
        ],
        _id: "6608148078928fa8cc4fc14d",
        tempId: "6608148078928fa8cc4fc14d",
      },
      {
        title: "Autres documents utiles à la finalisation de vos comptes annuels",
        order: 2,
        questions: [
          {
            type: "UPLOAD",
            title: "Documents Utiles à la Révision des Comptes",
            mandatory: false,
            multiple: false,
            other: false,
            newQuestion: false,
            order: 3,
            options: [],
            note: "Tout document pouvant être pertinent à la révision des comptes. Ceci peut inclure, mais n'est pas limité à, les contrats en cours, les baux immobiliers, les polices d'assurance, les prêts en cours, les accords de partenariat, etc.",
            _id: "6608148078928fa8cc4fc150",
            tempId: "6608148078928fa8cc4fc150",
          },
        ],
        _id: "6608148078928fa8cc4fc14f",
        tempId: "6608148078928fa8cc4fc14f",
      },
    ],
    rules: [],
    draft: true,
    createdAt: new Date(),
  },
  tax_return: {
    tempId: "36fa67a8-d394-4158-a4dd-e5fe65f2052d",
    title: "Déclaration de revenus - Nouvelle déclaration",
    description: "Déclaration sur les revenus 2023 et/ou IFI 2024",
    creatorId: "",
    accountingFirmId: "",
    sections: [
      {
        _id: "66300e365c6c8499e3852336",
        title: "Informations préalables",
        order: 0,
        questions: [
          {
            _id: "66300e365c6c8499e3852337",
            type: "OPTION",
            title: "Avez-vous changé d'adresse en 2023/2024 ? ",
            mandatory: true,
            multiple: false,
            other: false,
            order: 0,
            newQuestion: false,
            options: [
              {
                _id: "66300e365c6c8499e3852338",
                label: "Oui",
                value: "1",
                tempId: "24b35d1b-2786-4155-b9f9-8d95f842ebcf",
              },
              {
                _id: "66300e365c6c8499e3852339",
                label: "Non",
                value: "2",
                tempId: "efffe371-5a63-42e1-bcd9-d549e623375d",
              },
            ],
            tempId: "e2b38458-6ba1-4b26-8726-2250f7d05d57",
          },
          {
            _id: "66300e365c6c8499e385233a",
            type: "TEXTAREA",
            title: "Si oui, indiquez la date du changement : ",
            mandatory: false,
            multiple: false,
            other: false,
            order: 1,
            newQuestion: false,
            options: [],
            tempId: "400441f8-e8d5-4906-aa45-e3787e297dd0",
          },
          {
            _id: "66300e365c6c8499e385233b",
            type: "TEXTAREA",
            title: "Adresse actuelle :",
            mandatory: true,
            multiple: false,
            other: false,
            order: 2,
            newQuestion: false,
            options: [],
            tempId: "e1209170-c9d3-44d9-942a-f2ec4d1da5ab",
          },
          {
            _id: "66300e365c6c8499e385233c",
            type: "OPTION",
            title: "Vous êtes :",
            mandatory: true,
            multiple: false,
            other: false,
            order: 3,
            newQuestion: false,
            options: [
              {
                _id: "66300e365c6c8499e385233d",
                label: "Propriétaire",
                value: "1",
                tempId: "dc3cfc49-7a8a-4e30-b45c-baf91effb50d",
              },
              {
                _id: "66300e365c6c8499e385233e",
                label: "Locataire",
                value: "2",
                tempId: "8ae2d491-f068-46da-a922-47e07ef4c135",
              },
              {
                _id: "66300e365c6c8499e385233f",
                label: "Occupant",
                value: "3",
                tempId: "d7a15694-1990-46d3-ab30-3daf99b29717",
              },
            ],
            tempId: "d68677eb-a44b-4141-947b-53dcdc173298",
          },
        ],
        tempId: "09517d83-d733-48ef-8a10-ecd8a96f2405",
      },
      {
        _id: "66300e365c6c8499e3852340",
        title: "PRÉPARATION DÉCLARATION N° 2042 ET ANNEXES",
        order: 1,
        questions: [
          {
            _id: "66300e365c6c8499e3852341",
            type: "OPTION",
            title: "Votre situation familiale a-t-elle changé ?",
            mandatory: true,
            multiple: false,
            other: false,
            order: 4,
            newQuestion: false,
            options: [
              {
                _id: "66300e365c6c8499e3852342",
                label: "Oui",
                value: "1",
                tempId: "312db363-0fef-4e74-b29e-0133e556ccbe",
              },
              {
                _id: "66300e365c6c8499e3852343",
                label: "Non",
                value: "2",
                tempId: "6feb7a07-93af-4a74-8935-d2ad9d5e3b5f",
              },
            ],
            note: "(Mariage, pacs, divorces, décès, naissance, …) ",
            tempId: "fe696922-ed63-4708-9fa0-2f018a2568f5",
          },
          {
            _id: "66300e365c6c8499e3852344",
            type: "TEXTAREA",
            title: "Commentaires : ",
            mandatory: false,
            multiple: false,
            other: false,
            order: 5,
            newQuestion: false,
            options: [],
            tempId: "e75a1e4c-d2c3-42b5-9187-09397210fefa",
          },
          {
            _id: "66300e365c6c8499e3852345",
            type: "UPLOAD",
            title: "Pièce jointe :",
            mandatory: false,
            multiple: false,
            other: false,
            order: 6,
            newQuestion: false,
            options: [],
            tempId: "c632ec8f-c697-4bb6-8483-8cf2002ec1f3",
          },
          {
            _id: "66300e365c6c8499e3852346",
            type: "OPTION",
            title: "La structure de votre foyer familial a-t-elle changé ? ",
            mandatory: true,
            multiple: false,
            other: false,
            order: 7,
            newQuestion: false,
            options: [
              {
                _id: "66300e365c6c8499e3852347",
                label: "Oui",
                value: "1",
                tempId: "248aa567-daea-4446-800c-f6e9c6b6c81c",
              },
              {
                _id: "66300e365c6c8499e3852348",
                label: "Non",
                value: "2",
                tempId: "bcf6d7af-85b2-4406-a327-7f5f0b93cf0d",
              },
            ],
            note: "(Naissance, rattachement d'un enfant non majeur, etc.)",
            tempId: "2604c315-b27e-4554-b222-09f5608e28cf",
          },
          {
            _id: "66300e365c6c8499e3852349",
            type: "TEXTAREA",
            title: "Commentaires : ",
            mandatory: false,
            multiple: false,
            other: false,
            order: 8,
            newQuestion: false,
            options: [],
            tempId: "2ff2415e-956c-44e0-800a-4c28d81a580d",
          },
          {
            _id: "66300e365c6c8499e385234a",
            type: "OPTION",
            title: "Hébergez-vous sous votre toit des personnes autres que vos enfants ?",
            mandatory: true,
            multiple: false,
            other: false,
            order: 9,
            newQuestion: false,
            options: [
              {
                _id: "66300e365c6c8499e385234b",
                label: "Oui",
                value: "1",
                tempId: "93170f72-f3dd-4367-bef1-ff38536fae81",
              },
              {
                _id: "66300e365c6c8499e385234c",
                label: "Non",
                value: "2",
                tempId: "5dd5e342-7185-4760-a7c4-df17da1dc80f",
              },
            ],
            tempId: "997ea689-e0ca-44ab-9bdf-c5c917fecc69",
          },
          {
            _id: "66300e365c6c8499e385234d",
            type: "TEXTAREA",
            title: "Commentaires : ",
            mandatory: false,
            multiple: false,
            other: false,
            order: 10,
            newQuestion: false,
            options: [],
            tempId: "20fb0edd-198b-44c0-a2a7-47871a3d0556",
          },
          {
            _id: "66300e365c6c8499e385234e",
            type: "OPTION",
            title: "Indiquez si  : ",
            mandatory: true,
            multiple: true,
            other: false,
            order: 11,
            newQuestion: false,
            options: [
              {
                _id: "66300e365c6c8499e385234f",
                label: "Vous avez un enfant handicapé",
                value: "1",
                tempId: "651cbe7b-cc4a-47a4-82e8-21aaf5944970",
              },
              {
                _id: "66300e365c6c8499e3852350",
                label: "Vous avez un enfant en garde alternée",
                value: "2",
                tempId: "d0efe791-6815-4ccc-adac-1f0964178924",
              },
              {
                _id: "66300e365c6c8499e3852351",
                label: "Vous êtes un parent isolé",
                value: "3",
                tempId: "4882f2fe-4115-46ca-8a3a-20def674e3d5",
              },
              {
                _id: "66300e365c6c8499e3852352",
                label: "Non concerné",
                value: "4",
                tempId: "c8a008aa-486d-481f-8f57-f0ae043db9c8",
              },
            ],
            tempId: "a5980423-726e-4fa3-ab58-496f3c1207cd",
          },
          {
            _id: "66300e365c6c8499e3852353",
            type: "TEXTAREA",
            title: "Commentaires : ",
            mandatory: false,
            multiple: false,
            other: false,
            order: 12,
            newQuestion: false,
            options: [],
            tempId: "ba8488ec-6f75-41ed-b5fc-ebc9651a6020",
          },
          {
            _id: "66300e365c6c8499e3852354",
            type: "OPTION",
            title: "Frais de garde d’enfants de moins de 7 ans :",
            mandatory: true,
            multiple: false,
            other: false,
            order: 13,
            newQuestion: false,
            options: [
              {
                _id: "66300e365c6c8499e3852355",
                label: "Oui",
                value: "1",
                tempId: "71dec777-ee7d-4d3b-bd05-21cd6ca7bba4",
              },
              {
                _id: "66300e365c6c8499e3852356",
                label: "Non",
                value: "2",
                tempId: "8902dac9-b713-4052-8188-a4c5514552e3",
              },
            ],
            tempId: "79079e2d-1ac2-4184-92c5-70f1d233c913",
          },
          {
            _id: "66300e365c6c8499e3852357",
            type: "UPLOAD",
            title: "Pièce jointe : ",
            mandatory: false,
            multiple: false,
            other: false,
            order: 14,
            newQuestion: false,
            options: [],
            tempId: "de12ca87-30f8-4005-b0f4-28656427d5de",
          },
          {
            _id: "66300e365c6c8499e3852358",
            type: "OPTION",
            title: "Avez-vous des enfants poursuivant des études au 1er janvier 2023 ?",
            mandatory: true,
            multiple: false,
            other: false,
            order: 15,
            newQuestion: false,
            options: [
              {
                _id: "66300e365c6c8499e3852359",
                label: "Oui",
                value: "1",
                tempId: "7015cad4-1e68-40d9-930e-2052f2e87ab4",
              },
              {
                _id: "66300e365c6c8499e385235a",
                label: "Non",
                value: "2",
                tempId: "c84d7245-fa1e-4b37-9e19-821b68380b77",
              },
            ],
            tempId: "489a9999-2862-43c3-aa7d-a2a01bfc83c6",
          },
          {
            _id: "66300e365c6c8499e385235b",
            type: "TEXTAREA",
            title:
              "Si oui, indiquez le nombre d'enfants et leur niveau d'études (collège, lycée ou études supérieures).",
            mandatory: false,
            multiple: false,
            other: false,
            order: 16,
            newQuestion: false,
            options: [],
            tempId: "29b1f85d-055a-451a-b73c-6b8a2b2aae49",
          },
          {
            _id: "66300e365c6c8499e385235c",
            type: "TEXTAREA",
            title: "Avez-vous des enfants étudiants bénéficiant de leur propre logement ?",
            mandatory: false,
            multiple: false,
            other: false,
            order: 17,
            newQuestion: false,
            options: [],
            note: "Si oui, merci d'indiquer le prénom de l'enfant et son adresse",
            tempId: "cfd42a81-1c8a-4844-b0e9-55d636ee2d50",
          },
          {
            _id: "66300e365c6c8499e385235d",
            type: "OPTION",
            title:
              "Avez-vous perçu une rémunération de nature différente par rapport aux années précédentes ? ",
            mandatory: true,
            multiple: false,
            other: false,
            order: 18,
            newQuestion: false,
            options: [
              {
                _id: "66300e365c6c8499e385235e",
                label: "Oui",
                value: "1",
                tempId: "6d2f3ad9-5f60-420c-b791-063a8a337021",
              },
              {
                _id: "66300e365c6c8499e385235f",
                label: "Non",
                value: "2",
                tempId: "582b7329-bcab-4412-82b0-8a8f5b0b2786",
              },
            ],
            tempId: "3fe01b74-b11a-48c8-8b83-a8a7f5886feb",
          },
          {
            _id: "66300e365c6c8499e3852360",
            type: "TEXTAREA",
            title:
              "Si oui, de quelle nature ? (indemnités maladie/maternité, allocation chômage, etc.)",
            mandatory: false,
            multiple: false,
            other: false,
            order: 19,
            newQuestion: false,
            options: [],
            tempId: "0def5bb2-a3e4-4e25-a754-0c16f346ecad",
          },
          {
            _id: "66300e365c6c8499e3852361",
            type: "UPLOAD",
            title: "Pièce jointe :",
            mandatory: false,
            multiple: false,
            other: false,
            order: 20,
            newQuestion: false,
            options: [],
            tempId: "50a56b38-997b-47a5-b83e-38f7f6752775",
          },
          {
            _id: "66300e365c6c8499e3852362",
            type: "OPTION",
            title: "Percevez-vous des revenus professionnels ? ",
            mandatory: true,
            multiple: false,
            other: false,
            order: 21,
            newQuestion: false,
            options: [
              {
                _id: "66300e365c6c8499e3852363",
                label: "Oui",
                value: "1",
                tempId: "198cc87c-2341-4955-a334-6b1a3db8cc23",
              },
              {
                _id: "66300e365c6c8499e3852364",
                label: "Non",
                value: "2",
                tempId: "a21ef8d4-da3e-437c-9940-904a0e90620f",
              },
            ],
            tempId: "31eee8ea-2342-4b09-bea2-89ac85efbe9b",
          },
          {
            _id: "66300e365c6c8499e3852365",
            type: "TEXTAREA",
            title: "Si oui, indiquez les montants :  ",
            mandatory: false,
            multiple: false,
            other: false,
            order: 22,
            newQuestion: false,
            options: [],
            tempId: "53641d92-9549-4af1-9f43-9f780dc4566b",
          },
          {
            _id: "66300e365c6c8499e3852366",
            type: "UPLOAD",
            title:
              "Joindre la copie de la déclaration des revenus professionnels si elle n’a pas été faite par le cabinet. Pour les indépendants, il convient également de transmettre les éléments pour remplir le volet social.",
            mandatory: false,
            multiple: false,
            other: false,
            order: 23,
            newQuestion: false,
            options: [],
            tempId: "ae88a51c-aa59-47ae-805b-73647d5a9922",
          },
          {
            _id: "66300e365c6c8499e3852367",
            type: "OPTION",
            title:
              "Percevez-vous des revenus de l’étranger ? (salaires, dividendes, intérêts, etc.)",
            mandatory: true,
            multiple: false,
            other: false,
            order: 24,
            newQuestion: false,
            options: [
              {
                _id: "66300e365c6c8499e3852368",
                label: "Oui",
                value: "1",
                tempId: "d4994239-700e-47d7-8281-31bd78971b08",
              },
              {
                _id: "66300e365c6c8499e3852369",
                label: "Non",
                value: "2",
                tempId: "9540d944-0dd8-4612-98ee-d70cc19de8bc",
              },
            ],
            tempId: "a66ee633-5d67-43b7-8e1d-5bb568d978a2",
          },
          {
            _id: "66300e365c6c8499e385236a",
            type: "UPLOAD",
            title: "Pièce jointe : ",
            mandatory: false,
            multiple: false,
            other: false,
            order: 25,
            newQuestion: false,
            options: [],
            tempId: "0ccc003f-cb13-4c53-8261-08f56a43d52e",
          },
          {
            _id: "66300e365c6c8499e385236b",
            type: "OPTION",
            title: "Percevez-vous des revenus de capitaux mobiliers (dividendes, coupons, etc.) ? ",
            mandatory: true,
            multiple: false,
            other: false,
            order: 26,
            newQuestion: false,
            options: [
              {
                _id: "66300e365c6c8499e385236c",
                label: "Oui",
                value: "1",
                tempId: "1af55336-b0d6-4de5-901e-1542771bcca7",
              },
              {
                _id: "66300e365c6c8499e385236d",
                label: "Non",
                value: "2",
                tempId: "8e118f70-eecb-44ad-b729-2388024c97f1",
              },
            ],
            tempId: "78dbb3ec-9697-4c84-bdca-d44db4ce6488",
          },
          {
            _id: "66300e365c6c8499e385236e",
            type: "UPLOAD",
            title: "Pièce jointe : ",
            mandatory: false,
            multiple: false,
            other: false,
            order: 27,
            newQuestion: false,
            options: [],
            tempId: "9b9dcdb1-2387-4b94-a197-4d4e867b317a",
          },
          {
            _id: "66300e365c6c8499e385236f",
            type: "OPTION",
            title: "Avez-vous souscrit au capital d’une société ou d’un fonds ?",
            mandatory: true,
            multiple: false,
            other: false,
            order: 28,
            newQuestion: false,
            options: [
              {
                _id: "66300e365c6c8499e3852370",
                label: "Oui",
                value: "1",
                tempId: "015f3edf-fa93-44bf-8fe0-e9e6f61c3b54",
              },
              {
                _id: "66300e365c6c8499e3852371",
                label: "Non",
                value: "2",
                tempId: "a267bdff-b6ce-4589-8a38-1ded88ddd3b5",
              },
            ],
            tempId: "d2974c4e-bd21-4ea6-a032-58ce01b69019",
          },
          {
            _id: "66300e365c6c8499e3852372",
            type: "UPLOAD",
            title: "Pièce jointe : ",
            mandatory: false,
            multiple: false,
            other: false,
            order: 29,
            newQuestion: false,
            options: [],
            tempId: "994f7262-a7d0-4a85-b09a-9a4f46a6d0fd",
          },
          {
            _id: "66300e365c6c8499e3852373",
            type: "OPTION",
            title:
              "Avez-vous cédé ou apporté des titres au cours de l’année ? (actions, parts sociales etc.)",
            mandatory: true,
            multiple: false,
            other: false,
            order: 30,
            newQuestion: false,
            options: [
              {
                _id: "66300e365c6c8499e3852374",
                label: "Oui",
                value: "1",
                tempId: "313bf588-9a11-41f0-b4b2-cd5e677f4320",
              },
              {
                _id: "66300e365c6c8499e3852375",
                label: "Non",
                value: "2",
                tempId: "8c77be8c-ac6a-4888-935c-fc430d23676b",
              },
            ],
            tempId: "6b23192b-6921-48a7-9f71-d97cc3ac6afa",
          },
          {
            _id: "66300e365c6c8499e3852376",
            type: "UPLOAD",
            title: "Si oui, justificatif : ",
            mandatory: false,
            multiple: false,
            other: false,
            order: 31,
            newQuestion: false,
            options: [],
            tempId: "57963965-6714-4dc5-aa92-7c911f9cbe8d",
          },
          {
            _id: "66300e365c6c8499e3852377",
            type: "OPTION",
            title:
              "Avez-vous exercé une levée d'option d'achat ? (stocks option, attribution gratuite d'actions, etc.)",
            mandatory: true,
            multiple: false,
            other: false,
            order: 32,
            newQuestion: false,
            options: [
              {
                _id: "66300e365c6c8499e3852378",
                label: "Oui",
                value: "1",
                tempId: "00990109-9596-4c5b-b970-a239c9283332",
              },
              {
                _id: "66300e365c6c8499e3852379",
                label: "Non",
                value: "2",
                tempId: "a89ae249-75ed-45f1-9157-e6987c2b8922",
              },
            ],
            tempId: "12adafeb-2af3-45f6-9a07-8dfd323363d4",
          },
          {
            _id: "66300e365c6c8499e385237a",
            type: "UPLOAD",
            title: "Si oui, merci de fournir le justificatif : ",
            mandatory: false,
            multiple: false,
            other: false,
            order: 33,
            newQuestion: false,
            options: [],
            tempId: "4311d68e-ae9f-4d07-aafc-ab0c4650d756",
          },
          {
            _id: "66300e365c6c8499e385237b",
            type: "OPTION",
            title:
              "Avez-vous engagé des dépenses pouvant ouvrir droit à un crédit d’impôt ou à une réduction d’impôt ?",
            mandatory: true,
            multiple: false,
            other: false,
            order: 34,
            newQuestion: false,
            options: [
              {
                _id: "66300e365c6c8499e385237c",
                label: "Oui",
                value: "1",
                tempId: "834c9394-46db-452d-be1f-88c451bbb6ac",
              },
              {
                _id: "66300e365c6c8499e385237d",
                label: "Non",
                value: "2",
                tempId: "bc1a649e-6dbd-4b08-9ede-efdece816b10",
              },
            ],
            note: "Dons, cotisations syndicales, emploi d’un salarié à domicile, installation d’une borne de recharge électrique, dépenses d’investissements locatifs type Censi bouvard…",
            tempId: "772c845c-9915-42b4-88fd-04e162e1870b",
          },
          {
            _id: "66300e365c6c8499e385237e",
            type: "UPLOAD",
            title: "Si oui, justificatif : ",
            mandatory: false,
            multiple: false,
            other: false,
            order: 35,
            newQuestion: false,
            options: [],
            tempId: "e45fa67a-c198-4e1d-89d9-df096ad4b7cb",
          },
          {
            _id: "66300e365c6c8499e385237f",
            type: "OPTION",
            title: "Versez-vous une pension alimentaire ?",
            mandatory: true,
            multiple: false,
            other: false,
            order: 36,
            newQuestion: false,
            options: [
              {
                _id: "66300e365c6c8499e3852380",
                label: "Oui",
                value: "1",
                tempId: "149285eb-56a0-4af6-b5d5-bf53d64f60d1",
              },
              {
                _id: "66300e365c6c8499e3852381",
                label: "Non",
                value: "2",
                tempId: "d3c97341-6bab-43b8-97a4-a666cf0ef325",
              },
            ],
            tempId: "741c0d63-813f-4096-88a7-e752d0e5e5a5",
          },
          {
            _id: "66300e365c6c8499e3852382",
            type: "TEXTAREA",
            title: "Si oui, à qui ? Quel montant ?",
            mandatory: false,
            multiple: false,
            other: false,
            order: 37,
            newQuestion: false,
            options: [],
            tempId: "a918e9d6-b5f8-455c-8d5b-8905f6ee74b8",
          },
          {
            _id: "66300e365c6c8499e3852383",
            type: "UPLOAD",
            title: "Si décision de justice, merci de fournir le justificatif : ",
            mandatory: false,
            multiple: false,
            other: false,
            order: 38,
            newQuestion: false,
            options: [],
            tempId: "71d15b42-c520-43cf-8d9d-8ec9743d89d9",
          },
          {
            _id: "66300e365c6c8499e3852384",
            type: "OPTION",
            title:
              "Détenez-vous des comptes bancaires, comptes d'actifs numériques et/ou Assurance Vie à l’étranger ? ",
            mandatory: true,
            multiple: false,
            other: false,
            order: 39,
            newQuestion: false,
            options: [
              {
                _id: "66300e365c6c8499e3852385",
                label: "Oui",
                value: "1",
                tempId: "880fbd9d-e31f-4eed-ace4-2859543c790d",
              },
              {
                _id: "66300e365c6c8499e3852386",
                label: "Non",
                value: "2",
                tempId: "64ab446a-c6f6-4062-8daa-a11bf1e030ea",
              },
            ],
            note: "Sont également concernés les comptes N26, Revolut, etc. Attention : il convient également de déclarer les comptes qui sont clos dans l'année.",
            tempId: "a25bda78-af51-471a-a2ef-975d9465224c",
          },
          {
            _id: "66300e365c6c8499e3852387",
            type: "OPTION",
            title: "Possédez vous des biens immobiliers ?",
            mandatory: true,
            multiple: false,
            other: false,
            order: 40,
            newQuestion: false,
            options: [
              {
                _id: "66300e365c6c8499e3852388",
                label: "Oui",
                value: "1",
                tempId: "ca3fab01-3a1a-412a-9a95-f2e9951f2a5b",
              },
              {
                _id: "66300e365c6c8499e3852389",
                label: "Non",
                value: "2",
                tempId: "9a344c3e-038c-4082-8361-fcbdb434bc11",
              },
            ],
            tempId: "72276161-6eec-49d7-92bb-686959f83839",
          },
        ],
        tempId: "8f055096-4d40-428c-a1e2-22b38d2692bb",
      },
      {
        _id: "66300e365c6c8499e385238a",
        title: "IMMOBILIER",
        order: 4,
        questions: [
          {
            _id: "66300e365c6c8499e385238b",
            type: "OPTION",
            title: "Avez-vous réalisé une plus-value immobilière ?",
            mandatory: true,
            multiple: false,
            other: false,
            order: 41,
            newQuestion: false,
            options: [
              {
                _id: "66300e365c6c8499e385238c",
                label: "Oui",
                value: "1",
                tempId: "70615661-a3a0-4f2e-91f1-411058a5d4fa",
              },
              {
                _id: "66300e365c6c8499e385238d",
                label: "Non",
                value: "2",
                tempId: "28ed0acc-786b-463f-aa76-cb0f28676810",
              },
            ],
            tempId: "ebf642e4-2af0-4f1d-87d3-be51476417d3",
          },
          {
            _id: "66300e365c6c8499e385238e",
            type: "UPLOAD",
            title: "Justificatif :",
            mandatory: false,
            multiple: false,
            other: false,
            order: 42,
            newQuestion: false,
            options: [],
            note: "Acte de vente et la déclaration de plus value établie par le notaire.",
            tempId: "35bbe1f8-795d-4faf-95fc-217e912feda1",
          },
          {
            _id: "66300e365c6c8499e385238f",
            type: "OPTION",
            title: "Avez-vous un/des bien(s) immobilier(s) loué(s) en meublé ?",
            mandatory: true,
            multiple: false,
            other: false,
            order: 43,
            newQuestion: false,
            options: [
              {
                _id: "66300e365c6c8499e3852390",
                label: "Oui",
                value: "1",
                tempId: "bc4a6f67-b6b5-4f68-b9b5-f98e6cae5380",
              },
              {
                _id: "66300e365c6c8499e3852391",
                label: "Non",
                value: "2",
                tempId: "ea220d3c-dbc5-40df-a9e3-5499b8fba450",
              },
            ],
            tempId: "9b44b672-a5ee-400d-87a3-f3d81071793d",
          },
          {
            _id: "66300e365c6c8499e3852392",
            type: "UPLOAD",
            title: "Justificatifs",
            mandatory: false,
            multiple: false,
            other: false,
            order: 44,
            newQuestion: false,
            options: [],
            note: "Joindre les quittances de loyer (comprenant les charges refacturées) si déclaration micro.\nSi déclaration au réel, joindre la liasse fiscale.",
            tempId: "989c9016-2b1d-4eda-bf3e-366bcfae6ae5",
          },
          {
            _id: "66300e365c6c8499e3852393",
            type: "OPTION",
            title: "Avez-vous un/des bien(s) immobilier(s) loué(s) en location nue (non meublée) ?",
            mandatory: true,
            multiple: false,
            other: false,
            order: 45,
            newQuestion: false,
            options: [
              {
                _id: "66300e365c6c8499e3852394",
                label: "Oui",
                value: "1",
                tempId: "d24bf27d-9cd1-497d-8e87-571b68fb494b",
              },
              {
                _id: "66300e365c6c8499e3852395",
                label: "Non",
                value: "2",
                tempId: "2fd2f46b-0f5e-47cf-9e20-6fa140c68fe0",
              },
            ],
            tempId: "5a54073e-d7ef-47d9-b202-0988c9e3db4b",
          },
          {
            _id: "66300e365c6c8499e3852396",
            type: "OPTION",
            title: "Votre patrimoine immobilier est-il supérieur à 1 300 000 € ?",
            mandatory: true,
            multiple: false,
            other: false,
            order: 46,
            newQuestion: false,
            options: [
              {
                _id: "66300e365c6c8499e3852397",
                label: "Oui",
                value: "1",
                tempId: "6368f577-bd5d-4f73-852d-fea20722a0e6",
              },
              {
                _id: "66300e365c6c8499e3852398",
                label: "Non",
                value: "2",
                tempId: "31601d75-49a1-470f-a1eb-fbf006f20696",
              },
            ],
            tempId: "aa3ff4fb-2706-44c8-bff0-0db6556f7e8b",
          },
          {
            _id: "66300e365c6c8499e3852399",
            type: "TEXTAREA",
            title: "Si oui, précisez : ",
            mandatory: false,
            multiple: false,
            other: false,
            order: 47,
            newQuestion: false,
            options: [],
            note: "Location saisonnière ? de longue durée ? Les deux ? \nPlusieurs biens ? etc.\nSi location déjà déclarée l'année dernière, indiquez s'il y a du changement.",
            tempId: "2bc7b9fa-80a6-402f-81f0-d345f5f8f48d",
          },
        ],
        tempId: "620e9a04-e596-46dd-81cb-52ea36471aff",
      },
      {
        _id: "66300e365c6c8499e385239a",
        title: "PREPARATION DECLARATION N° 2044 - LOCATION NUE",
        order: 6,
        questions: [
          {
            _id: "66300e365c6c8499e385239b",
            type: "OPTION",
            title:
              ". Avez-vous acquis ou vendu un bien locatif (ou des parts de SCI ou SCPI) durant l’année ? ",
            mandatory: true,
            multiple: false,
            other: false,
            order: 48,
            newQuestion: false,
            options: [
              {
                _id: "66300e365c6c8499e385239c",
                label: "Oui",
                value: "1",
                tempId: "dfb4e3ba-6657-4f3f-aa09-d43cdea7b16e",
              },
              {
                _id: "66300e365c6c8499e385239d",
                label: "Non",
                value: "2",
                tempId: "20387b93-43de-4427-a17a-d911b7afd03b",
              },
            ],
            tempId: "b05d1649-84a7-4add-b7da-a6408c2a6687",
          },
          {
            _id: "66300e365c6c8499e385239e",
            type: "OPTION",
            title: "Avez-vous changé de locataire dans un de vos biens locatifs ? ",
            mandatory: true,
            multiple: false,
            other: false,
            order: 49,
            newQuestion: false,
            options: [
              {
                _id: "66300e365c6c8499e385239f",
                label: "Oui",
                value: "1",
                tempId: "b9f9e478-80b9-4dbc-b88d-fcb4f0f04ba4",
              },
              {
                _id: "66300e365c6c8499e38523a0",
                label: "Non",
                value: "2",
                tempId: "29981047-7ba9-44b9-808b-b28aaa668082",
              },
            ],
            tempId: "969d2562-447d-445b-85c0-d9ca429abbbd",
          },
          {
            _id: "66300e365c6c8499e38523a1",
            type: "TEXTAREA",
            title:
              "Merci d'indiquer le bien concerné, la date de changement de locataire et les informations du nouveau locataire",
            mandatory: false,
            multiple: false,
            other: false,
            order: 50,
            newQuestion: false,
            options: [],
            tempId: "758199fa-5524-4aa4-99c3-fe91d0aff43c",
          },
          {
            _id: "66300e365c6c8499e38523a2",
            type: "OPTION",
            title: "Avez-vous effectué des travaux dans l’un de vos biens locatifs ? ",
            mandatory: true,
            multiple: false,
            other: false,
            order: 51,
            newQuestion: false,
            options: [
              {
                _id: "66300e365c6c8499e38523a3",
                label: "Oui",
                value: "1",
                tempId: "64929420-6d68-41b8-83a7-795f290d96d3",
              },
              {
                _id: "66300e365c6c8499e38523a4",
                label: "Non",
                value: "2",
                tempId: "99d8b6d0-4582-4e54-8ce0-def2524b730d",
              },
            ],
            tempId: "faa637b5-f2fe-4b6d-b666-dc22b3cd6124",
          },
          {
            _id: "66300e365c6c8499e38523a5",
            type: "OPTION",
            title:
              "Avez-vous effectué des travaux de rénovation énergétiques permettant à un bien de passer d’une classe énergétique E, F ou G à une classe A, B, C ou D ? ",
            mandatory: true,
            multiple: false,
            other: false,
            order: 52,
            newQuestion: false,
            options: [
              {
                _id: "66300e365c6c8499e38523a6",
                label: "Oui",
                value: "1",
                tempId: "19945b4d-c6e3-44a3-90a9-161e1865b8d1",
              },
              {
                _id: "66300e365c6c8499e38523a7",
                label: "Non",
                value: "2",
                tempId: "3d08468b-8c5b-4524-8250-e3f873f1dbb4",
              },
            ],
            tempId: "0fd2f31e-f8f8-412c-a32a-9093f2c506f8",
          },
          {
            _id: "66300e365c6c8499e38523a8",
            type: "UPLOAD",
            title: "Justificatifs ",
            mandatory: false,
            multiple: false,
            other: false,
            order: 53,
            newQuestion: false,
            options: [],
            note: 'Joindre l’ensemble des justificatifs correspondant (avis de taxes foncières, les charges, travaux, montant des intérêts et assurance emprunt, etc.) ou la liasse fiscale si les biens sont détenus via une SCI ou SCPI.\nSi dispositif de défiscalisation, joindre l’engagement de location, le calcul des éléments amortissables, etc.\nSi vous avez répondu "oui" à une des questions précédentes, joindre les justificatifs le cas échéant.',
            tempId: "2050b817-8e3c-4fb2-8281-a70b27cf1883",
          },
        ],
        tempId: "9238f288-c0c8-4f11-b90e-3102771dceb1",
      },
      {
        _id: "66300e365c6c8499e38523a9",
        title: "IMPOT SUR LA FORTUNE IMMOBILIERE",
        order: 5,
        questions: [
          {
            _id: "66300e365c6c8499e38523aa",
            type: "TEXTAREA",
            title:
              "Indiquez ici les valorisations de vos actifs immobiliers (en direct ou via une société) au 1er janvier 2024.",
            mandatory: true,
            multiple: false,
            other: false,
            order: 54,
            newQuestion: false,
            options: [],
            tempId: "aa0f9dad-268d-4ab3-a3df-4d0d7a86ac25",
          },
          {
            _id: "66300e365c6c8499e38523ab",
            type: "TEXTAREA",
            title:
              "Indiquez ici si vous avez eu des changement dans la composition de votre patrimoine immobilier.",
            mandatory: true,
            multiple: false,
            other: false,
            order: 55,
            newQuestion: false,
            options: [],
            note: "Acquisition, vente, création d'une SCI, achat de parts de SCI/SCPI.",
            tempId: "fda32949-73a4-4269-89fd-47a0d184a205",
          },
          {
            _id: "66300e365c6c8499e38523ac",
            type: "UPLOAD",
            title: "Justificatifs :",
            mandatory: false,
            multiple: false,
            other: false,
            order: 56,
            newQuestion: false,
            options: [],
            tempId: "53d570a3-9f1e-4636-9a02-fb231442cbbe",
          },
          {
            _id: "66300e365c6c8499e38523ad",
            type: "OPTION",
            title:
              "Avez-vous souscrit ou renégocié un nouvel emprunt concernant vos actifs immobiliers ?",
            mandatory: true,
            multiple: false,
            other: false,
            order: 57,
            newQuestion: false,
            options: [
              {
                _id: "66300e365c6c8499e38523ae",
                label: "Oui",
                value: "1",
                tempId: "854da2a0-a181-4020-ab9d-6838f7c31299",
              },
              {
                _id: "66300e365c6c8499e38523af",
                label: "Non",
                value: "2",
                tempId: "30f32f2e-aecf-4c0a-a833-d2228ae8f6f4",
              },
            ],
            tempId: "f30f5cf9-33f4-4b8f-8e19-1c0696eecf6a",
          },
          {
            _id: "66300e365c6c8499e38523b0",
            type: "UPLOAD",
            title: "Justificatifs :",
            mandatory: false,
            multiple: false,
            other: false,
            order: 58,
            newQuestion: false,
            options: [],
            tempId: "24bb3b4c-69d9-4b00-bbdf-d740ff6823d8",
          },
          {
            _id: "66300e365c6c8499e38523b1",
            type: "UPLOAD",
            title: "Merci de nous fournir tout autre document utile pour la déclaration d'IFI",
            mandatory: false,
            multiple: false,
            other: false,
            order: 59,
            newQuestion: false,
            options: [],
            note: "Taxes foncières si non présentes sur votre espace impots.gouv                          \nLes bilans des sociétés détenant de l'immobilier si non gérées par [nom du cabinet]\nLes justificatifs bancaires (en cas d'assurance vie ou SCPI) \n",
            tempId: "475428bb-4e78-4c57-872f-20ed9a57bc5c",
          },
        ],
        tempId: "5bd9ee18-402a-4860-a5e6-1be31dbf3b62",
      },
      {
        _id: "66300e365c6c8499e38523b2",
        title: "COMPTES A L'ETRANGER ",
        order: 3,
        questions: [
          {
            _id: "66300e365c6c8499e38523b3",
            type: "TEXTAREA",
            title:
              "Merci de nous indiquer les éléments suivants : identité du détenteur, intitulé du compte, adresse de l’établissement, numéro et caractéristiques du compte, dates d’ouverture du compte.",
            mandatory: true,
            multiple: false,
            other: false,
            order: 60,
            newQuestion: false,
            options: [],
            tempId: "55a7fca4-c055-46dc-8017-606ee5ad1c6e",
          },
          {
            _id: "66300e365c6c8499e38523b4",
            type: "UPLOAD",
            title: "Ou un justificatif : ",
            mandatory: false,
            multiple: false,
            other: false,
            order: 61,
            newQuestion: false,
            options: [],
            tempId: "c84778c2-6437-4948-a541-e9365e2227da",
          },
        ],
        tempId: "0f60e9f8-0942-470e-b456-59618e13c13a",
      },
      {
        _id: "66300e365c6c8499e38523b5",
        title: "AUTRES INFORMATIONS UTILES",
        order: 7,
        questions: [
          {
            _id: "66300e365c6c8499e38523b6",
            type: "TEXTAREA",
            title:
              "Merci de nous indiquer ici toutes autres informations qui vous paraîtraient utiles de nous faire connaître.",
            mandatory: false,
            multiple: false,
            other: false,
            order: 62,
            newQuestion: false,
            options: [],
            tempId: "7cb6d3b7-d563-47a8-bd7e-8d5263d8fc27",
          },
        ],
        tempId: "4f834e2b-82a4-4886-81d3-6bc975fa8e18",
      },
      {
        _id: "66300e365c6c8499e38523b7",
        title: "DECLARATION DES PROPRIETAIRES DE BIENS IMMOBILIERS",
        order: 8,
        questions: [
          {
            _id: "66300e365c6c8499e38523b8",
            type: "OPTION",
            title:
              "Un ou plusieurs occupants de vos biens ont-ils changé entre le 01/01/2023 et le 01/01/2024 (inclus) ? ",
            mandatory: true,
            multiple: false,
            other: false,
            order: 63,
            newQuestion: false,
            options: [
              {
                _id: "66300e365c6c8499e38523b9",
                label: "Oui",
                value: "1",
                tempId: "a9a313d1-c6ad-4c77-8352-102db48096c5",
              },
              {
                _id: "66300e365c6c8499e38523ba",
                label: "Non",
                value: "2",
                tempId: "4314877f-c266-4e4f-8db1-b1da62192213",
              },
            ],
            tempId: "db2c078c-1624-49e4-b6a6-f6c4ac637357",
          },
          {
            _id: "66300e365c6c8499e38523bb",
            type: "OPTION",
            title:
              "Si oui, avez-vous déjà signalé ce changement dans le service « Mes biens immobiliers » ? ",
            mandatory: true,
            multiple: false,
            other: false,
            order: 64,
            newQuestion: false,
            options: [
              {
                _id: "66300e365c6c8499e38523bc",
                label: "Oui",
                value: "1",
                tempId: "2b15837c-8401-47fa-a787-ccfa6bd35f15",
              },
              {
                _id: "66300e365c6c8499e38523bd",
                label: "Non",
                value: "2",
                tempId: "7b03a55d-ff74-4c4f-93b1-e3421b519f23",
              },
              {
                _id: "66300e365c6c8499e38523be",
                label: "Non concerné",
                value: "3",
                tempId: "224f4a54-ab28-4234-8b94-a04a4f08a9d9",
              },
            ],
            tempId: "86cf0cf4-5277-4de9-aa4a-caf653580320",
          },
          {
            _id: "66300e365c6c8499e38523bf",
            type: "OPTION",
            title: "Souhaitez-vous que le cabinet s'en charge ?",
            mandatory: false,
            multiple: false,
            other: false,
            order: 65,
            newQuestion: false,
            options: [
              {
                _id: "66300e365c6c8499e38523c0",
                label: "Oui",
                value: "1",
                tempId: "cf64a244-d256-45da-9638-71b3374d4a00",
              },
              {
                _id: "66300e365c6c8499e38523c1",
                label: "Non",
                value: "2",
                tempId: "e241979f-cc7a-4f51-a249-c903764e747b",
              },
            ],
            tempId: "bc860e22-b033-4153-8965-4fbf7717f184",
          },
          {
            _id: "66300e365c6c8499e38523c2",
            type: "TEXTAREA",
            title:
              "Si oui, merci d'indiquer les informations nécessaires pour effectuer la déclaration (date de changement, nom prénom, date et lieu de naissance du nouvel occupant etc.).",
            mandatory: false,
            multiple: false,
            other: false,
            order: 66,
            newQuestion: false,
            options: [],
            tempId: "af887eef-b224-4c98-9476-3a940a031ea3",
          },
        ],
        tempId: "3755f574-133f-433f-83e4-43f77999a122",
      },
      {
        _id: "66300e365c6c8499e38523c3",
        title: "ATTESTATIONS",
        order: 7,
        questions: [
          {
            _id: "66300e365c6c8499e38523c4",
            type: "OPTION",
            title: "En remplissant ce questionnaire : ",
            mandatory: false,
            multiple: false,
            other: false,
            order: 67,
            newQuestion: false,
            options: [
              {
                _id: "66300e365c6c8499e38523c5",
                label:
                  "J'atteste avoir pris connaissance des nouveaux tarifs et je confie la prestation à [nom du cabinet].",
                value: "1",
                tempId: "c6c4cc7a-53f4-4fac-a4d6-57f92ae96359",
              },
            ],
            tempId: "18ffeb24-f512-4f2b-86d4-79077da8b05b",
          },
          {
            _id: "66300e365c6c8499e38523c6",
            type: "OPTION",
            title: "En validant ce questionnaire : ",
            mandatory: true,
            multiple: false,
            other: false,
            order: 68,
            newQuestion: false,
            options: [
              {
                _id: "66300e365c6c8499e38523c7",
                label:
                  "J'atteste de l'exactitude et l'exhaustivité des informations transmises dans ledit questionnaire.",
                value: "1",
                tempId: "a0e74f42-53e1-454a-9940-4e058742cc04",
              },
            ],
            tempId: "7f824117-8783-4d4e-8baf-135c4bf5a305",
          },
        ],
        tempId: "c062f212-e537-46b9-9cdc-ebfdf6068783",
      },
    ],
    rules: [
      {
        _id: "66300e375c6c8499e38523ed",
        sectionId: "66300e365c6c8499e385239a",
        conditions: [
          {
            questionId: "66300e365c6c8499e3852393",
            value: "66300e365c6c8499e3852394",
            questionTempId: "5a54073e-d7ef-47d9-b202-0988c9e3db4b",
            tempId: "d24bf27d-9cd1-497d-8e87-571b68fb494b",
          },
        ],
        tempId: "9238f288-c0c8-4f11-b90e-3102771dceb1",
      },
      {
        _id: "66300e375c6c8499e38523ef",
        sectionId: "66300e365c6c8499e38523a9",
        conditions: [
          {
            questionId: "66300e365c6c8499e3852396",
            value: "66300e365c6c8499e3852397",
            questionTempId: "aa3ff4fb-2706-44c8-bff0-0db6556f7e8b",
            tempId: "6368f577-bd5d-4f73-852d-fea20722a0e6",
          },
        ],
        tempId: "5bd9ee18-402a-4860-a5e6-1be31dbf3b62",
      },
      {
        _id: "66300e375c6c8499e38523eb",
        sectionId: "66300e365c6c8499e38523b2",
        conditions: [
          {
            questionId: "66300e365c6c8499e3852384",
            value: "66300e365c6c8499e3852385",
            questionTempId: "a25bda78-af51-471a-a2ef-975d9465224c",
            tempId: "880fbd9d-e31f-4eed-ace4-2859543c790d",
          },
        ],
        tempId: "0f60e9f8-0942-470e-b456-59618e13c13a",
      },
      {
        _id: "66300e375c6c8499e38523e7",
        sectionId: "66300e365c6c8499e385238a",
        conditions: [
          {
            questionId: "66300e365c6c8499e3852387",
            value: "66300e365c6c8499e3852388",
            questionTempId: "72276161-6eec-49d7-92bb-686959f83839",
            tempId: "ca3fab01-3a1a-412a-9a95-f2e9951f2a5b",
          },
        ],
        tempId: "620e9a04-e596-46dd-81cb-52ea36471aff",
      },
      {
        _id: "66300e375c6c8499e38523e9",
        sectionId: "66300e365c6c8499e38523b7",
        conditions: [
          {
            questionId: "66300e365c6c8499e3852387",
            value: "66300e365c6c8499e3852388",
            questionTempId: "72276161-6eec-49d7-92bb-686959f83839",
            tempId: "ca3fab01-3a1a-412a-9a95-f2e9951f2a5b",
          },
        ],
        tempId: "3755f574-133f-433f-83e4-43f77999a122",
      },
    ],
    draft: true,
    createdAt: new Date(),
  },
};

export default templates;
