import {
  AnswerOutput,
  FormAnswersOutput,
  FormOutput,
  QuestionOutput,
  SectionOutput,
} from "@addventa/sesha-forms-api";
import { ConfigProvider, Input, theme } from "antd";
import { useEffect, useState } from "react";
import { apiAnswer, apiFormAnswers } from "../../../../api-configuration/Configuration";
import { completionTracking, navigationTracking } from "../utils";

const { TextArea } = Input;

function DisplayTextareaField(props: {
  formAnswers: FormAnswersOutput;
  form: FormOutput;
  section: SectionOutput;
  question: QuestionOutput;
  answer: AnswerOutput;
  mandatoryWarning: boolean;
  isInReviewMode?: boolean;
}) {
  const {
    token: { colorError, colorBorder },
  } = theme.useToken();

  const [content, setContent] = useState<string>("");

  useEffect(() => {
    setContent(props.answer.value[0] ? props.answer.value[0] : "");
  }, [props.answer]);

  async function savingContent(event: React.FocusEvent<HTMLTextAreaElement>): Promise<void> {
    return new Promise(async (_, reject) => {
      props.answer.value = event.target.value === "" ? [] : [event.target.value];
      await apiAnswer.answersUpdateOne(props.formAnswers._id, props.answer).catch((error) => {
        reject(error);
      });

      navigationTracking(props.formAnswers, props.form, props.section, props.question);
      completionTracking(props.formAnswers, props.form, props.section, props.question);
      // save progression and completion
      let updatedFormAnswers = { ...props.formAnswers, lastModifiedDate: new Date() };
      await apiFormAnswers.formAnswersUpdateOne(props.formAnswers._id, updatedFormAnswers);
    });
  }

  return (
    <ConfigProvider
      theme={{
        components: {
          Input: {
            colorBorder:
              props.mandatoryWarning && props.question.mandatory && props.answer.value.length === 0
                ? colorError
                : colorBorder,
          },
        },
      }}
    >
      <TextArea
        value={content}
        defaultValue={content}
        disabled={props.isInReviewMode}
        autoSize
        onBlur={savingContent}
        onChange={(event) => setContent(event.target.value)}
      />
    </ConfigProvider>
  );
}

export default DisplayTextareaField;
