import { FormAnswersOutput, FormOutput, QuestionOutput, SectionOutput } from "@addventa/sesha-forms-api";
import { filterActiveSections, coupleAllQuestionsAnswers } from "./rulesActivation";


/*
// filter out inactive answers, quite inneficient and simply following active sections/questions should be enough
export function filterActiveAnswers(sections: SectionOutput[], questionsAnswers: QuestionAndAnswer[]) : AnswerOutput[] {
    // would be more efficient if form (sections, rules, questions) was more organized before once for all
    return questionsAnswers.reduce<AnswerOutput[]>( (answers, qa) => {
        if( sections.some( s => s.questions!.some(q => q._id === qa.question._id) ) )
          answers.push(qa.answer)
        return answers;
      }, [])
}
*/

export function findQuestionSection(form: FormOutput, questionId: string) : [SectionOutput, QuestionOutput] {
    let s = form.sections!.find(s => s.questions!.find( q => q._id === questionId))!;
    let q =  s.questions!.find( q => q._id === questionId)!;

    return [s, q];
}

// comparing order of section/question, positive means greater than, negative means lower than
// TO Do: remove section order in evaluation, question order is now global and enough to sort them
export function compareQuestions([s1, q1]: [SectionOutput, QuestionOutput], [s2, q2]: [SectionOutput, QuestionOutput]) {
    if(s1.order === s2.order)  
        return q1.order-q2.order

    return s1.order-s2.order;
}

export function navigationTracking(formAnswers: FormAnswersOutput, form: FormOutput, section: SectionOutput, question: QuestionOutput) : void {
    // keep track of last answered question 
    formAnswers.lastAnsweredQuestionId = question._id;
}

export function completionTracking(formAnswers: FormAnswersOutput, form: FormOutput, section: SectionOutput, question: QuestionOutput) : void {

    // trace modification date
    formAnswers.lastModifiedDate = new Date();

    if( formAnswers.mostAdvancedQuestionId === undefined ) {
        formAnswers.mostAdvancedQuestionId = question._id;
    }        
    else {
        // and keep track of most advanced question, current question is after most advanced

        // get previous last section and question
        let [lastSection, lastQuestion]: [SectionOutput, QuestionOutput] = findQuestionSection(form, formAnswers.mostAdvancedQuestionId);
        
        // from the active sections
        let activeSections = filterActiveSections(form.sections!, form.rules!, coupleAllQuestionsAnswers(form.sections!, formAnswers.answers));
        // get last active Section, possibly the same as last section if it's active
        let lastActiveSection = activeSections.findLast(s => s.order <= lastSection.order)!;
        // and get last active question, rolling back to the end of a section if last section was not active
        let lastActiveQuestion = lastActiveSection._id === lastSection._id ? lastQuestion : lastActiveSection.questions![lastActiveSection.questions!.length-1];
        
        // starting tracing last active question if it changed
        if( lastActiveQuestion !== lastQuestion ) {
            formAnswers.mostAdvancedQuestionId = lastActiveQuestion._id;
        }

        // and trace current question as last question if it's more advanced
        if( compareQuestions([section, question], [lastActiveSection, lastActiveQuestion]) > 0 ) {
            formAnswers.mostAdvancedQuestionId = question._id;
        }  
    }
}