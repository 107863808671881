import { AccountingFirmOutput, FormOutput, UserOutput } from "@addventa/sesha-forms-api";
import dayjs from "dayjs";
import "dayjs/locale/fr";
const customParseFormat = require("dayjs/plugin/customParseFormat");

function generateText(
  text: string,
  user: UserOutput,
  accountingFirm: AccountingFirmOutput,
  form: FormOutput
) {
  dayjs.locale("fr");
  dayjs.extend(customParseFormat);

  let replacedText = "";

  const signature = user.signature
    ? user.signature
    : `<p>Cordialement</p><br />
      <p>${user.firstName} ${user.lastName}</p>
      <span style="color:#4569f8; font-size: 16px">${accountingFirm.name}</span>
  `;

  const closeDate = form.closeDate ? dayjs(form.closeDate) : undefined;

  replacedText = text
    .replaceAll("&lt;nom_form&gt;", form.title)
    .replaceAll("&lt;nom_cabinet&gt;", accountingFirm.name)
    .replaceAll("&lt;nom_collab&gt;", user.lastName)
    .replaceAll("&lt;signature_collab&gt;", signature);

  if (closeDate)
    replacedText = replacedText.replaceAll(
      "&lt;date_fin&gt;",
      closeDate.format("DD/MM/YYYY") + " à " + closeDate.format("HH") + "h" + closeDate.format("mm")
    );

  return replacedText;
}

function generateSubject(text: string, accountingFirm: AccountingFirmOutput, form: FormOutput) {
  let subjectTemplate = "";

  subjectTemplate = text
    .replaceAll("<nom_cabinet>", accountingFirm.name)
    .replaceAll("<nom_form>", form.title);

  return subjectTemplate;
}

export { generateSubject, generateText };
