import { AccountingFirmOutput, ContactOutput, TagOutput } from "@addventa/sesha-forms-api";
import { DeleteOutlined, ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Alert, Button, ConfigProvider, Modal, theme } from "antd";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { apiAccountingFirm, apiContact, apiTag } from "../../api-configuration/Configuration";
import TagItem from "./TagItem";

function Tags(props: {
  openTagsModal: boolean;
  setOpenTagsModal: React.Dispatch<React.SetStateAction<boolean>>;
  setReloadList: React.Dispatch<React.SetStateAction<boolean>>;
  contacts: ContactOutput[];
  reloadList: boolean;
}) {
  const {
    token: { colorPrimaryBgHover },
  } = theme.useToken();

  const [openTagsModal, setOpenTagsModal] = useState<boolean>(false);
  const [openDeleteTags, setOpenDeleteTags] = useState<boolean>(false);
  const [tags, setTags] = useState<TagOutput[]>([]);
  const [currentTag, setCurrentTag] = useState<string>("");
  const [accountingFirm, setAccountingFirm] = useState<AccountingFirmOutput>();
  const [isNew, setIsNew] = useState<string>("");

  useEffect(() => {
    (async () => {
      const resAcc = await apiAccountingFirm.accountingfirmFindOne();
      setAccountingFirm(resAcc);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const res = await apiTag.tagFindAll();
      setTags(res);
    })();
  }, [props.reloadList]);

  useEffect(() => {
    setOpenTagsModal(props.openTagsModal);
  }, [props.openTagsModal]);

  const addNewTag = () => {
    if (!accountingFirm) {
      console.error("addNewTag - accounting firm is undefined");
      return;
    }
    const tempId = uuidv4();
    const newTag = {
      label: "",
      color: "",
      _id: tempId,
      accountingFirmId: accountingFirm._id,
      contacts: [],
      createdAt: new Date(),
      isNew: true,
    };
    setIsNew(tempId);
    setCurrentTag(tempId);
    setTags([...tags, newTag]);
  };

  const deleteTag = (id: string) => {
    const currentTags = [...tags];
    setTags(currentTags.filter((tag) => tag._id !== id));
    (async () => {
      await apiTag.tagDeleteOne(id);
      const contactsWithTag = props.contacts.filter((contact: ContactOutput) =>
        contact.tags.includes(id)
      );
      for (const contact of contactsWithTag) {
        const filteredTags = contact.tags.filter((tag) => tag !== id);
        await apiContact.contactUpdateOne(contact._id, { tags: filteredTags });
      }
    })();
    props.setReloadList(true);
  };

  return (
    <div>
      <ConfigProvider
        theme={{
          components: {
            Modal: {
              headerBg: colorPrimaryBgHover,
            },
          },
        }}
      >
        <Modal
          width={currentTag !== "" ? 700 : 610}
          title={"Gestion des tags"}
          open={openTagsModal}
          footer={null}
          onCancel={() => {
            setOpenTagsModal(false);
            props.setOpenTagsModal(false);
          }}
        >
          {tags?.map((tag) => {
            if (tag._id === currentTag) {
              return (
                <div style={{ marginBottom: "10px", marginTop: "10px" }} key={tag._id}>
                  <TagItem
                    tag={tag}
                    mode="edit"
                    setCurrentTag={setCurrentTag}
                    isNew={isNew === tag._id}
                    setTags={setTags}
                    setReloadList={props.setReloadList}
                    reloadList={props.reloadList}
                  />
                </div>
              );
            } else {
              const countContactWithTag = props.contacts?.filter((elt) =>
                elt.tags.includes(tag._id)
              ).length!;
              return (
                <div style={{ marginBottom: "10px", marginTop: "10px" }} key={tag._id}>
                  <div onClick={() => setCurrentTag(tag._id)} style={{ display: "inline-block" }}>
                    <TagItem
                      tag={tag}
                      mode="read"
                      setCurrentTag={setCurrentTag}
                      isNew={false}
                      contacts={props.contacts}
                      reloadList={props.reloadList}
                    />
                  </div>
                  <Button
                    shape="circle"
                    icon={<DeleteOutlined />}
                    style={{ float: "right", border: "0px" }}
                    size="small"
                    onClick={() => {
                      if (countContactWithTag > 0) {
                        setOpenDeleteTags(true);
                      } else {
                        deleteTag(tag._id);
                      }
                    }}
                  />
                  <ConfigProvider
                    theme={{
                      components: {
                        Button: {
                          colorPrimary: "#FF9700",
                          colorPrimaryHover: "#FF9700",
                        },
                        Modal: {
                          headerBg: "white",
                        },
                      },
                    }}
                  >
                    <Modal
                      open={openDeleteTags}
                      title={
                        <>
                          <ExclamationCircleOutlined
                            style={{ fontSize: "24px", marginRight: "10px", color: "#FF9700" }}
                          />
                          Êtes-vous sûr(e) du vouloir supprimer ce tag ?
                        </>
                      }
                      onOk={() => {
                        deleteTag(tag._id);
                        setOpenDeleteTags(false);
                      }}
                      onCancel={() => setOpenDeleteTags(false)}
                      maskClosable={false}
                      okText="Valider"
                      cancelText="Annuler"
                    >
                      <Alert
                        message=""
                        description={
                          <>
                            Ce tag est attribué à {countContactWithTag}
                            {countContactWithTag === 1 ? " contact" : " contacts"}. En supprimant ce
                            tag, il sera supprimé de tous les contacts concernés.
                          </>
                        }
                        type="warning"
                      />
                    </Modal>
                  </ConfigProvider>
                </div>
              );
            }
          })}
          {currentTag === "" ? (
            <Button
              shape="circle"
              icon={<PlusOutlined />}
              style={{ marginTop: "20px", marginBottom: "20px" }}
              onClick={addNewTag}
            />
          ) : null}
        </Modal>
      </ConfigProvider>
    </div>
  );
}

export default Tags;
