import { useEffect, useState } from "react";

import { ContactOutput, FormAnswersOutput, FormOutput } from "@addventa/sesha-forms-api";
import { Spin } from "antd";
import { useParams } from "react-router-dom";
import { apiContact, apiForm, apiFormAnswers } from "../../api-configuration/Configuration";
import ClosedFormAnswers from "./ClosedFormAnswers";
import DisconnectedFormAnswers from "./DisconnectedFormAnswers";
import DisplayFormAnswers from "./DisplayFormAnswers";
import "./formAnswers.css";

export type Params = {
  id: string;
};

function LaunchFormAnswers(props: {
  id?: string;
  disconnected: boolean;
  setDisplayBtnDisco: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  let { id } = useParams<keyof Params>();

  if (id === undefined && props.id !== undefined) {
    id = props.id;
  }

  const [loadedForm, setLoadedForm] = useState<FormOutput>();
  const [loadedContact, setLoadedContact] = useState<ContactOutput>();
  const [loadedFormAnswers, setLoadedFormAnswers] = useState<FormAnswersOutput>();

  useEffect(() => {
    (async () => {
      if (id !== undefined) {
        const formAnswers: FormAnswersOutput = await apiFormAnswers.formAnswersFindOne(id);

        formAnswers.lastAccessDate = new Date();
        await apiFormAnswers.formAnswersUpdateOne(formAnswers._id, formAnswers);

        setLoadedFormAnswers(formAnswers);
        props.setDisplayBtnDisco(!formAnswers.closed);

        const form: FormOutput = await apiForm.formFindOne(formAnswers.formId);

        // sort everything, for simpler usage after
        form.sections!.sort((s1, s2) => s1.order - s2.order);
        form.sections!.forEach((s) => {
          s.questions!.sort((q1, q2) => q1.order - q2.order);
        });

        setLoadedForm(form);

        // let form: SeshaForm = await (await getForm(formId)).json();

        const contact = await apiContact.contactFindOne(formAnswers.contactId);
        setLoadedContact(contact);
        /*
        // let formAnswers: SeshaFormAnswers = await (await getFormAnswers(contactId, formId)).json();
        // console.log(formAnswers);
 */
        // to do: verify if validated or locked
      }
    })();
  }, [id]);

  if (loadedForm !== undefined && loadedContact !== undefined && loadedFormAnswers !== undefined) {
    // TODO: missing a check of availability for the logged contact accessing the link
    if (props.disconnected) {
      return (
        <div key={id} id="formAnswer">
          <DisconnectedFormAnswers form={loadedForm} />
        </div>
      );
    } else if (loadedFormAnswers.closed) {
      return (
        <div key={id} id="formAnswer">
          <ClosedFormAnswers form={loadedForm} />
        </div>
      );
    } else {
      return (
        <div key={id} id="formAnswer">
          <DisplayFormAnswers
            form={loadedForm}
            contact={loadedContact}
            formAnswers={loadedFormAnswers}
          />
        </div>
      );
    }
  } else {
    return (
      <Spin
        spinning={
          loadedForm === undefined || loadedContact === undefined || loadedFormAnswers === undefined
        }
      >
        <h3>Loading...</h3>
      </Spin>
    );
  }
}

export default LaunchFormAnswers;
