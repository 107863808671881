import {
  AccountingFirmOutput,
  FormInput,
  FormOutput,
  MailTemplateBodyOutput,
  MailTemplateOutput,
} from "@addventa/sesha-forms-api";
import {
  Button,
  Checkbox,
  ConfigProvider,
  DatePicker,
  Flex,
  Form,
  Input,
  Modal,
  theme,
} from "antd";
import fr_FR from "antd/lib/date-picker/locale/fr_FR";
import { useContext, useState } from "react";
import { apiForm, apiFormAnswers } from "../../../../../api-configuration/Configuration";
import { sendReminders } from "../../../../../api/API";
import Context from "../../../../../context/Context";
import { useNotification } from "../../../../../hooks/useNotification";
import { generateSubject, generateText } from "../../../../../utils/generateMailText";
import TextEditor from "../../../../assets/editor/TextEditor";
import { EModalMailReminderState, ExtendedSeshaFormsAnswers } from "../../ReviewFormModel.d";

const allMailTemplatesInit = {
  form: {
    subject: "[<nom_cabinet>] - <nom_form>",
    linkText: "Accéder au formulaire",
    body: {
      beginning:
        "Bonjour,<br/><br/>Je vous invite à remplir le formulaire &lt;nom_form&gt; avant le &lt;date_fin&gt;.<br/>Je reste à votre disposition pour tout complément d'information.",
      ending: "&lt;signature_collab&gt;",
    },
  },
  formreminder: {
    subject: "[<nom_cabinet>] - <nom_form>",
    linkText: "Accéder au formulaire",
    body: {
      beginning:
        "Bonjour,<br/><br/>Je me permets de vous relancer au sujet du formulaire &lt;nom_form&gt;.<br/>A ce jour, vous avez rempli &lt;taux_completion&gt; de votre formulaire.<br/>Afin d'assurer le traitement de votre dossier, je vous remercie de compléter les informations manquatnes avant le &lt;date_fin&gt;.<br/>Je reste à votre disposition pour tout complément d'information.",
      ending: "&lt;signature_collab&gt;",
    },
  },
};

export function YourFormAnswersReminderModal(props: {
  form: FormOutput;
  formAnswersList: ExtendedSeshaFormsAnswers[];
  setFormAnswersList: React.Dispatch<React.SetStateAction<ExtendedSeshaFormsAnswers[]>>;
  modalIsOpen: boolean;
  setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  accountingFirm: AccountingFirmOutput | undefined;
  notGeneralReminder?: boolean | undefined;
}) {
  const [schedulingState, setSchedulingState] = useState<EModalMailReminderState>();
  const [mailScheduleForm] = Form.useForm();
  const { showNotification } = useNotification();
  const { user } = useContext(Context);

  const {
    token: { colorPrimaryBgHover, colorText },
  } = theme.useToken();

  const onFinish = async (values: any) => {
    let updatedFormAnswersList: ExtendedSeshaFormsAnswers[] = [];
    if (schedulingState === EModalMailReminderState.NOW || props.notGeneralReminder === true) {
      const formAnswersIdsToSend =
        props.notGeneralReminder === true
          ? props.formAnswersList.filter((el) => el.closed !== true).map((el) => el._id)
          : props.formAnswersList
              .filter((el) => el.completionRate !== 100 && el.closed !== true)
              .map((el) => el._id);
      const res = await sendReminders({
        formId: props.form._id,
        subject: values.subject,
        mailBody1: values.mailBody1,
        mailBody2: values.mailBody2,
        formAnswersIds: formAnswersIdsToSend,
      });
      if (res.status === 200 && formAnswersIdsToSend.length > 0) {
        showNotification("success", "Relances envoyées");
        props.setModalIsOpen(false);
      } else showNotification("error", "Erreur lors de l'envoi de la relance");
    } else {
      updatedFormAnswersList = props.formAnswersList
        .filter((el) => el.completionRate !== 100 && el.closed !== true)
        .map((el) => ({ ...el, reminderSendDate: values.schedulingDateDatePicker }));

      const res = await apiFormAnswers.formAnswersUpdateMany(updatedFormAnswersList);
      if (res.length) {
        const mailTemplate: MailTemplateOutput = {
          subject: values.subject,
          body: [
            { para: "begining", text: values.mailBody1 },
            { para: "ending", text: values.mailBody2 },
          ],
        };
        const senderName = !user ? "" : user?.firstName + " " + user?.lastName;
        const updatedForm: FormInput = {
          ...props.form,
          mailReminderSender: senderName,
          templateMailFormReminderGeneral: mailTemplate,
        };

        const resForm = await apiForm.formUpdateOne(props.form._id, updatedForm);
        if (!resForm) {
          showNotification("error", "Erreur lors de la programmation");
        } else {
          showNotification("success", "Relance programmée");
        }
      } else showNotification("error", "Erreur lors de la programmation");
    }
    props.setFormAnswersList((previous) => {
      return previous.map((el) => {
        const updatedFormAnswer = updatedFormAnswersList.find(
          (updatedEl) => updatedEl._id === el._id
        );
        return updatedFormAnswer ?? el;
      });
    });
    props.setModalIsOpen(false);
  };

  const onClose = () => {
    props.setModalIsOpen(false);
  };

  return (
    <>
      {props.accountingFirm && user && (
        <ConfigProvider
          theme={{
            components: {
              Modal: {
                headerBg: colorPrimaryBgHover,
              },
            },
          }}
        >
          <Modal
            title={
              !props.notGeneralReminder
                ? "Paramétrage d’une relance générale"
                : "Paramétrage d’une relance"
            }
            className="modal-reviewForm-yourFormAnswersReminder"
            open={props.modalIsOpen}
            footer={null}
            onCancel={onClose}
          >
            <Form
              onFinish={onFinish}
              form={mailScheduleForm}
              layout="vertical"
              initialValues={{
                subject: generateSubject(
                  props.accountingFirm.templateMailFormReminder?.subject ||
                    allMailTemplatesInit.formreminder.subject,
                  props.accountingFirm,
                  props.form
                ),
                mailBody1: generateText(
                  props.accountingFirm.templateMailFormReminder?.body?.find(
                    (elt: MailTemplateBodyOutput) => elt.para === "begining"
                  )?.text || allMailTemplatesInit.formreminder.body.beginning,
                  user,
                  props.accountingFirm,
                  props.form
                ),
                mailBody2: generateText(
                  props.accountingFirm.templateMailFormReminder?.body?.find(
                    (elt: MailTemplateBodyOutput) => elt.para === "ending"
                  )?.text || allMailTemplatesInit.formreminder.body.ending,
                  user,
                  props.accountingFirm,
                  props.form
                ),
              }}
            >
              <Flex vertical gap="large">
                <Form.Item label="Objet du mail" name="subject">
                  <Input />
                </Form.Item>
                <Form.Item label="Corps du mail" name="mailBody1">
                  <TextEditor
                    initialValue={generateText(
                      props.accountingFirm.templateMailFormReminder?.body?.find(
                        (elt: MailTemplateBodyOutput) => elt.para === "begining"
                      )?.text || allMailTemplatesInit.formreminder.body.beginning,
                      user,
                      props.accountingFirm,
                      props.form
                    )}
                    onChange={(data: string) => console.log("")}
                  />
                </Form.Item>
                <p style={{ color: "#4569F8", textDecoration: "underline" }}>
                  Accéder au formulaire
                </p>
                <Form.Item label="" name="mailBody2">
                  <TextEditor
                    initialValue={generateText(
                      props.accountingFirm.templateMailFormReminder?.body?.find(
                        (elt: MailTemplateBodyOutput) => elt.para === "ending"
                      )?.text || allMailTemplatesInit.formreminder.body.ending,
                      user,
                      props.accountingFirm,
                      props.form
                    )}
                    onChange={(data: string) => console.log("")}
                  />
                </Form.Item>
                {!props.notGeneralReminder ? (
                  <>
                    <p style={{ color: colorText }}>
                      <i>
                        Cette relance sera envoyée à tous les contacts n’ayant pas complété le
                        formulaire à 100%.
                      </i>
                    </p>
                    <Flex justify="start" align="center" gap="small" style={{ maxWidth: "70%" }}>
                      <Checkbox
                        checked={schedulingState === EModalMailReminderState.SCHEDULING}
                        onChange={() => setSchedulingState(EModalMailReminderState.SCHEDULING)}
                      >
                        Programmer le
                      </Checkbox>
                      <Form.Item name="schedulingDateDatePicker">
                        <DatePicker
                          locale={fr_FR}
                          placeholder="Selectionner une date"
                          style={{ width: "fit-content" }}
                          showTime={{
                            minuteStep: 15,
                            disabledMinutes: () => {
                              const minutes = [];
                              for (let i = 0; i < 60; i++) {
                                if (i % 15 !== 0) {
                                  minutes.push(i);
                                }
                              }
                              return minutes;
                            },
                          }}
                          disabled={schedulingState !== EModalMailReminderState.SCHEDULING}
                        />
                      </Form.Item>
                    </Flex>
                    <Checkbox
                      style={{ width: "fit-content" }}
                      checked={schedulingState === EModalMailReminderState.NOW}
                      onChange={() => setSchedulingState(EModalMailReminderState.NOW)}
                    >
                      Envoyer tout de suite
                    </Checkbox>
                  </>
                ) : null}
              </Flex>
              <div
                style={{
                  textAlign: "right",
                  paddingTop: props.notGeneralReminder ? "20px" : "0px",
                }}
              >
                <Button type="primary" htmlType="submit" style={{ marginBottom: "10px" }}>
                  Envoyer
                </Button>
              </div>
            </Form>
          </Modal>
        </ConfigProvider>
      )}
    </>
  );
}
