import { Flex } from "antd";

import { FormOutput } from "@addventa/sesha-forms-api";

function DisconnectedFormAnswers(props: { form: FormOutput }) {
  return (
    <Flex
      vertical
      justify="center"
      style={{
        minHeight: "65vh",
      }}
      gap="large"
    >
      <h1 style={{ margin: 0, textAlign: "center" }}>Merci</h1>
      <h2 style={{ margin: 0, textAlign: "center" }}>
        Vos réponses ont été sauvegardées.
        <br />
        Vous pouvez fermer la fenêtre.
      </h2>
    </Flex>
  );
}

export default DisconnectedFormAnswers;
