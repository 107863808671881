import {
  ContactOutput,
  TagOutput,
  UserContactInput,
  UserContactOutput,
  UserOutput,
} from "@addventa/sesha-forms-api";
import { Checkbox, Tag, TreeSelect, theme } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { CustomTagProps } from "rc-select/lib/BaseSelect";
import { useEffect, useState } from "react";
import { apiContact, apiTag, apiUserContactAsso } from "../../api-configuration/Configuration";
import { SeshaOption } from "../../types/SeshaForm/SeshaOption";
import { Contents } from "../../types/misc/Generic";

const ContactSelection = (props: { users: UserOutput[]; selectedUsers: string[] }) => {
  const {
    token: { colorTextSecondary, colorPrimary },
  } = theme.useToken();

  const [contacts, setContacts] = useState<ContactOutput[]>([]);
  const [filteredContacts, setFilteredContacts] = useState<ContactOutput[]>([]);
  const [tags, setTags] = useState<TagOutput[]>([]);
  const [userContactAsso, setUserContactAsso] = useState<UserContactOutput[]>([]);
  const [reload, setReload] = useState<boolean>(false);
  const [options, setOptions] = useState<Contents<SeshaOption>[]>([]);

  useEffect(() => {
    (async () => {
      const resContacts = await apiContact.contactFindAll();
      const resTags = await apiTag.tagFindAll();
      setContacts(resContacts);
      setTags(resTags);
      const treeData = [
        {
          title: "Tags",
          value: "tags",
          key: "tags",
          label: "tags",
          children: resTags.map((tag) => ({
            value: tag._id,
            label: tag.label,
          })),
        },
        {
          title: "Contacts",
          value: "contacts",
          key: "contacts",
          label: "contacts",
          children: resContacts.map((contact: ContactOutput) => ({
            value: contact._id,
            label: contact.firstName + " " + contact.lastName,
          })),
        },
      ];
      setOptions(treeData);
    })();
  }, []);

  useEffect(() => {
    setFilteredContacts(contacts);
  }, [contacts]);

  useEffect(() => {
    (async () => {
      setUserContactAsso(await apiUserContactAsso.userContactAssoFindAll());
    })();
  }, [reload]);

  const search = (value: string[]): void => {
    if (value.length > 0) {
      setFilteredContacts(
        contacts.filter(
          (contacts) =>
            value.includes(contacts._id) || contacts.tags.some((tag) => value.includes(tag))
        )
      );
    } else {
      setFilteredContacts(contacts);
    }
  };

  const onChange = async (
    e: CheckboxChangeEvent,
    isPreviousStateIndeterminate: boolean,
    contactId: string,
    usersWithAccess: string[]
  ) => {
    if (e.target.checked && !isPreviousStateIndeterminate) {
      const userContactsToAdd: UserContactInput[] = props.selectedUsers.map((userId) => ({
        contactId,
        userId,
      }));
      await apiUserContactAsso.userContactAssoCreateMany(userContactsToAdd);
    } else if (e.target.checked && isPreviousStateIndeterminate) {
      const userContactsToAdd: UserContactInput[] = props.selectedUsers
        .filter((el) => !usersWithAccess.includes(el))
        .map((userId) => ({ contactId, userId }));
      await apiUserContactAsso.userContactAssoCreateMany(userContactsToAdd);
    } else {
      const idsToDelete: string[] = userContactAsso
        .filter((el) => el.contactId === contactId && props.selectedUsers.includes(el.userId))
        .map((el) => el._id);
      await apiUserContactAsso.userContactAssoDeleteMany(idsToDelete);
    }
    setReload(!reload);
  };

  const selectAll = async () => {
    const userContactsToAdd: UserContactInput[] = props.selectedUsers
      .map((userId) => filteredContacts.map((contact) => ({ userId, contactId: contact._id })))
      .flat(1)
      .filter(
        (el) =>
          !userContactAsso.some(
            ({ contactId, userId }) => el.contactId === contactId && el.userId === userId
          )
      );
    await apiUserContactAsso.userContactAssoCreateMany(userContactsToAdd);
    setReload(!reload);
  };

  const unselectAll = async () => {
    const idsToDelete: string[] = userContactAsso
      .filter((el) => filteredContacts.some(({ _id }) => el.contactId === _id))
      .filter((el) => props.selectedUsers.includes(el.userId))
      .map(({ _id }) => _id);
    await apiUserContactAsso.userContactAssoDeleteMany(idsToDelete);
    setReload(!reload);
  };

  const tagRender = (props: CustomTagProps) => {
    const color = tags?.find((tag) => tag._id === props.value)?.color;

    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={color}
        onMouseDown={onPreventMouseDown}
        closable={props.closable}
        onClose={props.onClose}
        style={{
          marginRight: 3,
          marginLeft: 3,
          marginBottom: 1,
          borderRadius: 8,
          color: "#2F2F2F",
        }}
      >
        {props.label}
      </Tag>
    );
  };

  return (
    <div
      style={{
        borderRadius: "34px",
        boxShadow: "2px 6px 10.1px 1px rgba(0, 0, 0, 0.15)",
        padding: "20px",
      }}
    >
      {props.selectedUsers.length ? (
        <>
          <TreeSelect
            placeholder="Rechercher"
            onChange={search}
            treeCheckable
            treeData={options}
            treeNodeFilterProp="label"
            multiple={true}
            allowClear
            tagRender={tagRender}
            style={{ width: "100%", marginBottom: "15px" }}
          />
          <div style={{ maxHeight: "500px", overflowY: "auto" }}>
            {filteredContacts.map((contact) => {
              const filteredContactUserAsso: UserContactOutput[] = userContactAsso.filter(
                (el) => el.contactId === contact._id
              );
              const areEveryUsersInContact: boolean = props.selectedUsers.every((userId) =>
                filteredContactUserAsso.some((el) => el.userId === userId)
              );
              const isStateIndeterminate: boolean =
                !areEveryUsersInContact &&
                props.selectedUsers.some((userId) =>
                  filteredContactUserAsso.some((el) => el.userId === userId)
                );
              const usersWithAccess: string[] = props.selectedUsers.filter((userId) =>
                filteredContactUserAsso.some((el) => el.userId === userId)
              );
              const currentTags: TagOutput[] = contact.tags
                .map((el) => tags.find((tag) => tag?._id === el))
                .filter((tag): tag is TagOutput => !!tag);
              return (
                <div key={contact._id}>
                  <Checkbox
                    onChange={(e) =>
                      onChange(e, isStateIndeterminate, contact._id, usersWithAccess)
                    }
                    checked={areEveryUsersInContact}
                    indeterminate={isStateIndeterminate}
                    style={{ paddingBottom: "2px" }}
                  >
                    <span style={{ marginRight: 5 }}>
                      {contact.firstName + " " + contact.lastName}
                    </span>
                    {currentTags.map((el) => (
                      <Tag
                        color={el.color}
                        style={{
                          marginRight: 3,
                          marginLeft: 3,
                          marginBottom: 1,
                          borderRadius: 8,
                          color: colorTextSecondary,
                        }}
                      >
                        {el.label}
                      </Tag>
                    ))}
                  </Checkbox>
                  <br />
                </div>
              );
            })}
          </div>
          <div style={{ paddingTop: "15px", color: colorPrimary }}>
            <a
              style={{ color: colorPrimary }}
              onMouseEnter={(e) => (e.currentTarget.style.textDecoration = "underline")}
              onMouseLeave={(e) => (e.currentTarget.style.textDecoration = "none")}
              onClick={selectAll}
            >
              Tout sélectionner
            </a>{" "}
            /{" "}
            <a
              style={{ color: colorPrimary }}
              onMouseEnter={(e) => (e.currentTarget.style.textDecoration = "underline")}
              onMouseLeave={(e) => (e.currentTarget.style.textDecoration = "none")}
              onClick={unselectAll}
            >
              Tout désélectionner
            </a>
          </div>
        </>
      ) : (
        <p>Pour commencer, veuillez choisir un ou plusieurs utilisateurs dans le menu à gauche.</p>
      )}
    </div>
  );
};

export default ContactSelection;
