import QuestionGlobal from "../question/Question";

import { useDroppable } from "@dnd-kit/core";

import { FormOutput, QuestionOutput, SectionOutput } from "@addventa/sesha-forms-api";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { useEffect, useState } from "react";
import { Contents, Edition, Identified } from "../../../../types/misc/Generic";

function SectionContainer(props: {
  id: string;
  isReviewMode?: boolean;
  items: Identified<Contents<QuestionOutput>>[];
  form: Edition<Identified<Contents<FormOutput>>>;
  setForm: React.Dispatch<
    React.SetStateAction<Edition<Identified<Contents<FormOutput>>> | undefined>
  > | null;
  section: Identified<Contents<SectionOutput>>;
  setIsModified?: React.Dispatch<React.SetStateAction<boolean>>;
  setIsEdited?: (edited: string[]) => void;
}) {
  const { setNodeRef } = useDroppable({
    id: props.id,
  });

  const [editedQuestions, setEditedQuestions] = useState<string[]>([]);

  useEffect(() => {
    if (props.setIsEdited) {
      props.setIsEdited(editedQuestions);
    }
  }, [editedQuestions]);

  return (
    <>
      <SortableContext
        id={props.id}
        items={props.items as []}
        strategy={verticalListSortingStrategy}
      >
        <div ref={setNodeRef}>
          {props.items?.map((question) => (
            <QuestionGlobal
              isReviewMode={props.isReviewMode}
              key={question._id + question.tempId}
              form={props.form}
              setForm={props.setForm}
              section={props.section}
              question={question}
              setIsModified={props.setIsModified}
              setIsEdited={ (isEdited: boolean) => {
                if (isEdited) {
                  if (!editedQuestions.includes(question.tempId)) {
                    setEditedQuestions([...editedQuestions, question.tempId]);
                  }
                } else {
                  setEditedQuestions(editedQuestions.filter( (tempId: string) => tempId !== question.tempId));
                }
              }}
              editedQuestions={editedQuestions}
              //dragOverlay={question === activeId ? true : false}
            />
          ))}
        </div>
      </SortableContext>
    </>
  );
}

export default SectionContainer;
