import { Spin, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { testCodeValidity } from "../../../api/API";
import iD_error from "../../../assets/images/contact/iD-error.png";
import iD_unavailable from "../../../assets/images/contact/iD-unavailable.png";
import iD_valid from "../../../assets/images/contact/iD-valid.png";
import { ContactOutput } from "@addventa/sesha-forms-api";

function IDepotColumn(props: {
  record: ContactOutput;
  text: string;
  iDepotState: string;
  uuid: string;
}) {
  const [status, setStatus] = useState<Number | undefined>();

  useEffect(() => {
    if (props.iDepotState === "KO") {
      setStatus(0);
    } else if (props.uuid !== "" && props.iDepotState !== "") {
      (async () => {
        if (props.record.iDepot && props.record.iDepot !== "") {
          const res = await testCodeValidity({ code: props.record.iDepot, uuid: props.uuid });
          if (res.status !== 200) {
            setStatus(2);
          } else {
            setStatus(1);
          }
        } else {
          setStatus(3);
        }
      })();
    }
  }, [props.uuid, props.iDepotState, props.record.iDepot]);

  const switchState = () => {
    switch (status) {
      case 0:
        return (
          <Tooltip placement="top" title="i-Dépôt n'est pas activé sur le cabinet">
            <img src={iD_unavailable} alt="i-Dépôt indisponible" width={"22px"} />
          </Tooltip>
        );
      case 1:
        return (
          <Tooltip placement="top" title="Le contact est lié à i-Dépôt">
            <img src={iD_valid} alt="Code dossier valide" width={"22px"} />
          </Tooltip>
        );
      case 2:
        return (
          <Tooltip placement="top" title="Le code dossier n'est pas valide">
            <img src={iD_error} alt="Code dossier non valide" width={"22px"} />
          </Tooltip>
        );
      case 3:
        return "";
    }
  };

  return (
    <>
      <div>
        <Spin spinning={status === undefined}>{switchState()}</Spin>
      </div>
    </>
  );
}

export default IDepotColumn;
