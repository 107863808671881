import { RequestContext } from "@addventa/sesha-forms-api";

export const preMiddleware = async (context: RequestContext) => {
  const token =
    sessionStorage.getItem("token") !== null
      ? sessionStorage.getItem("token")
      : localStorage.getItem("token");
  context.init.headers = {
    ...context.init.headers,
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  };
  return context;
};
