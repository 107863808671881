import { CheckCircleOutlined } from "@ant-design/icons";
import { Flex, Typography, theme } from "antd";

import { FormOutput } from "@addventa/sesha-forms-api";

const { Title } = Typography;

function FormAnswersValidated(props: { form: FormOutput }) {
  const {
    token: { colorPrimary },
  } = theme.useToken();

  return (
    <Flex vertical align="center" gap="middle" justify="center">
      <CheckCircleOutlined
        style={{
          color: colorPrimary,
          fontSize: "80px",
        }}
      />
      <Flex vertical gap="small">
        <h1 style={{ margin: 0, textAlign: "center" }}>Merci ! Vos réponses ont été envoyées.</h1>
        <h3 style={{ margin: 0, textAlign: "center" }}>
          Pour éditer vos réponses, veuillez contacter votre cabinet.
        </h3>
      </Flex>
    </Flex>
  );
}

export default FormAnswersValidated;
