import { FormAnswersOutput, FormOutput } from "@addventa/sesha-forms-api";
import { FileAddOutlined, LockFilled } from "@ant-design/icons";
import { Button, Checkbox, CheckboxProps, ConfigProvider, Spin, Table, theme } from "antd";
import type { ColumnsType } from "antd/es/table";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { apiUser } from "../../../api-configuration/Configuration";
import Context from "../../../context/Context";
import { useNotification } from "../../../hooks/useNotification";

function FormList(props: {
  forms: FormOutput[];
  formsAnswers: FormAnswersOutput[];
  loading: boolean;
}) {
  const {
    token: { colorPrimaryBgHover },
  } = theme.useToken();
  const navigate = useNavigate();
  const location = useLocation();
  const { showNotification } = useNotification();
  const { user } = useContext(Context);

  const [consolidatedForms, setConsolidatedForms] = useState<
    { form: FormOutput; answers: FormAnswersOutput[] | undefined }[]
  >([]);
  const [displayClosedForms, setDisplayClosedForms] = useState<boolean>(false);
  const [allForms, setAllForms] = useState<FormOutput[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      if (!user) return;
      setDisplayClosedForms(user.displayClosedForms);
    })();
  }, [user]);

  useEffect(() => {
    const forms = props.forms.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());
    if (displayClosedForms) {
      setAllForms(forms.filter((form) => !form.isClosed));
    } else {
      setAllForms(forms);
    }
    if (!props.loading) setLoading(false);
  }, [displayClosedForms, props.forms, props.loading]);

  useEffect(() => {
    if (location.state !== null) {
      if (location.state.creation) {
        showNotification("success", "Votre mail a bien été envoyé");
      }
      navigate(location.pathname, { replace: true });
    }
  }, []);

  useEffect(() => {
    setConsolidatedForms(
      props.forms.map((form) => ({
        form: form,
        answers: props.formsAnswers.filter((el) => el.formId === form._id),
      }))
    );
  }, [props.forms, props.formsAnswers]);

  const columns: ColumnsType<FormOutput> = [
    {
      title: "Nom du formulaire",
      dataIndex: "title",
      key: "title",
      sorter: (a, b) => a.title.localeCompare(b.title),
      width: "320px",
      render: (text, record) => <span style={{ lineHeight: "normal" }}>{text}</span>,
      showSorterTooltip: false,
    },
    {
      title: "Etat",
      dataIndex: "state",
      key: "state",
      width: "160px",
      align: "center",
      sorter: (a, b) => (a.draft ? 0 : 1) - (b.draft ? 0 : 1),
      render: (text, record) => (
        <div style={{ textAlign: "center" }}>
          {record.isClosed &&
          consolidatedForms
            .find((el) => el.form._id === record._id)
            ?.answers?.filter((el) => !el.closed).length === 0 ? (
            <LockFilled
              style={{
                color: "#FF9700",
                fontSize: 15,
              }}
            />
          ) : (
            <span
              style={{
                height: "10px",
                width: "10px",
                backgroundColor: record.draft ? "#A6A6A6" : "#099F2A",
                borderRadius: "50%",
                display: "inline-block",
              }}
            ></span>
          )}

          <span
            style={{
              display: "block",
              fontStyle: "italic",
              fontSize: "12px",
            }}
          >
            {record.draft ? (
              "Brouillon"
            ) : record.isClosed &&
              consolidatedForms
                .find((el) => el.form._id === record._id)
                ?.answers?.filter((el) => !el.closed).length === 0 ? (
              <>Clôturé le {record.closeDate ? dayjs(record.closeDate).format("DD/MM/YYYY") : ""}</>
            ) : (
              <>Envoyé le {record.sentAt ? dayjs(record.sentAt).format("DD/MM/YYYY") : ""}</>
            )}
          </span>
        </div>
      ),
      showSorterTooltip: false,
    },
    {
      title: "Soumissions",
      dataIndex: "submission",
      key: "submission",
      width: "200px",
      align: "center",
      sorter: (a, b) =>
        (consolidatedForms.find((el) => el.form._id === a._id)?.answers?.filter((el) => el.closed)
          .length || 0) -
        (consolidatedForms.find((el) => el.form._id === b._id)?.answers?.filter((el) => el.closed)
          .length || 0),
      render: (text, record) =>
        consolidatedForms
          .find((el) => el.form._id === record._id)
          ?.answers?.filter((el) => el.closed).length,
      showSorterTooltip: false,
    },
    {
      title: "Nombre d'envois",
      dataIndex: "totalSent",
      key: "totalSent",
      width: "200px",
      align: "center",
      sorter: (a, b) =>
        (consolidatedForms.find((el) => el.form._id === a._id)?.answers?.length || 0) -
        (consolidatedForms.find((el) => el.form._id === b._id)?.answers?.length || 0),
      render: (text, record) =>
        consolidatedForms.find((el) => el.form._id === record._id)?.answers?.length,
      showSorterTooltip: false,
    },
    {
      title: "",
      key: "action",
      align: "center",
      render: (text, record) => {
        if (record.draft) {
          return (
            <ConfigProvider
              theme={{
                components: {
                  Button: {
                    controlHeight: 26,
                  },
                },
              }}
            >
              {" "}
              <Button
                onClick={() => navigate("/form/create/" + record._id)}
                style={{ background: colorPrimaryBgHover, border: 0 }}
              >
                Modifier
              </Button>
            </ConfigProvider>
          );
        } else {
          return (
            <ConfigProvider
              theme={{
                components: {
                  Button: {
                    controlHeight: 26,
                  },
                },
              }}
            >
              <Button
                onClick={() => navigate("/form/" + record._id)}
                style={{ background: colorPrimaryBgHover, border: 0 }}
              >
                Voir le détail
              </Button>
            </ConfigProvider>
          );
        }
      },
    },
  ];

  const locale = {
    emptyText: (
      <>
        {loading ? (
          <Spin spinning={loading} />
        ) : (
          <div style={{ marginTop: "18px" }}>
            <FileAddOutlined style={{ fontSize: "60px", color: "#A6A6A6" }} />
            <p style={{ fontSize: "19px", fontWeight: 700, color: "#A6A6A6" }}>
              Vos formulaires apparaîtront ici.
              <br />
              Pour commencer, cliquez sur “Créer un formulaire”.
            </p>
          </div>
        )}
      </>
    ),
  };

  const onChangeDisplayForms: CheckboxProps["onChange"] = (e) => {
    if (!user) {
      console.error("onChangeDisplayForms - user is undefined:  " + user);
      return;
    }
    setDisplayClosedForms(e.target.checked);
    apiUser.userUpdateOne(user._id, { displayClosedForms: e.target.checked });
  };

  return (
    <div style={{ marginTop: "5px" }}>
      <Checkbox
        checked={displayClosedForms}
        onChange={onChangeDisplayForms}
        style={{ float: "right", marginBottom: "10px", marginTop: "-20px" }}
        disabled={!user}
      >
        Masquer les formulaires clôturés
      </Checkbox>
      <ConfigProvider
        theme={{
          components: {
            Table: {
              cellPaddingBlock: 5,
            },
          },
        }}
      >
        <Table
          columns={columns}
          dataSource={allForms}
          locale={locale}
          pagination={{ position: ["bottomCenter"], size: "small" }}
        />
      </ConfigProvider>
    </div>
  );
}

export default FormList;
