import { jwtDecode } from "jwt-decode";
import { EFeatures } from "../enums/EFeatures";

export const useIsUserToken = (): boolean => {
  const storedToken = localStorage.getItem("token");
  if (!storedToken) return false;

  const { exp, features } = jwtDecode<{ exp: number; features: EFeatures }>(storedToken);
  return Date.now() < exp * 1000 && features?.length > 0;
};

export const useIsUserTokenSession = (): boolean => {
  const storedToken = sessionStorage.getItem("token");
  if (!storedToken) return false;

  const { exp, features } = jwtDecode<{ exp: number; features: EFeatures }>(storedToken);
  return Date.now() < exp * 1000 && features?.length > 0;
};
