import { ContactOutput, TagOutput } from "@addventa/sesha-forms-api";
import { SearchOutlined } from "@ant-design/icons";
import { Tag, TreeSelect } from "antd";
import { useEffect, useState } from "react";
import { apiContact, apiTag } from "../../api-configuration/Configuration";
import { SeshaOption } from "../../types/SeshaForm/SeshaOption";
import { Contents } from "../../types/misc/Generic";

import { CustomTagProps } from "rc-select/lib/BaseSelect";

function ContactFilter(props: {
  setFilteredContactsIds: React.Dispatch<React.SetStateAction<string[]>>;
  reloadSearch: boolean;
}) {
  const [contacts, setContacts] = useState<ContactOutput[]>([]);
  const [tags, setTags] = useState<TagOutput[]>([]);
  const [options, setOptions] = useState<Contents<SeshaOption>[]>([]);

  useEffect(() => {
    (async () => {
      const resContacts = await apiContact.contactFindAll();
      const resTags = await apiTag.tagFindAll();
      setContacts(resContacts);
      props.setFilteredContactsIds(resContacts.map(({ _id }) => _id));
      setTags(resTags);
      const treeData = [
        {
          title: "Tags",
          value: "tags",
          key: "tags",
          label: "tags",
          children: resTags.map((tag) => ({
            value: tag._id,
            label: tag.label,
          })),
        },
        {
          title: "Contacts",
          value: "contacts",
          key: "contacts",
          label: "contacts",
          children: resContacts.map((contact: ContactOutput) => ({
            value: contact._id,
            label: contact.firstName + " " + contact.lastName,
          })),
        },
      ];
      setOptions(treeData);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.reloadSearch]);

  const search = (value: string[]): void => {
    props.setFilteredContactsIds(
      value.length > 0
        ? contacts
            .filter(
              (contact) =>
                value.includes(contact._id) || contact.tags.some((tag) => value.includes(tag))
            )
            .map(({ _id }) => _id)
        : contacts.map(({ _id }) => _id)
    );
  };

  const tagRender = (props: CustomTagProps) => {
    const color = tags?.find((tag) => tag._id === props.value)?.color;

    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={color}
        onMouseDown={onPreventMouseDown}
        closable={props.closable}
        onClose={props.onClose}
        style={{
          marginRight: 3,
          marginLeft: 3,
          marginBottom: 1,
          borderRadius: 8,
          color: "#2F2F2F",
        }}
      >
        {props.label}
      </Tag>
    );
  };

  return (
    <div className="client-search" style={{ textAlign: "center" }}>
      <TreeSelect
        placeholder="Rechercher un contact ou un tag"
        onChange={search}
        treeCheckable
        treeData={options}
        treeNodeFilterProp="label"
        multiple={true}
        allowClear
        tagRender={tagRender}
        style={{
          width: "80%",
          zIndex: 0,
          marginTop: "75px",
          fontSize: "22px",
          height: "55px",
          textAlign: "left",
        }}
        suffixIcon={
          <SearchOutlined
            style={{
              fontSize: 23,
              marginTop: 8,
              marginBottom: 8,
              color: "#2F2F2F",
              paddingRight: "15px",
            }}
          />
        }
      />
    </div>
  );
}

export default ContactFilter;
