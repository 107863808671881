import { jwtDecode } from "jwt-decode";
import { EFeatures } from "../enums/EFeatures";

export const usePermission = (feature: EFeatures): boolean => {
  const token = localStorage.getItem("token");
  if (!token) return false;
  const { features } = jwtDecode<{ features: EFeatures[] }>(token);
  if (!features) return false;
  return features.includes(feature);
};
