import { UserOutput } from "@addventa/sesha-forms-api";
import { Col, Row, Tabs, TabsProps, theme } from "antd";
import { useEffect, useState } from "react";
import { apiUser } from "../../api-configuration/Configuration";
import ContactsSelection from "./ContactSelection";
import FormSelection from "./FormSelection";
import Users from "./UserSelection";

const IndexRights = () => {
  const {
    token: { colorPrimary, colorBgContainer },
  } = theme.useToken();

  const [users, setUsers] = useState<UserOutput[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  useEffect(() => {
    (async () => {
      setUsers(await apiUser.userFindAll());
    })();
  }, []);

  const items: TabsProps["items"] = [
    {
      key: "forms",
      label: "Formulaires",
      children: <FormSelection users={users} selectedUsers={selectedUsers} />,
      forceRender: true,
    },
    {
      key: "contacts",
      label: "Contacts",
      children: <ContactsSelection users={users} selectedUsers={selectedUsers} />,
      forceRender: true,
    },
  ];

  return (
    <>
      <Row>
        <Col span={6} offset={1}>
          <h3 style={{ color: colorPrimary }}>Gestion des droits</h3>
          <div
            style={{
              height: "3px",
              width: "35px",
              borderRadius: "2.5px",
              backgroundColor: colorPrimary,
              marginTop: "-3%",
            }}
          ></div>
        </Col>
      </Row>

      <Row style={{ marginTop: "30px" }}>
        <Col span={6} offset={1}>
          <div
            style={{
              width: "100%",
              height: "40px",
              marginBottom: "20px",
              borderRadius: "50px",
              backgroundColor: colorPrimary,
              textAlign: "center",
              fontWeight: "bold",
              color: colorBgContainer,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Utilisateurs
          </div>
        </Col>
        <Col span={15} offset={1}></Col>
      </Row>
      <Row>
        <Col span={6} offset={1}>
          <Users users={users} selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} />
          <div style={{ padding: "0px 3px 0px 3px" }}>
            <p>
              <i>
                Pour ajouter/modifier/supprimer des utilisateurs, rendez-vous sur la page
                "Administration", onglet "Gestion des utilisateurs".
              </i>
            </p>
          </div>
        </Col>
        <Col span={15} offset={1}>
          <Tabs
            centered
            indicator={{ size: 35, align: "start" }}
            items={items}
            style={{ marginTop: "-65px" }}
          />
        </Col>
      </Row>
    </>
  );
};

export default IndexRights;
