import { Col, Layout, Row, theme } from "antd";
import { Outlet } from "react-router-dom";
import NavClient from "../nav/NavClient";

const { Content } = Layout;

function ClientLayout(props: {
  setDisconnected: React.Dispatch<React.SetStateAction<boolean>>;
  setDisplayBtnDisco: React.Dispatch<React.SetStateAction<boolean>>;
  displayBtnDisco: boolean;
  logoFirmId: string;
}) {
  const {
    token: { colorBgLayout, colorBgContainer },
  } = theme.useToken();

  return (
    <div
      style={{
        background: colorBgLayout,
        minHeight: "100vh",
      }}
    >
      <Layout
        className="layout"
        style={{
          background: colorBgLayout,
          width: "90%",
          maxWidth: "1238px",
          margin: "0 auto",
        }}
      >
        <NavClient
          setDisconnected={props.setDisconnected}
          displayBtnDisco={props.displayBtnDisco}
          setDisplayBtnDisco={props.setDisplayBtnDisco}
          logoFirmId={props.logoFirmId}
        />
        <Content>
          <Row
            justify="center"
            style={{
              background: colorBgContainer,
            }}
          >
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Outlet />
            </Col>
          </Row>
        </Content>
      </Layout>
    </div>
  );
}

export default ClientLayout;
