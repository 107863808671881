import { Flex, theme } from "antd";

import {
  AnswerOutput,
  FormAnswersOutput,
  FormOutput,
  QuestionOutput,
  RuleOutput,
  SectionOutput,
} from "@addventa/sesha-forms-api";
import { objectid } from "../../../../types/misc/MongoDB";
import DisplayOptionsField from "./DisplayOptionsField";
import DisplayTextareaField from "./DisplayTextareaField";
import DisplayUploadField from "./DisplayUploadField";

import { ReactComponent as CommentIcon } from "../../../../assets/images/commentaire.svg";
import { linkify } from "./utils";

interface Props {
  form: FormOutput;
  accountingFirmId: objectid;
  section: SectionOutput;
  question: QuestionOutput;
  answer: AnswerOutput;
  formAnswers: FormAnswersOutput;
  currentSectionRules: RuleOutput[];
  setTriggeredRule: React.Dispatch<React.SetStateAction<boolean>> | null;
  mandatoryWarning: boolean;
  isInReviewMode?: boolean;
}

function DisplayQuestion(props: Props) {
  const {
    token: { colorPrimary },
  } = theme.useToken();

  
  // isolate this question rule potentially trigger, to be more efficien
  //const [currentRulesTriggers] = useState<SeshaRule[]>(props.currentSectionRules.filter( r => r.questionId == props.question._id));

  const questionType = (question: QuestionOutput) => {
    switch (question.type) {
      case "TEXTAREA":
        return (
          <DisplayTextareaField
            key={question._id}
            formAnswers={props.formAnswers}
            form={props.form}
            section={props.section}
            question={question}
            answer={props.answer}
            mandatoryWarning={props.mandatoryWarning}
            isInReviewMode={props.isInReviewMode}
          />
        );
      case "OPTION":
        return (
          <DisplayOptionsField
            key={question._id}
            formAnswers={props.formAnswers}
            form={props.form}
            section={props.section}
            question={question}
            answer={props.answer}
            currentSectionRules={props.currentSectionRules}
            setTriggeredRule={props.setTriggeredRule}
            mandatoryWarning={props.mandatoryWarning}
            isInReviewMode={props.isInReviewMode}
          />
        );
      case "UPLOAD":
        return (
          <DisplayUploadField
            key={question._id}
            accountingFirmId={props.accountingFirmId}
            formAnswers={props.formAnswers}
            form={props.form}
            section={props.section}
            question={question}
            answer={props.answer}
            mandatoryWarning={props.mandatoryWarning}
            isInReviewMode={props.isInReviewMode}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <Flex
      vertical
      gap="middle"
      style={{ position: "relative", width: "100%" }}
      className="pdf-print-container"
    >
      <Flex gap="small" align="center">
        <h3>{props.section.questions!.indexOf(props.question)! + 1 + "."}</h3>
        <Flex gap="small" style={{ position: "relative", top: 1.5, fontSize: "14" }}>
          <p>{props.question.title}</p>
          {props.question.mandatory === true ? <p style={{ color: colorPrimary }}>*</p> : null}
        </Flex>
      </Flex>

      { props.question.note !== undefined && 
        <Flex style={{
          marginRight: "1%"
        }}>
          <CommentIcon  style={{
            width: "4%",
            height: "4%",
            paddingTop: "0.5%",
            marginLeft: "3%",
            marginRight: "1%",
          }}/>
          <span style={{color: "#00A9A9", fontSize: "12", fontStyle: "italic"}} >note&nbsp;du&nbsp;cabinet&nbsp;:&nbsp;</span>
          <span style={{ 
            fontSize: "12", 
            fontStyle: "italic", 
            wordBreak: "break-word", 
            whiteSpace: "pre-line"  
          }}>
            {linkify(props.question.note)}
          </span>
        </Flex>
      }
      <div>{questionType(props.question)}</div>
    </Flex>
  );
}

export default DisplayQuestion;
