import {
  AccountingFirmOutput,
  ContactOutput,
  FormOutput,
  MailTemplateBodyOutput,
} from "@addventa/sesha-forms-api";
import { Button, Checkbox, Form, Input, Tag, TreeSelect } from "antd";
import { CustomTagProps } from "rc-select/lib/BaseSelect";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiContact, apiForm, apiTag } from "../../../api-configuration/Configuration";
import { initFormAnswersOfForm, sendFormMail } from "../../../api/API";
import Context from "../../../context/Context";
import { useNotification } from "../../../hooks/useNotification";
import { SeshaOption } from "../../../types/SeshaForm/SeshaOption";
import { Contents } from "../../../types/misc/Generic";
import { generateSubject, generateText } from "../../../utils/generateMailText";
import TextEditor from "../../assets/editor/TextEditor";
import { ExtendedSeshaFormsAnswers } from "../review/ReviewFormModel.d";
import { checkRulesValidityOnIds } from "./utils";

const allMailTemplatesInit = {
  form: {
    subject: "[<nom_cabinet>] - <nom_form>",
    linkText: "Accéder au formulaire",
    body: {
      beginning:
        "Bonjour,<br/><br/>Je vous invite à remplir le formulaire &lt;nom_form&gt; avant le &lt;date_fin&gt;.<br/>Je reste à votre disposition pour tout complément d'information.",
      ending: "&lt;signature_collab&gt;",
    },
  },
  formreminder: {
    subject: "[<nom_cabinet>] - <nom_form>",
    linkText: "Accéder au formulaire",
    body: {
      beginning:
        "Bonjour,<br/><br/>Je me permets de vous relancer au sujet du formulaire &lt;nom_form&gt;.<br/>A ce jour, vous avez rempli &lt;taux_completion&gt; de votre formulaire.<br/>Afin d'assurer le traitement de votre dossier, je vous remercie de compléter les informations manquatnes avant le &lt;date_fin&gt;.<br/>Je reste à votre disposition pour tout complément d'information.",
      ending: "&lt;signature_collab&gt;",
    },
  },
};

function SendForm(props: {
  form: FormOutput;
  formAnswersSetForm?: React.Dispatch<React.SetStateAction<FormOutput | undefined>>;
  setFormAnswersList?: React.Dispatch<React.SetStateAction<ExtendedSeshaFormsAnswers[]>>;
  setOpenSendForm?: React.Dispatch<React.SetStateAction<boolean>>;
  accountingFirm: AccountingFirmOutput;
  isFormAnswers?: boolean;
}) {
  const { user } = useContext(Context);
  const { showNotification } = useNotification();

  const [contacts, setContacts] = useState<Contents<SeshaOption>[]>([]);
  const [tags, setTags] = useState<{ label: string; value: string; color: string }[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const resTags = await apiTag.tagFindAll();
      setTags(
        resTags.map((tag) => ({
          value: tag._id,
          label: tag.label,
          color: tag.color,
        }))
      );
      const allContacts = await apiContact.contactFindAll();
      const treeData = [
        {
          title: "Tags",
          value: "tags",
          key: "tags",
          label: "tags",
          children: resTags.map((tag) => ({
            value: tag._id,
            label: tag.label,
          })),
        },
        {
          title: "Contacts",
          value: "contacts",
          key: "contacts",
          label: "contacts",
          children: allContacts.map((contact: ContactOutput) => ({
            value: contact._id,
            label: contact.firstName + " " + contact.lastName,
          })),
        },
      ];
      setContacts(treeData);
    })();
  }, []);

  function displayRulesErrors(invalidRules: any[]): JSX.Element {
    return (
      <div>
        Des règles incluant:<br></br>
        {invalidRules.map((r) => (
          <div>
            {r.context.join("/")}
            <br />
          </div>
        ))}
        font réference à quelque chose absent du formulaire, contactez le support
      </div>
    );
  }

  const onFinish = (values: any) => {
    (async () => {
      if (checkRulesValidityOnIds(props.form, displayRulesErrors)) {
        let updatedForm: FormOutput = {
          ...props.form,
          sendCopyToContacts: values.sendCopyToContacts,
          draft: false,
          sentAt: new Date(),
          isClosed: false,
        };

        await apiForm.formUpdateOne(updatedForm._id, updatedForm);

        const res = await initFormAnswersOfForm(props.form._id, {
          recipients: values.recipients,
        });
        if (props.formAnswersSetForm && props.setFormAnswersList) {
          let updatedFormAnswers = JSON.parse(JSON.stringify(props.form));
          updatedFormAnswers.isClosed = false;
          const contactList = await apiContact.contactFindManyByIds(values.recipients);
          props.formAnswersSetForm(updatedFormAnswers);
          let resJson: ExtendedSeshaFormsAnswers[] = await res.json();
          resJson = resJson.map((formAnswer: ExtendedSeshaFormsAnswers) => ({
            ...formAnswer,
            key: String(formAnswer._id),
            isSelected: false,
            isLoaded: false,
            completionRate: 0,
            contact: contactList.find((contact) => contact._id === formAnswer.contactId)!,
          }));
          props.setFormAnswersList((previous) => {
            const copyOfPrevious = [...previous];
            copyOfPrevious.push(...resJson);
            return copyOfPrevious;
          });
        }

        const resSendForm = await sendFormMail({
          formId: props.form._id,
          subject: values.subject,
          mailBody1: values.mailBody1,
          mailBody2: values.mailBody2,
          recipients: values.recipients,
        });

        if (resSendForm.status === 200) {
          //console.log("/form/" + props.form._id);
          if (props.isFormAnswers) {
            navigate(".", { state: { creation: false } });
            window.location.reload();
          } else {
            navigate("/", { state: { creation: true } });
          }
        } else {
          showNotification("error", "Erreur lors l'envoi du mail");
        }
      } else {
        throw new Error("Invalid rules");
      }
    })();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const tagRender = (props: CustomTagProps) => {
    const color = tags?.find((tag) => tag.value === props.value)?.color;

    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={color}
        onMouseDown={onPreventMouseDown}
        closable={props.closable}
        onClose={props.onClose}
        style={{
          marginRight: 3,
          marginLeft: 3,
          marginBottom: 1,
          borderRadius: 8,
          color: "#2F2F2F",
        }}
      >
        {props.label}
      </Tag>
    );
  };

  return (
    <>
      {props.accountingFirm && user && (
        <Form
          name="basic"
          style={{ maxWidth: 600 }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
          initialValues={{
            subject: generateSubject(
              props.accountingFirm.templateMailForm?.subject || allMailTemplatesInit.form.subject,
              props.accountingFirm,
              props.form
            ),
            mailBody1: generateText(
              props.accountingFirm.templateMailForm?.body?.find(
                (elt: MailTemplateBodyOutput) => elt.para === "begining"
              )?.text || allMailTemplatesInit.form.body.beginning,
              user,
              props.accountingFirm,
              props.form
            ),
            mailBody2: generateText(
              props.accountingFirm.templateMailForm?.body?.find(
                (elt: MailTemplateBodyOutput) => elt.para === "ending"
              )?.text || allMailTemplatesInit.form.body.ending,
              user,
              props.accountingFirm,
              props.form
            ),
          }}
        >
          <Form.Item
            label="A qui souhaitez-vous envoyer le formulaire ?"
            name="recipients"
            rules={[{ required: true, message: "Merci de renseigner les destinataires." }]}
          >
            <TreeSelect
              treeCheckable
              treeData={contacts}
              treeNodeFilterProp="label"
              multiple={true}
              tagRender={tagRender}
            />
          </Form.Item>
          <Form.Item label="Objet du mail" name="subject">
            <Input />
          </Form.Item>

          <Form.Item label="Corps du mail" name="mailBody1">
            <TextEditor
              initialValue={generateText(
                props.accountingFirm.templateMailForm?.body?.find(
                  (elt: MailTemplateBodyOutput) => elt.para === "begining"
                )?.text || allMailTemplatesInit.form.body.beginning,
                user,
                props.accountingFirm,
                props.form!
              )}
              onChange={(data: string) => console.log(data)}
            />
          </Form.Item>

          <p style={{ color: "#4569F8", textDecoration: "underline" }}>Accéder au formulaire</p>
          <Form.Item label="" name="mailBody2">
            <TextEditor
              initialValue={generateText(
                props.accountingFirm.templateMailForm?.body?.find(
                  (elt: MailTemplateBodyOutput) => elt.para === "ending"
                )?.text || allMailTemplatesInit.form.body.ending,
                user,
                props.accountingFirm,
                props.form!
              )}
              onChange={(data: string) => console.log(data)}
            />
          </Form.Item>

          <Form.Item label="" name="sendCopyToContacts" valuePropName="checked">
            <Checkbox>Envoyer aux répondants une copie de leurs réponses</Checkbox>
          </Form.Item>

          <Form.Item style={{ marginTop: "20px", textAlign: "right" }}>
            {!props.isFormAnswers ? (
              <Button
                style={{ marginRight: "10px" }}
                onClick={() => {
                  props.setOpenSendForm!(false);
                }}
              >
                Précédent
              </Button>
            ) : null}
            <Button type="primary" htmlType="submit">
              {props.isFormAnswers
                ? "Envoyer à de nouveaux destinataires"
                : "Envoyer le formulaire"}
            </Button>
          </Form.Item>
        </Form>
      )}
    </>
  );
}

export default SendForm;
