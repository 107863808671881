import { FormOutput, SectionOutput } from "@addventa/sesha-forms-api";
import { Contents, Edition, Identified } from "../types/misc/Generic";

function calculateCumulativeOrder(
  section: Identified<Contents<SectionOutput>>,
  indexInSection: number,
  allSections: Identified<Contents<SectionOutput>>[]
): number {
  // Calculate the cumulative order
  let cumulativeOrder = 0;

  for (let i = 0; i < allSections.length; i++) {
    const s = allSections[i];
    if (s.tempId === section.tempId) {
      cumulativeOrder += indexInSection;
      break;
    } else {
      if (s.questions) {
        cumulativeOrder += s.questions.length;
      }
    }
  }

  return cumulativeOrder;
}

function updateOrderAllQuestions(
  updatingForm: Edition<Identified<Contents<FormOutput>>>,
): Edition<Identified<Contents<FormOutput>>> {
  const sectionsWithOrderedQuestions: Identified<Contents<SectionOutput>>[] = [
    ...updatingForm.sections!,
  ];

  for (let i = 0; i < sectionsWithOrderedQuestions.length; i++) {
    for (let j = 0; j < sectionsWithOrderedQuestions[i].questions!.length; j++) {
      sectionsWithOrderedQuestions[i].questions![j].order = calculateCumulativeOrder(
        sectionsWithOrderedQuestions[i],
        j,
        updatingForm.sections!
      );
    }
  }

  const updatedForm: Edition<Identified<Contents<FormOutput>>> = {
    ...updatingForm,
    sections: sectionsWithOrderedQuestions,
  };

  return updatedForm;
}

export { updateOrderAllQuestions };

