import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Form, Input, InputRef, theme } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { EditableRowProps } from "../../../../types/antd/Table";
import { ExtendedSeshaContact } from "../../ContactModel";

const EditableContext = React.createContext<any | null>(null);

const EditableRow = ({ index, ...props }: { index: number }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}: EditableRowProps<ExtendedSeshaContact>) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext);

  const {
    token: { colorError },
  } = theme.useToken();

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  const isKeyOfContactListTableInterface = (key: any): key is keyof ExtendedSeshaContact => {
    return key in record;
  };

  const toggleEdit = () => {
    setEditing(!editing);
    if (isKeyOfContactListTableInterface(dataIndex)) {
      form.setFieldsValue({
        [dataIndex]: record[dataIndex],
      });
    }
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      if (dataIndex === "email") {
        record.emailIsDuplicate = false;
      }

      handleSave({
        ...record,
        ...values,
      });
      setEditing(false);
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };
  let childNode = children;
  if (editable && isKeyOfContactListTableInterface(dataIndex)) {
    childNode =
      editing ||
      record.isNewlyAdded ||
      record[dataIndex] === undefined ||
      record[dataIndex] === "" ? (
        <Form.Item
          style={{
            margin: 0,
          }}
          name={dataIndex}
          validateStatus={record.isInErrorState && dataIndex !== "mailToBox" ? "error" : undefined}
        >
          <Input
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            style={{
              width: "83%",
            }}
          />
          {record.isInErrorState && dataIndex !== "mailToBox" && (
            <ExclamationCircleOutlined style={{ color: colorError, paddingLeft: "5px" }} />
          )}
        </Form.Item>
      ) : (
        <div
          className={`editable-cell-value-wrap ${
            dataIndex === "email" && record.emailIsDuplicate ? "error" : ""
          }`}
          style={{
            minHeight: 20,
          }}
          onClick={toggleEdit}
        >
          {children}
          {dataIndex === "email" && record.emailIsDuplicate && (
            <ExclamationCircleOutlined style={{ color: colorError }} />
          )}
        </div>
      );
  }
  return <td {...restProps}>{childNode}</td>;
};

export { EditableCell, EditableRow };
