import { LockOutlined } from '@ant-design/icons';
import { Flex, Typography, theme } from "antd";

import {
  FormOutput
} from "@addventa/sesha-forms-api";

const { Title } = Typography;

function ClosedFormAnswers(props: {
  form: FormOutput;
}) {
  const {
    token: { colorPrimary },
  } = theme.useToken();

    return (
      <Flex vertical align="center" justify="center"
        style={{ 
          padding: "20px 80px",
          minHeight: "65vh",
        }}
      >
        <Title level={1}>{props.form.title}</Title>
        <Flex vertical align="center" justify="center"
              style={{ 
                justifyContent: "center",
              }}
        >
          <LockOutlined 
            style={{
              color: colorPrimary,
              fontSize: "10vh",
            }} 
          />
          <Title level={2}>
            Le formulaire a été bloqué par le cabinet.
          </Title>
          <Title level={4}>
            Prenez contact avec votre interlocuteur pour le débloquer.
          </Title>
        </Flex>
      </Flex>
    );
}

export default ClosedFormAnswers;
