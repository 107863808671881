import { AccountingFirmOutput, FormOutput } from "@addventa/sesha-forms-api";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import type { TabsProps } from "antd";
import { Alert, Button, Col, ConfigProvider, Modal, Row, Spin, Tabs } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { apiAccountingFirm } from "../../../api-configuration/Configuration";
import { EFeatures } from "../../../enums/EFeatures";
import { usePermission } from "../../../hooks/usePermission";
import CreateForm from "./CreateForm";
import SendFormParams from "./SendFormParams";

function CreateFormTabs() {
  const [activeTab, setActiveTab] = useState<string>("1");
  const [form, setForm] = useState<FormOutput>();
  const [accountingFirm, setAccountingFirm] = useState<AccountingFirmOutput>();
  const [isModified, setIsModified] = useState<boolean>(false);
  const [leave, setLeave] = useState<string>("");
  const [openWaring, setOpenWarning] = useState<boolean>(false);
  const canSendForm = usePermission(EFeatures.CREATE_AND_SEND_FORM);

  const { id } = useParams<{ id: string }>();

  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const resAcc = await apiAccountingFirm.accountingfirmFindOne();
      setAccountingFirm(resAcc);
    })();
  }, []);

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Votre formulaire",
      children: (
        <CreateForm
          setActiveTab={setActiveTab}
          setForm={setForm}
          accountingFirm={accountingFirm!}
          setIsModified={setIsModified}
        />
      ),
    },
    {
      key: "2",
      label: "Paramétrage et envoi",
      children: <SendFormParams form={form!} accountingFirm={accountingFirm!} />,
      disabled: !canSendForm || !id || !form,
    },
  ];

  const onChange = (key: string) => {
    if (key === "2" && isModified) {
      setLeave("2");
      setOpenWarning(true);
    } else {
      setActiveTab(key);
    }
  };

  useEffect(() => {
    if (!isModified) setLeave("");
  }, [isModified]);

  //TODO : move accountingFirm !== undefined lower in the components
  return (
    <>
      <Button
        type="default"
        style={{ width: "fit-content", marginLeft: "60px" }}
        onClick={() => {
          if (!isModified) navigate("/");
          else {
            setLeave("home");
            setOpenWarning(true);
          }
        }}
      >
        Retour aux formulaires
      </Button>
      <Spin spinning={accountingFirm ? false : true}>
        {accountingFirm !== undefined ? (
          <Row justify="center">
            <h2>Création de formulaire</h2>
            <Col xs={24} sm={22} md={22} lg={22}>
              <Tabs
                defaultActiveKey="1"
                activeKey={activeTab}
                style={{ minHeight: 220 }}
                items={items}
                tabPosition={"left"}
                onChange={onChange}
              />
            </Col>
          </Row>
        ) : null}
      </Spin>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              colorPrimary: "#FF9700",
              colorPrimaryHover: "#FF9700",
            },
          },
        }}
      >
        {" "}
        <Modal
          open={openWaring}
          title={
            <>
              <ExclamationCircleOutlined
                style={{ fontSize: "24px", marginRight: "10px", color: "#FF9700" }}
              />
              Vous n’avez pas enregistré votre formulaire
            </>
          }
          onOk={() => {
            setOpenWarning(false);
          }}
          onCancel={() => {
            setOpenWarning(false);
            if (leave === "home") {
              navigate("/");
            } else {
              setActiveTab("2");
            }
          }}
          maskClosable={false}
          okText="Revenir au formulaire"
          cancelText="Quitter sans enregistrer"
          closable={false}
        >
          <Alert
            message=""
            description="En quittant cette page sans avoir cliqué sur “Enregistrer le brouillon”, vos dernières modifications ne seront pas prises en compte. Pour enregistrer le formulaire, cliquez sur “revenir au formulaire”, puis “enregistrer le brouillon”."
            type="warning"
          />
        </Modal>
      </ConfigProvider>
    </>
  );
}

export default CreateFormTabs;
