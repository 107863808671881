import type { TabsProps } from "antd";
import { Button, Col, Row, Tabs } from "antd";
import { Link } from "react-router-dom";
import ContactCreation from "./components/create/ContactCreation";

import "./Contact.css";

function ContactTabs() {
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Importer des contacts",
      children: <ContactCreation />,
    },
  ];

  return (
    <>
      <Row>
        <Col span={23} offset={1}>
          <Link to="/?tab=contacts">
            <Button>Retour aux contacts</Button>
          </Link>
        </Col>
      </Row>
      <Row>
        <Col span={12} offset={6} style={{ textAlign: "center" }}>
          <h2>Création de contacts</h2>
        </Col>
      </Row>
      <Row>
        <Col xs={24} sm={22} md={22} lg={22}>
          <Tabs style={{ minHeight: 220 }} items={items} tabPosition={"left"} />
        </Col>
      </Row>
    </>
  );
}

export default ContactTabs;
