import { QuestionOutput } from "@addventa/sesha-forms-api";
import { Input } from "antd";
import { Contents, Identified } from "../../../../types/misc/Generic";

const { TextArea } = Input;

function TextareaField(props: { question: Identified<Contents<QuestionOutput>>; mode: string }) {
  return (
    <TextArea
      rows={4}
      disabled={props.mode !== "write"}
      autoSize={props.mode !== "write"}
      placeholder={props.mode !== "write" ? "Entrez votre réponse ici" : ""}
    />
  );
}

export default TextareaField;
