import { Button, Flex, theme } from "antd";

function FormAnswersValidationPanel(props: {
  validable: boolean;
  setValidable: React.Dispatch<React.SetStateAction<boolean>>;
  setInValidationMode: React.Dispatch<React.SetStateAction<boolean>>;
  setValidated: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const {
    token: { colorBgContainer, boxShadow },
  } = theme.useToken();

  // for buttons in review mode
  function editFormAnswers(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();

    // it was already left on the last section when entering validation mode, so it only requires to disable preview mode
    props.setValidable(false);
    props.setInValidationMode(false);
  }

  function validateFormAnswers(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();

    // todo: send answers and/or validation
    props.setInValidationMode(false);
    props.setValidated(true);
  }
  return (
    <Flex
      className="form-answers-validation-panel"
      justify="end"
      wrap="wrap"
      gap="small"
      style={{
        background: colorBgContainer,
        boxShadow: "0px 5px 30px " + boxShadow,
      }}
    >
      <Button type="default" onClick={editFormAnswers}>
        Editer mes réponses
      </Button>
      <Button type="primary" onClick={validateFormAnswers} disabled={!props.validable}>
        Valider et envoyer mes réponses
      </Button>
    </Flex>
  );
}

export default FormAnswersValidationPanel;
