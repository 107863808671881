import React from "react";
import { Contents, Edition, Identified } from "../../../../types/misc/Generic";
import QuestionGlobal from "../question/Question";

import { FormOutput } from "@addventa/sesha-forms-api";

function QuestionItem(props: {
  id: string;
  form: Edition<Identified<Contents<FormOutput>>>;
  setForm: React.Dispatch<React.SetStateAction<Edition<Identified<Contents<FormOutput>>> | undefined>>;
}) {
  const section = props.form.sections!.find((section: any) =>
    section.questions!.some((question: any) => question.tempId === props.id)
  );
  const question = section!.questions!.find((question: any) => question.tempId === props.id);

  return (
    <div style={{ padding: "5px" }}>
      <QuestionGlobal
        key={question!.tempId}
        form={props.form}
        setForm={props.setForm}
        section={section!}
        question={question!}
        isReviewMode={true}
        //dragOverlay={question === activeId ? true : false}
      />
    </div>
  );
}

export default QuestionItem;
