import { UserOutput } from "@addventa/sesha-forms-api";
import { createContext } from "react";
import { Token } from "../types/Token/Token";

type ContextType = {
  user: UserOutput | null;
  updateUser: (arg: UserOutput) => void;
  token: Token | null;
  theme: "light" | "dark";
  setThemeType: (arg: "light" | "dark") => void;
  /*
  leave: boolean;
  setLeave: (isLeaving: boolean) => void;
*/
};

export default createContext<ContextType>({
  user: null,
  updateUser: () => {},
  token: null,
  theme: "light",
  setThemeType: () => {},
  /*
  leave: false,
  setLeave: () => {},
*/
});

/*
type ClientContextType = {
  leave: boolean;
  setLeave: (isLeaving: boolean) => void;
};
export default createContext<ContextType>({
  leave: false,
  setLeave: () => {},
});
*/
