import { ContactOutput } from "@addventa/sesha-forms-api";
import { EditOutlined } from "@ant-design/icons";
import { Button, ConfigProvider, Form, Input, Modal, Select, Tag, theme } from "antd";
import type { CustomTagProps } from "rc-select/lib/BaseSelect";
import { useEffect, useState } from "react";
import { apiContact, apiTag } from "../../../api-configuration/Configuration";
import { useNotification } from "../../../hooks/useNotification";

type Props = {
  contact: ContactOutput | undefined;
  setContactToEdit: React.Dispatch<React.SetStateAction<ContactOutput | undefined>>;
  setReloadList: React.Dispatch<React.SetStateAction<boolean>>;
  reloadList: boolean;
  dataProvider: string;
  fec: boolean;
};

function ContactListModal(props: Props) {
  const {
    token: { colorPrimaryBgHover },
  } = theme.useToken();
  const { showNotification } = useNotification();
  const [form] = Form.useForm();
  const [options, setOptions] = useState<{ label: string; value: string; color: string }[]>([]);

  useEffect(() => {
    (async () => {
      const resTags = await apiTag.tagFindAll();
      const tags = [];
      for (const tag of resTags) {
        tags.push({ label: tag.label, value: tag._id, color: tag.color });
      }
      setOptions(tags);
    })();
  }, [props.reloadList]);

  const onFinishEdit = (values: ContactOutput) => {
    (async () => {
      if (props.contact) {
        try {
          await apiContact.contactUpdateOne(props.contact._id, values);
          showNotification("success", "Contact modifié");
        } catch (error) {
          console.error("onFinishEdit - " + error);
          showNotification("error", "Erreur lors de l'enregistrement");
        }
      }
      props.setContactToEdit(undefined);
      props.setReloadList(true);
    })();
  };

  const tagRender = (props: CustomTagProps) => {
    const { label, closable, onClose } = props;
    const color = options?.find((option) => option.label === label)?.color;

    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={color}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginRight: 3,
          marginLeft: 3,
          marginBottom: 1,
          borderRadius: 8,
          color: "#2F2F2F",
        }}
      >
        {label}
      </Tag>
    );
  };

  useEffect(() => {
    form.resetFields();
  }, [form, props.contact]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            headerBg: colorPrimaryBgHover,
          },
        },
      }}
    >
      <Modal
        title="Modifier les données du contact"
        open={!!props.contact}
        onCancel={() => props.setContactToEdit(undefined)}
        footer={null}
        forceRender
      >
        <Form
          form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          labelAlign="left"
          onFinish={onFinishEdit}
          initialValues={{
            firstName: props.contact?.firstName,
            lastName: props.contact?.lastName,
            email: props.contact?.email,
            tags: props.contact?.tags,
            mailToBox: props.contact?.mailToBox,
            iDepot: props.contact?.iDepot,
          }}
        >
          <Form.Item
            name="firstName"
            label="Prénom"
            rules={[
              {
                required: true,
                message: "Merci de renseigner le prénom",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="lastName"
            label="Nom"
            rules={[
              {
                required: true,
                message: "Merci de renseigner le nom",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="email"
            label="Adresse email"
            rules={[
              {
                required: true,
                message: "Merci de renseigner l'adresse email",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="tags" label="Tag(s)">
            <Select mode="multiple" tagRender={tagRender} allowClear options={options} />
          </Form.Item>
          <Form.Item name="mailToBox" label="Mail to box">
            <Input />
          </Form.Item>
          {props.dataProvider === "ACD" && props.fec === false ? (
            <Form.Item name="iDepot" label="Code dossier i-Dépôt">
              <Input />
            </Form.Item>
          ) : null}
          <Form.Item wrapperCol={{ span: 24 }}>
            <Button type="primary" block htmlType="submit" icon={<EditOutlined />}>
              Mettre à jour
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </ConfigProvider>
  );
}

export default ContactListModal;
