import { FormOutput } from "@addventa/sesha-forms-api";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Alert, ConfigProvider, Modal } from "antd";
import { apiForm, apiFormAnswers } from "../../../../api-configuration/Configuration";
import { ELockType, ExtendedSeshaFormsAnswers } from "../ReviewFormModel.d";

export function YourFormAnswersLockValidationModal(props: {
  form: FormOutput;
  setForm: React.Dispatch<React.SetStateAction<FormOutput | undefined>>;
  formAnswersList: ExtendedSeshaFormsAnswers[];
  setFormAnswersList: React.Dispatch<React.SetStateAction<ExtendedSeshaFormsAnswers[]>>;
  unselectAll?: () => void;
  modalIsOpen: boolean;
  setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  lockType: ELockType;
}) {
  const generateManyPDFs = async () => {
    if (props.form.isGeneratingPDF) return;

    const form = props.form;
    form.createdAt = new Date(form.createdAt);
    form.isGeneratingPDF = true;
    if (form.updatedAt) {
      form.updatedAt = new Date(form.updatedAt);
    }

    apiFormAnswers.formAnswersGenerateManyPDFs(form);
  };

  const lockAllAccess = async () => {
    let copyFormAnswersList = [...props.formAnswersList];
    for (let formAnswer of copyFormAnswersList) {
      formAnswer.closed = true;
    }
    const newForm = JSON.parse(JSON.stringify(props.form));
    newForm.isClosed = true;
    newForm.closeDate = new Date();
    if (newForm.sentAt && !(newForm.sentAt instanceof Date)) {
      newForm.sentAt = new Date(newForm.sentAt);
    }
    props.setForm(newForm);

    generateManyPDFs();

    await apiForm.formUpdateOne(newForm._id, newForm);
    await apiFormAnswers.formAnswersUpdateMany(copyFormAnswersList);
    props.setFormAnswersList(copyFormAnswersList);
  };

  const unlockAccess = async () => {
    let updatedFormAnswers = props.formAnswersList;
    for (const formAnswer of updatedFormAnswers.filter((form) => form.isSelected === true)) {
      updatedFormAnswers.find((form) => form._id === formAnswer._id)!.closed = false;
    }
    await apiFormAnswers.formAnswersUpdateMany(props.formAnswersList);
    props.setFormAnswersList && props.setFormAnswersList(updatedFormAnswers);

    if (props.form.isClosed) {
      const newForm = JSON.parse(JSON.stringify(props.form));
      newForm.isClosed = false;
      if (newForm.closeDate && !(newForm.closeDate instanceof Date)) {
        newForm.closeDate = new Date(newForm.closeDate);
      }
      if (newForm.sentAt && !(newForm.sentAt instanceof Date)) {
        newForm.sentAt = new Date(newForm.sentAt);
      }
      props.setForm(newForm);
      await apiForm.formUpdateOne(newForm._id, newForm);
    }
  };

  const lockAccess = async () => {
    let updatedFormAnswers = props.formAnswersList;
    for (const formAnswer of updatedFormAnswers.filter((form) => form.isSelected === true)) {
      updatedFormAnswers.find((form) => form._id === formAnswer._id)!.closed = true;
      formAnswer.createdAt = new Date(formAnswer.createdAt);
    }
    await apiFormAnswers.formAnswersUpdateMany(props.formAnswersList);
    props.setFormAnswersList(updatedFormAnswers);

    generateManyPDFs();

    if (updatedFormAnswers.every((form) => form.closed === true)) {
      const newForm: FormOutput = JSON.parse(JSON.stringify(props.form));
      newForm.isClosed = true;
      newForm.closeDate = new Date();

      if (newForm.sentAt && !(newForm.sentAt instanceof Date)) {
        newForm.sentAt = new Date(newForm.sentAt);
      }
      props.setForm(newForm);
      await apiForm.formUpdateOne(newForm._id, newForm);
    }
  };

  const modalTitle = () => {
    if (props.lockType === ELockType.LOCK) {
      return "Valider et clôturer le(s) formulaire(s)";
    }
    if (props.lockType === ELockType.UNLOCK) {
      return "Rétablir l'accès au(x) formulaire(s)";
    }
    if (props.lockType === ELockType.LOCKALL) {
      return "Valider et clôturer tous les formulaires";
    }
  };

  const modalContent = () => {
    if (props.lockType === ELockType.LOCK) {
      return "Êtes-vous sur(e) de vouloir clôturer le(s) formulaire(s) sélectionné(s) ?";
    }
    if (props.lockType === ELockType.UNLOCK) {
      return "Êtes-vous sur(e) de vouloir rétablir l'accès au(x) formulaire(s) sélectionné(s) ?";
    }
    if (props.lockType === ELockType.LOCKALL) {
      return "Êtes-vous sur(e) de vouloir clôturer tous les formulaires ?";
    }
  };

  const onFinish = () => {
    if (props.lockType === ELockType.LOCK) {
      lockAccess();
    } else if (props.lockType === ELockType.UNLOCK) {
      unlockAccess();
    } else if (props.lockType === ELockType.LOCKALL) {
      lockAllAccess();
    }
    props.setModalIsOpen(false);
    props.unselectAll && props.unselectAll();
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary: "#FF9700",
            colorPrimaryHover: "#FF9700",
          },
          Modal: {
            headerBg: "#FFF",
          },
        },
      }}
    >
      <Modal
        open={props.modalIsOpen}
        title={
          <>
            <ExclamationCircleOutlined
              style={{ fontSize: "24px", marginRight: "10px", color: "#FF9700" }}
            />
            {modalTitle()}
          </>
        }
        className="modal-confirmation-lock-form"
        onOk={() => onFinish()}
        onCancel={() => props.setModalIsOpen(false)}
        maskClosable={false}
        okText="Valider"
        cancelText="Annuler"
      >
        <Alert message="" description={modalContent()} type="warning" />
      </Modal>
    </ConfigProvider>
  );
}
