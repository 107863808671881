import { Button, Card, Col, Input, Row, theme } from "antd";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import document from "../../../../assets/images/document.png";
import options from "../../../../assets/images/options.png";
import text from "../../../../assets/images/text.png";
import { updateOrderAllQuestions } from "../../../../utils/orderQuestions";

import {
  FormOutput,
  QuestionInputTypeEnum,
  QuestionOutput,
  SectionOutput,
} from "@addventa/sesha-forms-api";
import { CloseOutlined } from "@ant-design/icons";
import { Contents, Edition, Identified } from "../../../../types/misc/Generic";

interface Props {
  form: Edition<Identified<Contents<FormOutput>>>;
  setForm: React.Dispatch<
    React.SetStateAction<Edition<Identified<Contents<FormOutput>>> | undefined>
  >;
  section: Identified<Contents<SectionOutput>>;
  setNewQuestion: React.Dispatch<React.SetStateAction<string | undefined>>;
  setIsModified: (b: boolean) => void;
}

function countQuestions(form: Edition<Identified<Contents<FormOutput>>>) {
  let total = 0;
  form.sections!.forEach((section: Identified<Contents<SectionOutput>>) => {
    total += section.questions!.length;
  });
  return total;
}

function NewQuestion(props: Props) {
  const {
    token: { colorPrimaryBgHover },
  } = theme.useToken();

  const [questionTitle, setQuestionTitle] = useState<string>("Titre de la question");

  const generateNewQuestion = (
    type: QuestionInputTypeEnum
  ): Identified<Contents<QuestionOutput>> => ({
    tempId: uuidv4(),
    type,
    title: questionTitle,
    order: countQuestions(props.form),
    newQuestion: true,
    options: [],
    mandatory: false,
    multiple: false,
    other: false,
  });

  const addQuestionToSection = (type: QuestionInputTypeEnum) => {
    props.setIsModified(true);
    props.setForm((prevForm: Edition<Identified<Contents<FormOutput>>> | undefined) => {
      var updatedForm: Edition<Identified<Contents<FormOutput>>> = { ...prevForm! };
      if (updatedForm.sections) {
        const sectionIndex = updatedForm.sections.findIndex(
          (section: any) => section.tempId === props.section.tempId
        );

        if (sectionIndex !== -1) {
          const section: Identified<Contents<SectionOutput>> = {
            ...updatedForm.sections[sectionIndex],
          };
          const newQuestion: Identified<Contents<QuestionOutput>> = generateNewQuestion(type);

          section.questions = section.questions
            ? [...section.questions, newQuestion]
            : [newQuestion];
          updatedForm.sections[sectionIndex] = section;
        }
      }

      updatedForm = updateOrderAllQuestions(updatedForm);
      return updatedForm;
    });
    props.setNewQuestion(undefined);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuestionTitle(e.target.value);
  };

  return (
    <Card style={{ borderRadius: "20px", boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}>
      {props.section.questions?.length! > 0 ? (
        <Button
          style={{ position: "absolute", right: "20px", top: "8px" }}
          shape="circle"
          type="text"
          icon={<CloseOutlined />}
          onClick={(e) => {
            e.preventDefault();
            props.setNewQuestion(undefined);
          }}
        />
      ) : null}
      <div style={{ marginTop: props.section.questions?.length! > 0 ? "5px" : "0px" }}>
        <span>Nouvelle question</span> <Input onChange={onChange} />
        <p>Mode de réponse</p>
        <Row justify="space-between">
          {[
            QuestionInputTypeEnum.Textarea,
            QuestionInputTypeEnum.Option,
            QuestionInputTypeEnum.Upload,
          ].map((type: QuestionInputTypeEnum) => (
            <Col xs={24} sm={22} md={7} lg={7} key={uuidv4() + "-" + type}>
              <Button
                key={type}
                style={{
                  background: colorPrimaryBgHover,
                  border: 0,
                  fontWeight: 700,
                  height: "80px",
                  width: "170px",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  addQuestionToSection(type);
                }}
              >
                {type === "TEXTAREA" ? (
                  <div style={{ textAlign: "center", paddingTop: "5px" }}>
                    <img src={text} alt="textarea" style={{}} />
                    <span style={{ display: "block", fontSize: "12px" }}>Champ libre</span>
                  </div>
                ) : type === "OPTION" ? (
                  <div style={{ textAlign: "center", paddingTop: "5px" }}>
                    <img src={options} alt="option" style={{}} />
                    <span style={{ display: "block", fontSize: "12px" }}>Case à cocher</span>
                  </div>
                ) : (
                  <div style={{ textAlign: "center", paddingTop: "5px" }}>
                    <img src={document} alt="upload" style={{}} />
                    <span style={{ display: "block", fontSize: "12px" }}>Pièce jointe</span>
                  </div>
                )}
              </Button>
            </Col>
          ))}
        </Row>
      </div>
    </Card>
  );
}

export default NewQuestion;
