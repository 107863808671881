import { FormOutput } from "@addventa/sesha-forms-api";
import { ConfigProvider, Flex, Input, theme } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useState } from "react";
import { Contents, Edition, Identified } from "../../../../types/misc/Generic";
import AllSections from "../../../assets/form/section/AllSections";
import { addTempIds } from "../../create/utils";


interface Props {
  form: FormOutput;
}

function YourForm(props: Props) {
  const {
    token: { colorPrimary, colorText, colorBgContainer },
  } = theme.useToken();
  
  const [reviewedForm] = useState<Edition<Identified<Contents<FormOutput>>>>(addTempIds(props.form));

  return (
    <ConfigProvider
      theme={{
        components: {
          Input: {
            colorBgContainerDisabled: colorBgContainer,
            colorTextDisabled: colorText,
            paddingInline: 20,
          },
          Checkbox: {
            colorBgContainerDisabled: colorBgContainer,
            colorBorder: colorPrimary,
            colorTextDisabled: colorText,
          },
          Upload: {
            colorTextDisabled: colorPrimary,
          },
        },
      }}
    >
      <div className="yourForm">
        <Flex vertical gap={20} style={{ paddingBottom: 50 }}>
          <span>
            <h2>Nom du formulaire</h2>
            <Input disabled value={reviewedForm.title} />
          </span>
          <span>
            <h2>Description du formulaire</h2>
            <TextArea autoSize disabled value={reviewedForm.description} />
          </span>
        </Flex>
        <AllSections form={reviewedForm} setForm={null} isReviewMode={true} />
      </div>
    </ConfigProvider>
  );
}

export default YourForm;
