import { FormOutput, UserFormInput, UserFormOutput, UserOutput } from "@addventa/sesha-forms-api";
import { Checkbox, Input, theme } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import { ChangeEvent, useEffect, useState } from "react";
import { apiForm, apiUserFormAsso } from "../../api-configuration/Configuration";

const { Search } = Input;

const FormSelection = (props: { users: UserOutput[]; selectedUsers: string[] }) => {
  const {
    token: { colorPrimary },
  } = theme.useToken();

  const [forms, setForms] = useState<FormOutput[]>([]);
  const [filteredForms, setFilteredForms] = useState<FormOutput[]>([]);
  const [searchedText, setSearchedText] = useState<string>("");
  const [userFormAsso, setUserFormAsso] = useState<UserFormOutput[]>([]);
  const [reload, setReload] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      setForms(await apiForm.formFindAll());
    })();
  }, []);

  useEffect(() => {
    setFilteredForms(forms);
  }, [forms]);

  useEffect(() => {
    (async () => {
      setUserFormAsso(await apiUserFormAsso.userFormAssoFindAll());
    })();
  }, [reload]);

  const search = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value) {
      setFilteredForms(
        forms.filter((form) => form.title.toLowerCase().includes(e.target.value.toLowerCase()))
      );
    } else {
      setFilteredForms(forms);
    }
    setSearchedText(e.target.value);
  };

  const onChange = async (
    e: CheckboxChangeEvent,
    isPreviousStateIndeterminate: boolean,
    formId: string,
    usersWithAccess: string[]
  ) => {
    if (e.target.checked && !isPreviousStateIndeterminate) {
      const userFormsToAdd: UserFormInput[] = props.selectedUsers.map((userId) => ({
        formId,
        userId,
      }));
      await apiUserFormAsso.userFormAssoCreateMany(userFormsToAdd);
    } else if (e.target.checked && isPreviousStateIndeterminate) {
      const userFormsToAdd: UserFormInput[] = props.selectedUsers
        .filter((el) => !usersWithAccess.includes(el))
        .map((userId) => ({ formId, userId }));
      await apiUserFormAsso.userFormAssoCreateMany(userFormsToAdd);
    } else {
      const idsToDelete: string[] = userFormAsso
        .filter((el) => el.formId === formId && props.selectedUsers.includes(el.userId))
        .map((el) => el._id);
      await apiUserFormAsso.userFormAssoDeleteMany(idsToDelete);
    }
    setReload(!reload);
  };

  const selectAll = async () => {
    const userFormsToAdd: UserFormInput[] = props.selectedUsers
      .map((userId) => filteredForms.map((form) => ({ userId, formId: form._id })))
      .flat(1)
      .filter(
        (el) =>
          !userFormAsso.some(({ formId, userId }) => el.formId === formId && el.userId === userId)
      );
    await apiUserFormAsso.userFormAssoCreateMany(userFormsToAdd);
    setReload(!reload);
  };

  const unselectAll = async () => {
    const idsToDelete: string[] = userFormAsso
      .filter((el) => filteredForms.some(({ _id }) => el.formId === _id))
      .filter((el) => props.selectedUsers.includes(el.userId))
      .map(({ _id }) => _id);
    await apiUserFormAsso.userFormAssoDeleteMany(idsToDelete);
    setReload(!reload);
  };

  return (
    <div
      style={{
        borderRadius: "34px",
        boxShadow: "2px 6px 10.1px 1px rgba(0, 0, 0, 0.15)",
        padding: "20px",
      }}
    >
      {props.selectedUsers.length && userFormAsso.length ? (
        <>
          <Search
            placeholder="Rechercher"
            defaultValue={searchedText}
            onChange={search}
            enterButton
            allowClear
            style={{ paddingBottom: "15px" }}
          />
          <div style={{ maxHeight: "500px", overflowY: "auto" }}>
            {filteredForms.map((form) => {
              const filteredFormUserAsso: UserFormOutput[] = userFormAsso.filter(
                (el) => el.formId === form._id
              );
              const areEveryUsersInForm: boolean = props.selectedUsers.every((userId) =>
                filteredFormUserAsso.some((el) => el.userId === userId)
              );
              const isStateIndeterminate: boolean =
                !areEveryUsersInForm &&
                props.selectedUsers.some((userId) =>
                  filteredFormUserAsso.some((el) => el.userId === userId)
                );
              const usersWithAccess: string[] = props.selectedUsers.filter((userId) =>
                filteredFormUserAsso.some((el) => el.userId === userId)
              );
              return (
                <div key={form._id}>
                  <Checkbox
                    onChange={(e) => onChange(e, isStateIndeterminate, form._id, usersWithAccess)}
                    checked={areEveryUsersInForm}
                    indeterminate={isStateIndeterminate}
                    style={{ paddingBottom: "2px" }}
                  >
                    {form.title}
                  </Checkbox>
                  <br />
                </div>
              );
            })}
          </div>
          <div style={{ paddingTop: "15px", color: colorPrimary }}>
            <a
              style={{ color: colorPrimary }}
              onMouseEnter={(e) => (e.currentTarget.style.textDecoration = "underline")}
              onMouseLeave={(e) => (e.currentTarget.style.textDecoration = "none")}
              onClick={selectAll}
            >
              Tout sélectionner
            </a>{" "}
            /{" "}
            <a
              style={{ color: colorPrimary }}
              onMouseEnter={(e) => (e.currentTarget.style.textDecoration = "underline")}
              onMouseLeave={(e) => (e.currentTarget.style.textDecoration = "none")}
              onClick={unselectAll}
            >
              Tout désélectionner
            </a>
          </div>
        </>
      ) : (
        <p>Pour commencer, veuillez choisir un ou plusieurs utilisateurs dans le menu à gauche.</p>
      )}
    </div>
  );
};

export default FormSelection;
