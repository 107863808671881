import { Button, Col, Layout, Row, Switch, theme } from "antd";
import { useContext, useEffect, useState } from "react";
import { getFirmLogo } from "../../api/API";
import Context from "../../context/Context";

const { Header } = Layout;

const NavClient = (props: {
  setDisconnected: React.Dispatch<React.SetStateAction<boolean>>;
  displayBtnDisco: boolean;
  setDisplayBtnDisco: React.Dispatch<React.SetStateAction<boolean>>;
  logoFirmId: string;
}) => {
  const { theme: themeType, setThemeType } = useContext(Context);

  const {
    token: { colorBgContainer, boxShadow },
  } = theme.useToken();

  const [isLogoLoad, setIsLogoLoad] = useState<boolean>(false);
  const [logo, setLogo] = useState<any>();

  useEffect(() => {
    (async () => {
      if (props.logoFirmId !== "" && !isLogoLoad) {
        try {
          const resLogo = await getFirmLogo(props.logoFirmId);
          setLogo(resLogo);
          setIsLogoLoad(true);
        } catch (error) {
          console.log("LOAD LOGO FAILED: " + error);
        }
      }
    })();
  }, [props.logoFirmId, isLogoLoad]);

  return (
    <Header
      className="header-nav-client"
      style={{
        background: colorBgContainer,
        boxShadow: "0px 5px 30px " + boxShadow,
      }}
    >
      <Row
        style={{
          justifyContent: "space-between",
        }}
      >
        <Col span={8}>
          <div style={{ marginTop: "7px" }}>
            {isLogoLoad && (
              <img
                src={URL.createObjectURL(logo)}
                alt=""
                style={{ maxWidth: "150px", maxHeight: "80px" }}
              />
            )}
            {(process.env.REACT_APP_ENV === "dev" || process.env.REACT_APP_ENV === "local") && (
              <Switch
                className="switch-theme"
                defaultChecked={themeType === "dark"}
                onChange={(checked) => setThemeType(checked ? "dark" : "light")}
                style={{ marginLeft: "10px", marginBottom: "11px" }}
              />
            )}
          </div>
        </Col>
        <Col>
          {props.displayBtnDisco ? (
            <Button
              onClick={() => {
                props.setDisconnected(true);
                props.setDisplayBtnDisco(false);
                sessionStorage.clear();
              }}
            >
              <span id="responsive-element-display-none">Enregistrer et&nbsp;</span>Quitter
            </Button>
          ) : (
            <></>
          )}
        </Col>
      </Row>
    </Header>
  );
};

export default NavClient;
