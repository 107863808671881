import {
  FormOutput,
  OptionOutput,
  QuestionOutput,
  SectionOutput
} from "@addventa/sesha-forms-api";
import { CloseOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Flex, Select } from "antd";
import { useEffect, useState } from "react";
import { EditionRule } from "../../../../types/SeshaForm/SeshaRule";
import { Contents, Edition, Identified } from "../../../../types/misc/Generic";
import { addTempId } from "../../../form/create/utils";

type SelectOption = { value: string; label: JSX.Element };

function SectionRule(props: {
  form: Edition<Identified<Contents<FormOutput>>>;
  setForm: React.Dispatch<React.SetStateAction<Edition<Identified<Contents<FormOutput>>> | undefined>>;
  section: Identified<Contents<SectionOutput>>;
  rule: Identified<Contents<EditionRule>>;
  rules: Identified<Contents<EditionRule>>[];
  setRules: React.Dispatch<React.SetStateAction<Identified<Contents<EditionRule>>[]>>;
  potentialTriggerQuestions: Identified<Contents<QuestionOutput>>[];
  isReviewMode: boolean | undefined;
}) {
  const [selectedQuestion, setSelectedQuestion] = useState<string>("");

  const [possibleSections, setPossibleSection] = useState<Identified<Contents<SectionOutput>>[]>([]);
  const [possibleQuestions, setPossibleQuestions] = useState<Identified<Contents<QuestionOutput>>[]>([]);
  const [invalidQuestions, setInvalidQuestions] = useState<Identified<Contents<QuestionOutput>>[]>([]);
  const [questionsToSelect, setQuestionsToSelect] = useState<SelectOption[]>([]);

  const deleteRule = (id: string) => {
    props.setRules(props.rules.filter((rule) => rule.tempId !== id));
  };
  /*
  props.rule.conditions.reduce<Identified<Contents<SectionOutput>>>( 
      (otherSections, condition) => otherSections.concat(props.form.sections!.filter((s) => s.questions!.find( q => condition.questionTempId === q.tempId))), []);
  */
  useEffect(() => {
    let newPossibleSections = props.form.sections!.filter((s) => s.order < props.section.order)
    setPossibleSection(newPossibleSections);
  }, [props.form]);
  
  useEffect(() => {
    setPossibleQuestions(props.potentialTriggerQuestions.filter( potentialTrigger => possibleSections.find( s => s.questions!.find( q => potentialTrigger.tempId === q.tempId))))
    setInvalidQuestions(props.potentialTriggerQuestions.filter( potentialTrigger => !possibleSections.find( s => s.questions!.find( q => potentialTrigger.tempId === q.tempId))))
  }, [possibleSections]);
  
  useEffect(() => {
    setQuestionsToSelect(
        possibleQuestions
        .concat(invalidQuestions)
        .map((question) => ({
          value: question.tempId,
          label: questionSelectedRender(question),
        })))
  }, [invalidQuestions, possibleQuestions]);


  const updateRuleQuestion = (value: string, idRule: string) => {
    setSelectedQuestion(value);
    var questionTempId: string = "";
    props.form.sections!.forEach((section: Identified<Contents<SectionOutput>>) => {
      section.questions!.forEach((question: Identified<Contents<QuestionOutput>>) => {
        if (question.tempId === value) {
          questionTempId = question.tempId;
        }
      });
    });

    props.setRules((prevArray) => {
      return prevArray.map((rule) => {
        if (rule.tempId === idRule) {
          // if we ever want actually several conditions, we should keep track of the condition modified to find it and maj the right one, so far only initializing one condition is ok
          if(rule.conditions.length != 0) return { ...rule, conditions: rule.conditions.map( condition => ({ ...condition, questionTempId: questionTempId }) ) };
          else return { ...rule, conditions: rule.conditions.concat([addTempId({questionTempId})]) };
        }
        return rule;
      });
    });
  };

  const updateRuleOption = (value: string, idRule: string, selectedQuestion: string) => {
    const optionId = props.potentialTriggerQuestions
      .find((question) => question.tempId === selectedQuestion)!
      .options?.find(
        (option: Identified<Contents<OptionOutput>>) => option.value === value
      )?.tempId;

    props.setRules((prevArray) => {
      return prevArray.map((rule) => {
        if (rule.tempId === idRule) {
          // if we ever want actually several conditions, we should keep track of the condition modified to find it and maj the right one, so far only initializing one condition is ok
          if(rule.conditions.length != 0) return { ...rule, conditions: rule.conditions.map( condition => ({ ...condition, valueTempId: optionId }) ) };
          else return { ...rule, conditions: rule.conditions.concat([addTempId({value: optionId})]) };
        }
        return rule;
      });
    });
  };

  function checkQuestionValidityForRule(question: Identified<Contents<QuestionOutput>> )  : boolean {
    let sectionOfQuestion = props.form.sections!.find( s => s.questions!.find( q => q.tempId === question.tempId))
    return possibleSections.find((section) => section.tempId === sectionOfQuestion?.tempId) === undefined;
  }

  function questionSelectedRender(question: Identified<Contents<QuestionOutput>> ) : JSX.Element {
    return ( <Flex>
      {checkQuestionValidityForRule(question) && (
        <ExclamationCircleOutlined style={{ color: "red", marginRight: "4%" }} />
      )}{" "}
      {question.title}
    </Flex> 
    );
  }

  return <div
    key={props.rule.tempId}
    style={{ marginTop: "10px", marginBottom: props.isReviewMode ? "20px" : "10px" }}
  >
    <span>Si</span>
    <Select
      disabled={props.isReviewMode}
      style={{ width: 175, marginLeft: "10px", marginRight: "10px" }}
      options={questionsToSelect
        /*
        props.potentialTriggerQuestions?.map((question) => ({
        value: question.tempId,
        label: questionSelectedRender(question),
      }))
    */}
      value={
        props.potentialTriggerQuestions?.find((question) => props.rule.conditions.find( condition => question.tempId === condition.questionTempId))?.tempId
      }
      onChange={(values) => updateRuleQuestion(values, props.rule.tempId)}
    />
    <span>est</span>
    <Select
      disabled={props.isReviewMode}
      style={{ width: 175, marginLeft: "10px", marginRight: "10px" }}
      value={
        props.potentialTriggerQuestions
          ?.find((question) => props.rule.conditions.find( condition => question.tempId === condition.questionTempId))
          ?.options?.find(
            (option: Identified<Contents<OptionOutput>>) => props.rule.conditions.find( condition => option.tempId === condition.valueTempId)
          )?.value
      }
      options={
        // it's either a new question selected
        props.potentialTriggerQuestions?.find((question) => question.tempId === selectedQuestion)?.options ||
        // or the rule having its question saved
        props.potentialTriggerQuestions?.find((question) => props.rule.conditions.find( condition => question.tempId === condition.questionTempId))?.options
      }
      onChange={(values) =>
        props.rule.conditions.find( condition => condition.questionTempId)
          ? updateRuleOption(
              values,
              props.rule.tempId,
              props.potentialTriggerQuestions?.find((question) => props.rule.conditions.find( condition => question.tempId === condition.questionTempId))
                ?.tempId!
            )
          : updateRuleOption(values, props.rule.tempId, selectedQuestion)
      }
    />
    {!props.isReviewMode ? (
      <Button
        type="text"
        icon={<CloseOutlined />}
        onClick={() => deleteRule(props.rule.tempId)}
      />
    ) : null}
  </div>
        
}

export default SectionRule;
