import { CloseCircleFilled } from "@ant-design/icons";
import { notification, theme } from "antd";
import { ArgsProps } from "antd/es/notification";
import React, { ReactNode, createContext, useContext, useMemo } from "react";

type NotificationStatus = "success" | "error";
type NotificationContextType = {
  showNotification: (status: NotificationStatus, message: string) => void;
};
type NotificationProviderProps = { children: ReactNode };

const NotificationContext = createContext<NotificationContextType | undefined>(undefined);

const useNotification = () => {
  const context = useContext(NotificationContext);

  if (!context) {
    throw new Error(
      "useNotification doit être utilisé à l'intérieur du fournisseur NotificationProvider"
    );
  }
  return context;
};

const NotificationProvider: React.FC<NotificationProviderProps> = ({ children }) => {
  const {
    token: { colorPrimary },
  } = theme.useToken();
  const [api, contextHolder] = notification.useNotification({
    stack: { threshold: 3 },
  });

  const showNotification = (status: NotificationStatus, message: string) => {
    const config: ArgsProps = {
      message: message,
      placement: "bottomRight",
      duration: 15,
      closeIcon: <CloseCircleFilled style={{ color: colorPrimary }} />,
    };

    switch (status) {
      case "success":
        api.success(config);
        break;
      case "error":
        api.error(config);
        break;
      default:
        break;
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const contextValue = useMemo(() => ({ showNotification }), []);

  return (
    <NotificationContext.Provider value={contextValue}>
      {contextHolder}
      {children}
    </NotificationContext.Provider>
  );
};

export { NotificationProvider, useNotification };
