import { ConfigProvider, Flex, theme } from "antd";

import {
  AnswerOutput,
  FormAnswersOutput,
  FormOutput,
  QuestionOutput,
  SectionOutput,
} from "@addventa/sesha-forms-api";
import DisplayQuestion from "../question/DisplayQuestion";
/**
 * Display all given sections. It doesn't filter itself the valid sections, in regards of rules
 * @param props
 * @returns
 */
function DisplayAllSections(props: {
  form: FormOutput;
  formAnswers: FormAnswersOutput;
  sections: SectionOutput[];
  unansweredQuestionsAreDisplayed?: boolean;
}) {
  const {
    token: { colorPrimaryBgHover, colorText, colorPrimary },
  } = theme.useToken();

  const sectionFilter = (section: SectionOutput) => {
    const questionsWithAnswersInSection = section.questions?.filter((question) => {
      return props.formAnswers.answers.some(
        (answer: AnswerOutput) => answer.questionId === question._id && answer.value.length > 0
      );
    });

    if (!props.unansweredQuestionsAreDisplayed && questionsWithAnswersInSection?.length === 0) {
      return <></>;
    }

    return (
      <h3
        style={{
          backgroundColor: colorPrimaryBgHover,
          padding: 20,
          width: "calc(100% - 40px)",
          maxWidth: 640,
          borderRadius: 10,
          boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
          margin: 0,
        }}
      >
        {section.title}
      </h3>
    );
  };

  const questionFilter = (section: SectionOutput, question: QuestionOutput) => {
    const answersInQuestion = (props.formAnswers.answers || []).filter(
      (answer: AnswerOutput) => answer.questionId === question._id && answer.value.length > 0
    );
    if (!props.unansweredQuestionsAreDisplayed && answersInQuestion.length === 0) {
      return <></>;
    }

    return (
      <Flex vertical key={question._id} style={{ width: "100%" }}>
        <DisplayQuestion
          form={props.form}
          accountingFirmId={props.form.accountingFirmId}
          section={section}
          formAnswers={props.formAnswers}
          question={question}
          answer={
            (props.formAnswers.answers || []).filter(
              (answer: AnswerOutput) => answer.questionId === question._id
            )[0]
          }
          currentSectionRules={[]}
          setTriggeredRule={null}
          mandatoryWarning={false}
          isInReviewMode={true}
        />
      </Flex>
    );
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Input: {
            colorTextDisabled: colorText,
            paddingInline: 20,
          },
          Checkbox: {
            colorTextDisabled: colorText,
          },
          Upload: {
            colorTextDisabled: colorPrimary,
          },
        },
      }}
    >
      {props.sections.map((section) => (
        <Flex
          vertical
          align="start"
          justify="center"
          style={{ maxWidth: 600 }}
          gap={30}
          key={section._id}
        >
          {sectionFilter(section)}
          {section
            .questions!.sort((q1: QuestionOutput, q2: QuestionOutput) => q1.order - q2.order)
            .map((question: QuestionOutput) => questionFilter(section, question))}
        </Flex>
      ))}
    </ConfigProvider>
  );
}

export default DisplayAllSections;
