import { Checkbox, ConfigProvider, Flex, Input, Radio, RadioChangeEvent, Space, theme } from "antd";
import { useEffect, useState } from "react";

import {
  AnswerOutput,
  FormAnswersOutput,
  FormOutput,
  OptionOutput,
  QuestionOutput,
  RuleOutput,
  SectionOutput,
} from "@addventa/sesha-forms-api";
import { apiAnswer, apiFormAnswers } from "../../../../api-configuration/Configuration";
import { completionTracking, navigationTracking } from "../utils";

const otherValue = "autre";

function onlyCheckedChoices(choices: OptionOutput[], checkedChoices: string[]): OptionOutput[] {
  return choices.filter((option: OptionOutput) => checkedChoices.includes(option.value));
}

function DisplayOptionsField(props: {
  formAnswers: FormAnswersOutput;
  form: FormOutput;
  section: SectionOutput;
  question: QuestionOutput;
  answer: AnswerOutput;
  currentSectionRules: RuleOutput[];
  setTriggeredRule: React.Dispatch<React.SetStateAction<boolean>> | null;
  mandatoryWarning: boolean;
  isInReviewMode?: boolean;
}) {
  const {
    token: { colorBorderSecondary, colorError, colorPrimary },
  } = theme.useToken();

  const [options] = useState<OptionOutput[]>(props.question.options!);
  const [multiple] = useState<boolean>(props.question.multiple);
  const [other] = useState<boolean>(props.question.other);
  const [otherText, setOtherText] = useState<string>("");

  const [checked, setChecked] = useState<string[]>([]);

  useEffect(() => {
    // retrieve corresponding check values, we can assume an option exists for each possible choice
    setChecked(
      props.answer.value.map((val) =>
        val.split(":")[0] === otherValue ? val : options.find((o) => o._id === val)!.value
      )
    );

    if (props.answer.value.length > 0) {
      setOtherText(
        props.answer.value.find((val) => val.split(":")[0] === otherValue)?.split(":")[1] || ""
      );
    }
  }, [options, props.answer]);

  async function onAnyGroupChange(checkedValues: string[]) {
    return new Promise(async (_, reject) => {
      setChecked(checkedValues);
      // retrieve corresponding, we can assume an option exists for each possible choice

      const answerValues = checkedValues.map((val: string) =>
        val.split(":")[0] === otherValue
          ? otherValue + ":" + otherText
          : options.find((o) => o.value === val)!._id
      );
      props.answer.value = answerValues;
      // update answer
      try {
        await apiAnswer.answersUpdateOne(props.formAnswers._id, props.answer);

        navigationTracking(props.formAnswers, props.form, props.section, props.question);
        completionTracking(props.formAnswers, props.form, props.section, props.question);
        // save progression and completion
        let updatedFormAnswers = { ...props.formAnswers, lastModifiedDate: new Date() };
        await apiFormAnswers.formAnswersUpdateOne(props.formAnswers._id, updatedFormAnswers);
        //        if(props.currentSectionRules.some( r => props.answer.value.includes(r.value)) ) props.setTriggeredRule(true);

        // check rules for value that would triggered a rule for a section to re evaluate progress
        if (
          props.setTriggeredRule &&
          props.currentSectionRules.some((r) =>
            r.conditions.find((c) => c.questionId === props.question._id)
          )
        )
          props.setTriggeredRule(true);
      } catch (error: any) {
        reject(error);
      }
    });
  }
  async function onOtherTextChange(value: string) {
    let otherIndex = props.answer.value.findIndex(
      (val: string) => val.split(":")[0] === otherValue
    );
    if (otherIndex !== -1) {
      props.answer.value[otherIndex] = otherValue + ":" + value;

      await apiAnswer.answersUpdateOne(props.formAnswers._id, props.answer);
      navigationTracking(props.formAnswers, props.form, props.section, props.question);
      completionTracking(props.formAnswers, props.form, props.section, props.question);
      // save progression and completion
      let updatedFormAnswers = { ...props.formAnswers, lastModifiedDate: new Date() };
      await apiFormAnswers.formAnswersUpdateOne(props.formAnswers._id, updatedFormAnswers);
    }
  }

  function onCheckBoxGroupChange(checkedValues: (string | number | boolean)[]) {
    onAnyGroupChange(checkedValues.map((v) => v.toString()));
  }

  function onRadioGroupChange(e: RadioChangeEvent) {
    onAnyGroupChange([e.target.value + ""]);
  }

  const handleClickOption = (value: string) => {
    if (value === checked[0]) {
      // Deselect if the same radio button is clicked again
      onAnyGroupChange([]);
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Checkbox: {
            colorBorder: props.isInReviewMode
              ? "transparent"
              : props.mandatoryWarning &&
                props.question.mandatory &&
                props.answer.value.length === 0
              ? colorError
              : colorPrimary,
            colorBgContainerDisabled: colorBorderSecondary,
          },
          Radio: {
            colorBorder: props.isInReviewMode
              ? "transparent"
              : props.mandatoryWarning &&
                props.question.mandatory &&
                props.answer.value.length === 0
              ? colorError
              : colorPrimary,
            colorBgContainerDisabled: colorBorderSecondary,
            colorTextDisabled: "#2F2F2F",
          },
        },
      }}
    >
      {multiple ? (
        <Checkbox.Group
          value={checked}
          onChange={onCheckBoxGroupChange}
          disabled={props.isInReviewMode}
        >
          <Space direction="vertical">
            {(props.isInReviewMode ? onlyCheckedChoices(options, checked) : options)?.map(
              (option, index) => (
                <Checkbox key={option._id} value={option.value}>
                  {option.label}
                </Checkbox>
              )
            )}
            {other &&
            (!props.isInReviewMode || checked.some((val) => val.split(":")[0] === otherValue)) ? (
              <>
                <Flex wrap="wrap" align="center" gap="small">
                  <Flex gap="small">
                    <Checkbox
                      value={checked.find((val) => val.split(":")[0] === otherValue) || otherValue}
                    />
                    <p style={{ textTransform: "capitalize" }}>{otherValue} :</p>
                  </Flex>
                  <Input
                    style={{ width: "200px", marginLeft: "10px" }}
                    value={otherText}
                    onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                      e.preventDefault();
                      onOtherTextChange(e.target.value);
                    }}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      e.preventDefault();
                      setOtherText(e.target.value);
                    }}
                    disabled={props.isInReviewMode}
                  />
                </Flex>
              </>
            ) : null}
          </Space>
        </Checkbox.Group>
      ) : (
        <Radio.Group
          value={checked[0]}
          onChange={onRadioGroupChange}
          disabled={props.isInReviewMode}
        >
          <Space direction="vertical">
            {(props.isInReviewMode ? onlyCheckedChoices(options, checked) : options)?.map(
              (option: OptionOutput) => (
                <Radio
                  key={option._id}
                  value={option.value}
                  onClick={() => handleClickOption(option.value)}
                >
                  {option.label}
                </Radio>
              )
            )}
            {other &&
            (!props.isInReviewMode || checked.some((val) => val.split(":")[0] === otherValue)) ? (
              <Flex wrap="wrap" align="center" gap="small">
                <Flex gap="small">
                  <Radio
                    onClick={() =>
                      handleClickOption(
                        checked.find((val) => val.split(":")[0] === otherValue) || otherValue
                      )
                    }
                    id="radioButton-autre"
                    value={checked.find((val) => val.split(":")[0] === otherValue) || otherValue}
                    style={{ textTransform: "capitalize" }}
                  >
                    {otherValue} :
                  </Radio>
                </Flex>
                <Input
                  disabled={props.isInReviewMode}
                  style={{ width: "200px", marginLeft: "10px" }}
                  value={otherText}
                  onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
                    e.preventDefault();
                    onOtherTextChange(e.target.value);
                  }}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    e.preventDefault();
                    setOtherText(e.target.value);
                  }}
                />
              </Flex>
            ) : null}
          </Space>
        </Radio.Group>
      )}
    </ConfigProvider>
  );
}

export default DisplayOptionsField;
