import {
  FormOutput,
  OptionOutput,
  QuestionOutput,
  SectionOutput
} from "@addventa/sesha-forms-api";
import { CloseOutlined, PlusCircleFilled } from "@ant-design/icons";
import { Button, Checkbox, ConfigProvider, Radio, Space, Switch, theme } from "antd";
import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { EditionRule } from "../../../../types/SeshaForm/SeshaRule";
import { Contents, Edition, Identified } from "../../../../types/misc/Generic";
import OneOptionField from "./OneOptionField";

function OptionsField(props: {
  setQuestion: React.Dispatch<React.SetStateAction<Identified<Contents<QuestionOutput>>>>;
  question: Identified<Contents<QuestionOutput>>;
  questionRules: Identified<Contents<EditionRule>>[];
  setQuestionRules: React.Dispatch<React.SetStateAction<Identified<Contents<EditionRule>>[]>>;
  section: Identified<Contents<SectionOutput>>;
  form: Edition<Identified<Contents<FormOutput>>>;
  mode: string;
  cancelEdit: boolean;
}) {
  const {
    token: { colorPrimary, colorText },
  } = theme.useToken();

  //const initialCount: number = 0;

  const [options, setOptions] = useState<Identified<Contents<OptionOutput>>[]>([]);
  const [multiple, setMultiple] = useState<boolean>(true);
  const [other, setOther] = useState<boolean>(false);

  useEffect(() => {
    if (props.question.options) {
      if (props.question.options!.length == 0) addNewOption();
      else setOptions(props.question!.options);
    }
    setMultiple(props.question.multiple);
    setOther(props.question.other);
  }, []);

  useEffect(() => {
    if (props.cancelEdit) {
      if (props.question.options) setOptions(props.question.options);
      if (props.question.multiple) setMultiple(multiple);
      if (props.question.other) setOther(props.question.other);
    }
  }, [props.cancelEdit]);

  const addNewOption = () => {
    const id = uuidv4();
    const newCheckbox = {
      label: "",
      value: (options.length + 1).toString(),
      tempId: id,
    };
    setOptions([...options, newCheckbox]);
  };

  useEffect(() => {
    props.setQuestion({
      ...props.question,
      options,
    });
  }, [options]);

  useEffect(() => {
    props.setQuestion({
      ...props.question,
      other,
    });
  }, [other]);

  const onChangeMultiple = (checked: boolean) => {
    if (checked) {
      props.setQuestion({ ...props.question, multiple: false });
      setMultiple(false);
    } else {
      props.setQuestion({ ...props.question, multiple: true });
      setMultiple(true);
    }
  };

  return (
    <>
      <ConfigProvider
        theme={{
          components: {
            Checkbox: {
              colorBorder: colorPrimary,
              colorBgContainerDisabled: "white",
              colorTextDisabled: colorText,
            },
            Radio: {
              colorBorder: colorPrimary,
              colorBgContainerDisabled: "white",
              colorTextDisabled: colorText,
            },
          },
        }}
      >
        {multiple ? (
          <Checkbox.Group disabled={true}>
            <Space direction="vertical">
              {options?.map((option) => (
                <div key={option.tempId}>
                  <Space direction="horizontal">
                    <Checkbox value={option.value}>
                      <OneOptionField
                        option={option}
                        allOptions={options}
                        setAllOptions={setOptions}
                        question={props.question}
                        questionRules={props.questionRules}
                        setQuestionRules={props.setQuestionRules}
                        section={props.section}
                        form={props.form}
                        other={other}
                        multiple={multiple}
                        mode={props.mode}
                        cancelEdit={props.cancelEdit}
                      />
                    </Checkbox>
                  </Space>
                </div>
              ))}
              {other ? (
                <Checkbox value={"autre"}>
                  Autre...{" "}
                  {props.mode === "edit" ? (
                    <Button
                      type="text"
                      icon={<CloseOutlined />}
                      onClick={(e) => {
                        e.preventDefault();
                        setOther(false);
                      }}
                    />
                  ) : null}
                </Checkbox>
              ) : null}
            </Space>
          </Checkbox.Group>
        ) : (
          <Radio.Group disabled={true}>
            <Space direction="vertical">
              {options?.map((option) => (
                <div key={option.tempId}>
                  <Radio value={option.value}>
                    <OneOptionField
                      option={option}
                      allOptions={options}
                      setAllOptions={setOptions}
                      question={props.question}
                      questionRules={props.questionRules}
                      setQuestionRules={props.setQuestionRules}
                      section={props.section}
                      form={props.form}
                      other={other}
                      multiple={multiple}
                      mode={props.mode}
                      cancelEdit={props.cancelEdit}
                    />
                  </Radio>
                </div>
              ))}
              {other ? (
                <Radio value={"autre"}>
                  Autre...{" "}
                  {props.mode === "edit" ? (
                    <Button
                      type="text"
                      icon={<CloseOutlined />}
                      onClick={(e) => {
                        e.preventDefault();
                        setOther(false);
                      }}
                    />
                  ) : null}
                </Radio>
              ) : null}
            </Space>
          </Radio.Group>
        )}

        <>
          {props.mode === "edit" ? (
            <div style={{ marginTop: "10px" }}>
              <div>
                <Button type="text" onClick={addNewOption}>
                  <PlusCircleFilled />{" "}
                  <span style={{ fontWeight: "bold" }}>Ajouter une option</span>
                </Button>
                <span>ou</span>
                <Button
                  type="text"
                  onClick={(e) => {
                    e.preventDefault();
                    setOther(true);
                  }}
                >
                  <span style={{ fontWeight: "bold", color: colorPrimary }}>ajouter "Autre"</span>
                </Button>
              </div>
              <div style={{ marginTop: "20px" }}>
                <span>Restreindre à une réponse unique</span>
                <Switch
                  size="small"
                  value={!multiple}
                  onChange={onChangeMultiple}
                  style={{ marginLeft: "10px" }}
                />
              </div>
            </div>
          ) : null}
        </>
      </ConfigProvider>
    </>
  );
}

export default OptionsField;
