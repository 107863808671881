import {
  AnswerFile,
  AnswerOutput,
  FileSettingsOutput,
  FormAnswersOutput,
  FormOutput,
  QuestionOutput,
  SectionOutput,
} from "@addventa/sesha-forms-api";
import { UploadOutlined } from "@ant-design/icons";
import { Flex, Upload, UploadFile, message, theme } from "antd";
import { UploadChangeParam } from "antd/es/upload";
import { Buffer } from "buffer";
import { RcFile, UploadRequestOption } from "rc-upload/lib/interface";
import { useEffect, useState } from "react";
import {
  apiAccountingFirm,
  apiAnswer,
  apiContact,
  apiFormAnswers,
} from "../../../../api-configuration/Configuration";
import { getIDepotOnForms, sendToIDepot, sendToMailToBox } from "../../../../api/API";
import { objectid } from "../../../../types/misc/MongoDB";
import { completionTracking, navigationTracking } from "../utils";

import "./DisplayUploadField.css";

const { Dragger } = Upload;

const options = [
  { label: ".png", value: "image/png" },
  { label: ".jpeg", value: "image/jpeg" },
  { label: ".jpg", value: "image/jpg" },
  { label: ".pdf", value: "application/pdf" },
  { label: ".txt", value: "text/plain" },
  { label: ".csv", value: "text/csv" },
  {
    label: ".docx",
    value: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  },
  { label: ".doc", value: "application/msword" },
  { label: ".xls", value: "application/vnd.ms-excel" },
  { label: ".xlsx", value: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" },
  { label: ".ofx", value: "application/x-ofx" },
  { label: ".json", value: "application/json" },
];

function createAndDownloadBlobFile(body: string, filename: string, type: string) {
  const blob = new Blob([Buffer.from(body, "base64")], { type: type });
  const fileName = `${filename}`;

  const link = document.createElement("a");
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

function DisplayUploadField(props: {
  accountingFirmId: objectid;
  formAnswers: FormAnswersOutput;
  form: FormOutput;
  section: SectionOutput;
  question: QuestionOutput;
  answer: AnswerOutput;
  mandatoryWarning: boolean;
  isInReviewMode?: boolean;
}) {
  const {
    token: { colorTextTertiary, colorBgBase, colorError, colorBorder, colorTextQuaternary },
  } = theme.useToken();

  function checkFileListForVisibleDragZone(list: UploadFile<any>[]): boolean {
    return list.length === 0;
  }

  const [fileList, setFileList] = useState<UploadFile<any>[]>(
    props.answer.value.map((fileName, i) => ({
      uid: fileName,
      name: fileName,
      status: "done",
    }))
  );
  const [, setVisibleDragZone] = useState<boolean>(checkFileListForVisibleDragZone(fileList));
  const [fileSettings, setFileSettings] = useState<FileSettingsOutput>({});
  const [activeIDepot, setActiveIDepot] = useState<boolean>();

  useEffect(() => {
    setFileList(
      props.answer.value.map((fileName, i) => ({
        uid: fileName,
        name: fileName,
        status: "done",
      }))
    );
  }, [props.answer]);

  // get accountinng firm fileSize and fileType
  useEffect(() => {
    (async () => {
      const fs = await apiAccountingFirm.accountingfirmFindFileSettings(props.accountingFirmId);
      setFileSettings(fs);
    })();
  }, [props.accountingFirmId]);

  // En attente de création de la nouvelle route et de la bonne réponse de l'API
  useEffect(() => {
    (async () => {
      const resp = await getIDepotOnForms(props.accountingFirmId);
      const data = await resp.json();
      setActiveIDepot(data.iDepotOnForms);
    })();
  }, [props.accountingFirmId]);

  // preload file from answer
  useEffect(() => {
    if (!props.isInReviewMode) {
      (async () => {
        // assuming modification aren't done in place, to know when an answer can be updated
        if (
          fileList.some((f) => !props.answer.value.includes(f.name)) ||
          props.answer.value.some((v) => !fileList.some((f) => f.name === v))
        ) {
          props.answer.value = fileList.filter((f) => f.status === "done").map((f) => f.name);
          await apiAnswer.answersUpdateOne(props.formAnswers._id, props.answer);

          navigationTracking(props.formAnswers, props.form, props.section, props.question);
          completionTracking(props.formAnswers, props.form, props.section, props.question);
          // save progression and completion
          let updatedFormAnswers = { ...props.formAnswers, lastModifiedDate: new Date() };
          await apiFormAnswers.formAnswersUpdateOne(props.formAnswers._id, updatedFormAnswers);
        }

        setVisibleDragZone(checkFileListForVisibleDragZone(fileList));
      })();
    }
  }, [fileList, props.answer, props.formAnswers]);

  async function onRemove(e: UploadFile) {
    if (e.name) {
      if (props.answer.value.some((v) => v === e.name)) {
        try {
          let file = fileList.find((f) => f.name === e.name);
          if (file) {
            // only tags it as removed, so it's not possible to upload a file with the same name until it's done
            file.status = "removed";
            setFileList(fileList);

            await apiAnswer.answersDeleteFile(
              props.form._id,
              props.formAnswers._id,
              props.answer._id,
              e.name
            );

            // remove exact old tracking file
            setFileList(fileList.filter((f) => f !== file));
          }
        } catch (e) {}
      }
    }
  }

  async function onClick(file: UploadFile<any>) {
    if (file.name) {
      if (props.answer.value.some((v) => v === file.name)) {
        try {
          var value: AnswerFile = await apiAnswer.answersGetFile(
            props.form._id,
            props.formAnswers._id,
            props.answer._id,
            file.name
          );

          createAndDownloadBlobFile(value.content, file.name, value.type);
        } catch (e) {
          throw e;
        }
      }
    }
  }

  const handleFileChanged = (info: UploadChangeParam<UploadFile<any>>) => {
    if (info.file.status === "removed") {
    } else if (info.file.status === "uploading") {
      setFileList(info.fileList);
    } else if (info.file.status === "done") {
      setFileList(info.fileList);
    }
  };

  const checkBeforeUpload = function (file: RcFile): boolean {
    const { name, type, size } = file;

    if (fileList.some((f) => f.name === name)) {
      message.error(
        "Il n'est pas possible de charger 2 fichiers du même nom. Veuillez revoir votre sélection ou supprimer le fichier déjà téléchargé."
      );
      return false;
    }

    const supportedFormats =
      fileSettings.fileTypes && fileSettings.fileTypes.length > 0
        ? fileSettings.fileTypes
        : options.map(({ value }) => value);
    const unsupportedFormat =
      !supportedFormats.includes(type) ||
      (!supportedFormats.includes("application/x-ofx") && name.endsWith(".ofx"));
    if (unsupportedFormat) {
      message.error(
        `Format non pris en charge. ${
          fileSettings.fileTypes && fileSettings.fileTypes.length > 0
            ? fileSettings.fileTypes
            : "(" + options.map(({ label }) => label) + ")"
        }`
      );
      return false;
    }

    const maxSizeInBytes = (fileSettings.fileSize ?? 0) * 1048576;
    if (fileSettings.fileSize && size > maxSizeInBytes) {
      message.error(
        `"${name}" est trop volumineux. Taille maximum: ${fileSettings.fileSize ?? 0}Mo.`
      );
      return false;
    }

    return true;
  };

  // override post request for upload file
  const customRequest = async function (rcOption: UploadRequestOption) {
    return new Promise<void>(function (_, reject) {
      var fileType: string = typeof rcOption.file === "string" ? "" : rcOption.file.type;
      var fileName: string = rcOption.file instanceof File ? rcOption.file.name : "uploaded file";

      const reader = new FileReader();

      reader.onload = async (fileLoad: ProgressEvent<FileReader>) => {
        if (fileLoad.target?.result) {
          let file: string =
            typeof fileLoad.target?.result === "string"
              ? Buffer.from(fileLoad.target?.result, "binary").toString("base64")
              : Buffer.from(fileLoad.target?.result).toString("base64");

          let newFile: AnswerFile = {
            name: fileName,
            content: file,
            type: fileType,
          };

          try {
            // start with upload file answer
            const resUpload = await apiAnswer.answersUploadFile(
              props.form._id,
              props.formAnswers._id,
              props.answer._id,
              newFile.name,
              newFile
            );

            //send to mail to box
            const resContact = await apiContact.contactFindOne(props.formAnswers.contactId);
            if (resContact.mailToBox) {
              await sendToMailToBox(resContact._id, props.formAnswers.formId, newFile);
            }
            //send to i-Dépôt
            if (activeIDepot && resContact.iDepot && resUpload) {
              await sendToIDepot(
                resContact.iDepot,
                resContact._id,
                props.form._id,
                props.formAnswers._id,
                props.answer._id,
                newFile
              );
            }
            let fileStatus = fileList.find((f) => f.name === fileName);
            if (fileStatus) {
              fileStatus.status = "done";
            }
            setFileList(fileList);
            if (rcOption.onSuccess) rcOption.onSuccess(rcOption.file);
          } catch (error: any) {
            // if file upload failed, set old file back if possible and handle the error for the component

            let fileStatus = fileList.find((f) => f.name === fileName);
            if (fileStatus) {
              fileStatus.status = "error";
            }
            setFileList(fileList);

            if (rcOption.onError) rcOption.onError(error);
            reject(error);
          }
        }
      };

      reader.readAsBinaryString(
        typeof rcOption.file === "string" ? new Blob([rcOption.file]) : rcOption.file
      );
    });
  };

  return props.isInReviewMode ? (
    <Upload disabled fileList={fileList} />
  ) : (
    <Flex
      style={{
        display: "flex",
        flexDirection: "row",
        width: "inherit",
      }}
    >
      <Dragger
        className="upload-field-dragger-client"
        multiple={true}
        fileList={fileList}
        listType={"text"}
        showUploadList={{
          showDownloadIcon: true,
          showPreviewIcon: false,
        }}
        beforeUpload={checkBeforeUpload}
        customRequest={customRequest}
        onChange={handleFileChanged}
        //onPreview={onClick}
        onDownload={onClick}
        onRemove={onRemove}
        style={{
          borderBlockColor:
            props.mandatoryWarning && props.question.mandatory && props.answer.value.length === 0
              ? colorError
              : colorBorder,
          backgroundColor: colorBgBase,
          border: `solid 3px ${colorTextQuaternary}`,
        }}
        disabled={props.isInReviewMode}
      >
        <p className="ant-upload-drag-icon">
          <UploadOutlined />
        </p>
        <p className="ant-upload-text-alternative" style={{ color: colorTextTertiary }}>
          Partagez vos fichiers ici
        </p>
        <p className="ant-upload-text" style={{ color: colorTextTertiary }}>
          Faîtes glisser vos éléments ici ou cliquez ici.
        </p>
      </Dragger>
    </Flex>
  );
}

export default DisplayUploadField;
