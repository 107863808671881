import { SectionOutput } from "@addventa/sesha-forms-api";
import { Flex, Progress, theme } from "antd";

function FormAnswersProgressBar(props: {
  prevSections: SectionOutput[];
  currentSection: SectionOutput;
  nextSections: SectionOutput[];
}) {
  const {
    token: { colorPrimary, colorFillSecondary },
  } = theme.useToken();

  return (
    <Flex vertical align="center">
      <Flex>
        {props.prevSections.length + 1}/{props.prevSections.length + 1 + props.nextSections.length}
      </Flex>
      <Progress
        type="line"
        percent={
          ((props.prevSections.length + 1) / (props.prevSections.length + 1 + props.nextSections.length)) * 100
        }
        showInfo={false}
        strokeColor={colorPrimary}
        trailColor={colorFillSecondary}
        style={{ 
          width: "33vw",
          maxWidth: "300px",
          justifyContent: "center",
        }}
      />
    </Flex>
  );
}

export default FormAnswersProgressBar;
