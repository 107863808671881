import {
  AccountingFirmOutput,
  FormAnswersWithContactOutput,
  FormOutput,
} from "@addventa/sesha-forms-api";
import { LockFilled } from "@ant-design/icons";
import { Badge, Button, ConfigProvider, Flex, Spin, Tabs, TabsProps, theme } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import {
  apiAccountingFirm,
  apiForm,
  apiFormAnswers,
} from "../../../api-configuration/Configuration";
import { EFeatures } from "../../../enums/EFeatures";
import { useNotification } from "../../../hooks/useNotification";
import { usePermission } from "../../../hooks/usePermission";
import SendForm from "../create/SendForm";
import SendFormParams from "../create/SendFormParams";
import "./ReviewForm.css";
import { ELockType, ExtendedSeshaFormsAnswers } from "./ReviewFormModel.d";
import YourFormAnswers from "./components/FormAnswerTab/YourFormAnswers";
import YourForm from "./components/YourForm";
import { YourFormAnswersLockValidationModal } from "./components/YourFormAnswersLockValidationModal";
import { FormDeletionValidationModal } from "./components/formDeletionValidationModal";

function ReviewFormTabs() {
  const [form, setForm] = useState<FormOutput>();
  const [formAnswersList, setFormAnswersList] = useState<ExtendedSeshaFormsAnswers[]>([]);
  const [accountingFirm, setAccountingFirm] = useState<AccountingFirmOutput>();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [formDeletionModalIsVisible, setFormDeletionModalIsVisible] = useState(false);
  const [modalLockType, setModalLockType] = useState<ELockType>(ELockType.LOCK);
  const [activeTab, setActiveTab] = useState<string>("1");
  const canSendForm = usePermission(EFeatures.CREATE_AND_SEND_FORM);

  const navigate = useNavigate();
  const location = useLocation();
  const { showNotification } = useNotification();

  useEffect(() => {
    if (location.state !== null) {
      if (!location.state.creation) {
        showNotification("success", "Votre mail a bien été envoyé");
        setActiveTab("2");
      }
      navigate(location.pathname, { replace: true });
    }
  }, []);

  let { id } = useParams();
  const {
    token: { colorPrimaryBgHover, colorTextSecondary, colorSuccess, colorPrimary },
  } = theme.useToken();

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Votre formulaire",
      children: <YourForm form={form!} />,
    },

    {
      key: "2",
      label: (
        <>
          Vos réponses
          <Badge
            count={
              formAnswersList.filter((formAnswer) => formAnswer.completionRate !== 0).length || 0
            }
            showZero
            color={colorPrimary}
            style={{
              transform: "scale(90%)",
              position: "absolute",
              bottom: 5,
              right:
                formAnswersList.filter((formAnswer) => formAnswer.completionRate !== 0).length > 9
                  ? -22
                  : -15,
            }}
            size="small"
          />
        </>
      ),
      children: (
        <YourFormAnswers
          form={form!}
          setForm={setForm!}
          formAnswersList={formAnswersList}
          setFormAnswersList={setFormAnswersList}
          accountingFirm={accountingFirm}
          setModalLocakValiationIsOpen={setIsModalVisible}
          setLockType={setModalLockType}
        />
      ),
    },

    {
      key: "3",
      label: "Paramètres",
      children: (
        <SendFormParams
          form={form!}
          accountingFirm={accountingFirm!}
          isFormAnswers={true}
          setModalForLockValidationIsOpen={setIsModalVisible}
          setLockType={setModalLockType}
          setModalDeleteFormIsOpen={setFormDeletionModalIsVisible}
        />
      ),
      disabled: !canSendForm,
    },

    {
      key: "4",
      label: "Envoi du formulaire",
      children: (
        <SendForm
          form={form!}
          formAnswersSetForm={setForm!}
          setFormAnswersList={setFormAnswersList}
          accountingFirm={accountingFirm!}
          isFormAnswers={true}
        />
      ),
      disabled: !canSendForm,
    },
  ];

  useEffect(() => {
    (async () => {
      const acc = await apiAccountingFirm.accountingfirmFindOne();
      setAccountingFirm(acc);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const res: FormOutput = await apiForm.formFindOne(id!);
      setForm(res);

      const resFormAnswersWithContact: FormAnswersWithContactOutput[] =
        await apiFormAnswers.formAnswersGetFormAnswersByFormId(id!, {
          computeCR: true,
          filterByUserId: false,
        });
      const formAnswersData: ExtendedSeshaFormsAnswers[] = resFormAnswersWithContact.map(
        (data, index) => {
          return {
            ...data.formAnswers!,
            key: String(index),
            isSelected: false,
            isLoading: false,
            isLoaded: false,
            completionRate: data.formAnswers?.completionRate!,
            contact: data.contact!,
          };
        }
      );
      setFormAnswersList(formAnswersData);
    })();
  }, []);

  const unselectAll = () => {
    setFormAnswersList((previous) => {
      return previous.map((answer) => {
        answer.isSelected = false;
        return answer;
      });
    });
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            headerBg: colorPrimaryBgHover,
          },
        },
      }}
    >
      <section style={{ margin: "0px 50px" }} className="reviewForm">
        <Button type="default" style={{ width: "fit-content" }} onClick={() => navigate("/")}>
          Retour aux formulaires
        </Button>
        <Spin spinning={form ? false : true}>
          {form && (
            <Flex vertical gap={20}>
              <Flex gap="small" align="center" justify="center">
                {form.isClosed ? (
                  <LockFilled
                    style={{
                      color: "#FF9700",
                      fontSize: 20,
                    }}
                  />
                ) : (
                  <div
                    style={{
                      height: 12,
                      width: 12,
                      borderRadius: 50,
                      backgroundColor: colorSuccess,
                    }}
                  ></div>
                )}
                <h1>{form.title}</h1>
                <p style={{ position: "relative", top: 3, color: colorTextSecondary }}>
                  Envoyé le {form.sentAt ? dayjs(form.sentAt).format("DD/MM/YYYY") : ""}
                </p>
              </Flex>
              <Tabs
                style={{ minHeight: 400 }}
                items={items}
                tabPosition={"left"}
                activeKey={activeTab}
                onChange={(key) => {
                  setActiveTab(key);
                }}
              />
            </Flex>
          )}
        </Spin>
      </section>
      <YourFormAnswersLockValidationModal
        form={form!}
        setForm={setForm}
        formAnswersList={formAnswersList}
        setFormAnswersList={setFormAnswersList}
        unselectAll={unselectAll}
        modalIsOpen={isModalVisible}
        setModalIsOpen={setIsModalVisible}
        lockType={modalLockType}
      />
      <FormDeletionValidationModal
        form={form!}
        modalIsVisible={formDeletionModalIsVisible}
        setModalIsVisible={setFormDeletionModalIsVisible}
      />
    </ConfigProvider>
  );
}

export default ReviewFormTabs;
