import { QuestionOutput } from "@addventa/sesha-forms-api";
import { UploadOutlined } from "@ant-design/icons";
import { Upload, theme } from "antd";
import { Contents, Identified } from "../../../../types/misc/Generic";

const { Dragger } = Upload;

function UploadField(props: { question: Identified<Contents<QuestionOutput>>; mode: string }) {
  const {
    token: { colorBgContainer, colorTextTertiary },
  } = theme.useToken();

  return (
    <>
      <Dragger
        style={{
          border: `solid 3px ${colorTextTertiary}`,
          backgroundColor: colorBgContainer,
        }}
        {...props}
        disabled={props.mode !== "write"}
      >
        <p className="ant-upload-drag-icon">
          <UploadOutlined />
        </p>
        <p className="ant-upload-text" style={{ color: colorTextTertiary }}>
          Faîtes glisser vos éléments ici ou cliquez ici.
        </p>
      </Dragger>
    </>
  );
}

export default UploadField;
