import { ContactOutput, FormAnswersOutput, FormOutput } from "@addventa/sesha-forms-api";
import { apiFormAnswers } from "../api-configuration/Configuration";
import { coupleAllQuestionsAnswers, filterActiveSections } from "../components/assets/formAnswers/rulesActivation";

const downloadManyFormAnswers = async (form: FormOutput, contacts: ContactOutput[] | undefined) => {
  form.createdAt = new Date(form.createdAt);
  if (form.updatedAt) {
    form.updatedAt = new Date(form.updatedAt);
  }
  if (form.closeDate) {
    form.closeDate = new Date(form.closeDate);
  }
  if (form.sentAt) {
    form.sentAt = new Date(form.sentAt);
  }

  const res = await apiFormAnswers.formAnswersDownloadManyFormAnswers({ form, contacts});

  const pdfBlob = new Blob([res], { type: "application/zip" });

  // Continue with the blob handling to create a download link
  const blobUrl = URL.createObjectURL(pdfBlob);
  const link = document.createElement("a");
  link.href = blobUrl;
  link.download = `${form.title}.zip`;
  document.body.appendChild(link);
  link.click();

  // Cleanup
  URL.revokeObjectURL(blobUrl);
  document.body.removeChild(link);
};

const downloadOneFormAnswers = async (
  form: FormOutput,
  formAnswers: FormAnswersOutput,
  contact: ContactOutput
) => {
  let questionsAndAnswers = coupleAllQuestionsAnswers(form.sections!, formAnswers.answers);
  let activeSections = filterActiveSections(form.sections!, form.rules!, questionsAndAnswers);

  const filteredForm = { ...form, sections: activeSections };
  filteredForm.createdAt = new Date(form.createdAt);
  if (form.updatedAt) filteredForm.updatedAt = new Date(form.updatedAt);

  const body = {
    form: filteredForm,
    formAnswers: formAnswers,
    contact: contact,
  };
  const res = await apiFormAnswers.formAnswersDownloadOneFormAnswers(
    formAnswers._id,
    body
  );

  const pdfBlob = new Blob([res], { type: "application/zip" });

  // Continue with the blob handling to create a download link
  const blobUrl = URL.createObjectURL(pdfBlob);
  const link = document.createElement("a");
  link.href = blobUrl;
  link.download = `${form.title}-${contact.firstName} ${contact.lastName}.zip`;
  document.body.appendChild(link);
  link.click();

  // Cleanup
  URL.revokeObjectURL(blobUrl);
  document.body.removeChild(link);
};

// const downloadPdf = async (componentId: string) => {
//   const componentHtmlWithStyles = getComponentHtmlWithStyles(`#${componentId}`);

//   const htmlToPdf: FormAnswersHtmlToPDFRequest = {
//     frontend_html: componentHtmlWithStyles,
//   };
//   const res = await apiFormAnswers.formAnswersHtmlToPDF(htmlToPdf);

//   const pdfBlob = new Blob([res], { type: "application/pdf" });

//   // Continue with the blob handling to create a download link
//   const blobUrl = URL.createObjectURL(pdfBlob);
//   const link = document.createElement("a");
//   link.href = blobUrl;
//   link.download = "filename.pdf";
//   document.body.appendChild(link);
//   link.click();

//   // Cleanup
//   URL.revokeObjectURL(blobUrl);
//   document.body.removeChild(link);
// };

export { downloadManyFormAnswers, downloadOneFormAnswers };

