import {
  ExclamationCircleFilled,
  ExclamationCircleOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { Button, ConfigProvider, Flex, Modal, UploadFile, theme } from "antd";
import { UploadChangeParam } from "antd/es/upload";
import Dragger from "antd/es/upload/Dragger";
import { useState } from "react";
import * as XLSX from "xlsx";
import XLS_file_example from "../../../../assets/images/contact/create/XLS_file_example.jpg";
import XLS_file_example_ACD from "../../../../assets/images/contact/create/XLS_file_example_ACD.png";

import { ExtendedSeshaContact } from "../../ContactModel";

interface Props {
  modalIsOpen: boolean;
  setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setTableDataSource: React.Dispatch<React.SetStateAction<ExtendedSeshaContact[]>>;
  dataProvider: string;
  fec: boolean;
}

export function ContactCreationExcelModal(props: Props) {
  const {
    token: {
      colorBgBase,
      colorError,
      colorPrimary,
      colorFillTertiary,
      colorBorder,
      colorPrimaryBgHover,
      colorTextQuaternary,
    },
  } = theme.useToken();

  const [uploadError, setUploadError] = useState<boolean>(false);
  const [modalIsLoading, setModalIsLoading] = useState<boolean>(false);
  const [contactFile, setContactFile] = useState<UploadChangeParam<UploadFile<any>> | undefined>(
    undefined
  );

  function createAndDownloadExcel() {
    const headers = ["Prénom", "Nom", "E-mail", "Mail-to-box"];
    const headersACD = ["Prénom", "Nom", "E-mail", "Mail-to-box", "Code dossier i-Dépôt"];

    const workbook = XLSX.utils.book_new();
    const worksheet =
      props.dataProvider === "ACD" && props.fec === false
        ? XLSX.utils.aoa_to_sheet([headersACD])
        : XLSX.utils.aoa_to_sheet([headers]);

    XLSX.utils.book_append_sheet(workbook, worksheet, "Template");

    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "Ajout_de_contact_modèle.xlsx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }

  const handleOnUpload = (file: UploadChangeParam<UploadFile<any>>) => {
    setModalIsLoading(true);
    const emptyFile: UploadChangeParam<UploadFile<any>> = {
      file: {} as UploadFile<any>,
      fileList: [],
    };

    if (file.file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      if (file.file.status === "removed") {
        setContactFile(undefined);
      } else {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = new Uint8Array(e.target?.result as ArrayBuffer);
          const workbook = XLSX.read(data, { type: "array" });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];

          const json: any[] = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

          json.forEach((row: any[]) => {
            if (row.includes(false)) {
              row[row.findIndex((element) => element === false)] = "Faux";
            }
            if (row.includes(true)) {
              row[row.findIndex((element) => element === true)] = "Vrai";
            }
          });
          const header = json[0].map((elt: string) => elt.trim());
          const headerIsValid =
            header[0] === "Prénom" && header[1] === "Nom" && header[2] === "E-mail";
          if (!headerIsValid) {
            setContactFile(emptyFile);
            setUploadError(true);
            setModalIsLoading(false);
            return;
          }
        };
        reader.readAsArrayBuffer(file.file.originFileObj as Blob);
        setContactFile({ file: file.file, fileList: [file.file] });
      }
      setUploadError(false);
      setModalIsLoading(false);
      return;
    }

    setContactFile(emptyFile);
    setUploadError(true);
    setModalIsLoading(false);
  };

  const onFinish = () => {
    const file = contactFile;
    if (!file || file.fileList.length === 0) {
      setUploadError(true);
      return;
    }
    setModalIsLoading(true);
    if (file.file.originFileObj) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target?.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: "array" });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const json: any[] = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        json.forEach((row: any[]) => {
          if (row.includes(false)) {
            row[row.findIndex((element) => element === false)] = "Faux";
          }
          if (row.includes(true)) {
            row[row.findIndex((element) => element === true)] = "Vrai";
          }
        });

        props.setTableDataSource((previous) => {
          const contactsFromExcel: ExtendedSeshaContact[] = json
            .slice(1)
            .map((row: any[], index) => ({
              _id: "",
              accountingFirmId: "fake accounting id",
              creatorId: "creator-id",
              createdAt: new Date(),
              key: previous.length + index,
              firstName: row[0] ? String(row[0]) : "",
              lastName: row[1] ? String(row[1]) : "",
              email: row[2] ? String(row[2]) : "",
              mailToBox: row[3] ? String(row[3]) : "",
              iDepot: row[4] ? String(row[4]) : "",
            }));

          let newData;
          if (previous.length === 1 && previous[0].isFirst && previous[0].isNewlyAdded) {
            newData = [...contactsFromExcel];
          } else {
            newData = [...previous, ...contactsFromExcel];
          }

          newData[0].isFirst = true;
          return newData;
        });
      };
      reader.readAsArrayBuffer(file.file.originFileObj as Blob);
    }
    setModalIsLoading(false);
    onClose();
  };

  const onClose = () => {
    setUploadError(false);
    setContactFile(undefined);
    props.setModalIsOpen(false);
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Modal: {
            headerBg: colorPrimaryBgHover,
          },
        },
      }}
    >
      <Modal
        title={"Importer un fichier XLS"}
        className="modal-contact-creation"
        open={props.modalIsOpen}
        onOk={onFinish}
        okText="Importer le fichier"
        confirmLoading={modalIsLoading}
        onCancel={onClose}
      >
        <Dragger
          defaultFileList={undefined}
          fileList={contactFile?.fileList}
          onChange={(e) => handleOnUpload(e)}
          style={{ backgroundColor: colorBgBase, border: `solid 3px ${colorTextQuaternary}` }}
        >
          <p className="ant-upload-drag-icon">
            <UploadOutlined />
          </p>
          <p className="ant-upload-hint">Faîtes glisser votre fichier ici ou cliquez ici.</p>
        </Dragger>
        <Flex
          vertical
          gap="middle"
          align="center"
          style={{
            marginTop: 36,
            padding: "20px 44px",
            borderRadius: 10,
            border: `solid 2px ${colorBorder}`,
            backgroundColor: colorFillTertiary,
          }}
        >
          <Flex gap={22}>
            <ExclamationCircleFilled style={{ color: colorPrimary, fontSize: 25 }} />
            <span>
              <p>
                Pour que l’import du tableau fonctionne, les informations doivent être remplies dans
                cet ordre :
                <br />
                Prénom/Nom/E-mail/Mail to box (facultatif)
                {props.dataProvider === "ACD" && props.fec === false
                  ? "/Code dossier i-Dépôt (facultatif)"
                  : ""}
              </p>
              {props.dataProvider === "ACD" && props.fec === false ? (
                <img src={XLS_file_example_ACD} alt="XLS_fichier_exemple ACD" width={"100%"} />
              ) : (
                <img src={XLS_file_example} alt="XLS_fichier_exemple" />
              )}
            </span>
          </Flex>
          <Button type="primary" onClick={createAndDownloadExcel}>
            Télécharger un modèle vierge
          </Button>
        </Flex>
        {uploadError && (
          <Flex justify="center" align="center" gap="middle">
            <ExclamationCircleOutlined style={{ color: colorError }} />
            <p style={{ color: colorError }}>
              Le fichier importé n’est pas au bon format. Veuillez réessayer l’import avec un
              fichier conforme.
            </p>
          </Flex>
        )}
      </Modal>
    </ConfigProvider>
  );
}
