import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css"; // Import the bubble theme

interface EditorProps {
  initialValue: string; // Change prop name to initialValue
  onChange: (value: string) => void;
}

const TextEditor: React.FC<EditorProps> = ({ initialValue, onChange }) => {
  const [editorHtml, setEditorHtml] = useState(initialValue); // Initialize state with initialValue

  useEffect(() => {
    //console.log(initialValue);
    // Update editorHtml if initialValue changes
    setEditorHtml(initialValue);
  }, [initialValue]);

  const handleChange = (html: string) => {
    //console.log(html);
    setEditorHtml(html);
    onChange(html);
  };

  return (
    <ReactQuill
      style={{ border: "1px solid #A6A6A6", borderRadius: "20px" }}
      theme="bubble"
      value={editorHtml}
      onChange={handleChange}
      modules={{
        toolbar: [
          [{ header: "1" }, { header: "2" }],
          ["bold", "italic", "underline"],
          [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
          [{ align: [] }],
          [
            {
              color: [],
            },
          ],
        ],
      }}
    />
  );
};

export default TextEditor;
